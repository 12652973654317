
import React from 'react';
import LocalStorage from '../../Providers/LS';
import Utils from '../../Providers/Utils';

export default class AdminMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    ls = new LocalStorage();
    utils = new Utils();

    localStorage = {
        userLS: {}
    }

    componentDidMount() {
        // console.log('props in admin menu', this.props)
        this.getUserLS()
    }

    getUserLS = async () => {
        this.localStorage.userLS = await this.ls.getLocalStorage(this.ls.userLS)
        console.log('user LS ', this.localStorage.userLS)

        if (this.localStorage.userLS) {
            this.verifyIsAdmin(this.localStorage.userLS.idul, this.localStorage.userLS.sessionIdul)
        } else {
            // daca nu are userLS il trimit pe home
            console.log('Eroare la preluare userLS');
            this.props.history.push('/');
        }
    }

    verifyIsAdmin = async (idul, sessionIdul) => {
        let isAdmin = await this.utils.verifyAdmin(idul, sessionIdul)
        // console.log('isAdmin ::: ', isAdmin)

        if (isAdmin) {

        } else {
            // daca nu este admin il trimit pe home
            this.props.history.push('/');
        }

    }

    render() {
        return (
            <div className="admin-menu">

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminclients') }} >
                    <p>Clienti</p>
                </div>
         

                <div className="admin-tab inline-block" onClick={() => {
                    // console.log('this.props:: ', this.props)
                    this.props.history.push('/adminmeditations')
                }} >
                    <p>Meditatii</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminmeditationsorder') }} >
                    <p>Meditatii Ordine</p>
                </div>


                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/admincategories') }} >
                    <p>Categorii</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminlevels') }} >
                    <p>Niveluri</p>
                </div>

                {/* <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminprograme') }} >
                    <p>Programe</p>
                </div> */}

                <div className="admin-tab inline-block" onClick={() => {
                    console.log('this.props:: ', this.props)
                    this.props.history.push('/adminauthors')
                }} >
                    <p>Autori</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminnewsletter') }} >
                    <p>Inscriere Newsletter</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminquotation') }} >
                    <p>Citate Zilnice</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminratings') }} >
                    <p>Ratings</p>
                </div>

                <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/admincontact') }} >
                    <p>Contact</p>
                </div>
            </div>
        )
    }

}