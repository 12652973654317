import React from 'react';
import { Tooltip } from '@material-ui/core';

export default class RatingStars extends React.Component {
    constructor(props) {
        super(props);
    }



    componentDidMount() {
        console.log('rating meditatie ', this.props.meditation)

    }


    ratingStars = (rating) => {
        // asuming 5
        let containerStyle = {
            // backgroundColor: "lightpink",
            flex: 1,
            justifyContent: "center",
            alingnItems: "center",
            cursor: "pointer"
        }

        let iconStyleFull = {
            color: "#c3c3c3", //darkorange
            fontSize: '20px'
        }

        let iconStyleHalf = {
            color: "#c3c3c3", // #ffe897
            fontSize: '20px'
        }

        let iconStyleEmpty = {
            color: "#c3c3c3",
            fontSize: '20px'
        }

        let rez = []

        let stars = Number(rating)
        console.log('rating ', stars)



        for (let i = 0; i < 5; i++) {
            if (stars > 0) {
                if (stars > 0.5) {
                    rez.push(
                        <Tooltip title={i + 1} key={'icon' + i}>
                            <i  className="material-icons" style={iconStyleFull} onClick={() => this.props.setVote(i + 1)}>star</i>
                        </Tooltip>
                    )
                }
                else {

                    rez.push(
                        <Tooltip title={i + 1} key={'icon' + i}>
                            <i className="material-icons" style={iconStyleHalf} onClick={() => this.props.setVote(i + 1)}>star_half</i>
                        </Tooltip>
                    )
                }
            }
            else {
                rez.push(
                    <Tooltip title={i + 1} key={'icon' + i}>
                        <i className="material-icons" style={iconStyleEmpty} onClick={() => this.props.setVote(i + 1)}>star_outline</i>
                    </Tooltip>
                )
            }
            stars--;
        }
        return (<div style={containerStyle}> {rez} </div>)

    }

    render() {

        let stars = this.ratingStars(this.props.meditation.rating)

        return (
            <div>
                {stars}
            </div>
        );
    }


}  // end class

