import React from 'react';
import { Grid } from '@material-ui/core'

export default class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barWidth: "",
            progressWidth: 0,
            playPause: 'play_arrow',
            bar1: 'block',
            bar2: 'block',
            bar3: 'block',
            bar4: 'block',
        }
    }

    componentDidMount() {
        this.barWidth()
        window.addEventListener('resize', this.barWidth)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.barWidth)
    }

    barWidth = () => {
        let container = document.getElementById('meditatii-myplayer')
        let playIcon = document.getElementById('play-icon')
        let volumeIcon = document.getElementById('player-volume-container')
        let timer = document.getElementById('player-timer')

        let bar = ((playIcon.clientWidth + volumeIcon.clientWidth + timer.clientWidth + 38 + 38) / container.clientWidth * 100) - 100

        this.setState({ barWidth: - bar + '%' })
    }

    showCoords = (ev) => {
        let div = ev.clientX;

        let offset = div - document.getElementById('bar').offsetLeft
        let bar_width = document.getElementById('bar').clientWidth

        this.setState({ progressWidth: Math.floor((offset / bar_width * 100)) }, () => {
            this.getAudioTime()
        })

    }

    playPause = () => {
        this.props.audio.currentTime = Math.floor((this.props.audio.duration * this.state.progressWidth) / 100)
        this.props.audio.ontimeupdate = () => {

            let duration = this.props.audio.duration - this.props.audio.currentTime

            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if (minutes >= 60) {
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour

            this.setState({
                progressWidth: this.props.audio.currentTime / this.props.audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
            })
        }
        if (this.state.playPause === 'play_arrow') {
            this.setState({ playPause: 'pause' }, () => {
                this.props.audio.play()
            })
        } else {
            this.setState({ playPause: 'play_arrow' }, () => {
                this.props.audio.pause()
            })
        }

        this.props.audio.onended = () => {
            this.props.audio.currentTime = 0
            this.setState({ playPause: 'play_arrow' })
        }
    }

    setVolume = (ev) => {
        let div = ev.clientX

        let offset = div - document.getElementById('meditatii-player-volume').offsetLeft
        let bar_width = document.getElementById('meditatii-player-volume').clientWidth

        let vWidth = Math.floor((offset / bar_width * 100))

        if (vWidth < 25) {
            this.setState({
                bar1: 'block',
                bar2: 'none',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                this.props.audio.volume = 0
            })
        } else if (vWidth > 25 && vWidth < 50) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'none',
                bar4: 'none'
            }, () => {
                this.props.audio.volume = 0.25
            })
        } else if (vWidth > 50 && vWidth < 75) {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'none'
            }, () => {
                this.props.audio.volume = 0.5
            })
        } else {
            this.setState({
                bar1: 'block',
                bar2: 'block',
                bar3: 'block',
                bar4: 'block'
            }, () => {
                this.props.audio.volume = 1
            })
        }

    }

    getAudioTime = () => {
        this.props.audio.currentTime = Math.floor((this.props.audio.duration * this.state.progressWidth) / 100)
        this.props.audio.ontimeupdate = () => {
            let duration = this.props.audio.duration - this.props.audio.currentTime

            let hour = Math.floor(duration / 3600)
            let minutes = Math.floor(duration / 60);
            let seconds = Math.ceil(duration - minutes * 60) - 1

            if (minutes >= 60) {
                minutes = minutes - 60
            }

            hour = hour < 10 ? '0' + hour : hour

            this.setState({
                progressWidth: this.props.audio.currentTime / this.props.audio.duration * 100,
                duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
            })
        }
    }

    render() {
        return (
            <Grid container className="meditatii-subsection-subsection meditatii-player-container" id="meditatii-myplayer" style={{ margin: "20px auto" }} >
                <Grid className="meditatii-subsection-subsection" style={{ padding: 0 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div id='play-icon' style={{ width: 'fit-content', display: 'inline-flex', verticalAlign: 'bottom' }}>
                        <i className='material-icons' style={{ fontSize: 38, color: "#ea7ab7", cursor: 'pointer' }} onClick={this.playPause}>{this.state.playPause}</i>
                    </div>

                    <div id='player-timer' style={{ display: 'inline-flex' }}>
                        <span style={{ fontSize: 12, }}>{this.props.duration}</span>
                    </div>

                    <div style={{ height: '20px', border: '1px solid #ea7ab7', display: 'inline-flex', width: this.state.barWidth, borderRadius: 10, marginTop: 12, }} id='bar' onClick={this.showCoords} >
                        <div style={{ backgroundColor: '#ea7ab7', width: this.state.progressWidth + "%", height: '100%', borderRadius: 10 }}  ></div>
                    </div>

                    <div className="meditatii-volume-container" id='player-volume-container'>
                        <div id="meditatii-player-volume" onClick={this.setVolume}>
                            <div className="meditatii-volume-bars" style={{ height: 8, bottom: 0, display: this.state.bar1 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 16, bottom: 0, left: 9, display: this.state.bar2 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 24, bottom: 0, left: 18, display: this.state.bar3 }} ></div>
                            <div className="meditatii-volume-bars" style={{ height: 32, bottom: 0, left: 27, display: this.state.bar4 }}></div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
};
