import React from 'react';
import patreon from '../../images/patreon_icon.png';
import { Tooltip } from '@material-ui/core';

export default class DonateHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() { 
        return (
            <div className="donate-header-container inline-block">
                <Tooltip title="Become a patreon">

                    <a href="https://www.patreon.com/namastespace" target="_blank" className="patreon-img" rel="noopener noreferrer">
                        <img src={patreon} width="20px" alt="patreon" />
                </a>
                </Tooltip>
            </div>
        )
    }

}