import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './App.css';
import './css/namaste.css';

import LoginRegister from './Module/LoginRegister/LoginRegister';

import Home from './Module/Home/Home';
import Meditation from './Module/Meditation/Meditation';

// ADMIN SECTION

import Admin from './Module/Admin/Admin'

import AdminAuthors from './Module/Admin/Authors/AdminAuthors';
import AdminEditAuthor from './Module/Admin/Authors/AdminEditAuthor';

import AdminClients from './Module/Admin/AdminClients';
import AdminClientDetails from './Module/Admin/AdminClientDetails';

import AdminQuotation from './Module/Admin/CitateZilnice/AdminQuotation';

import AdminMeditations from './Module/Admin/Meditations/AdminMeditations';
import AdminEditMeditation from './Module/Admin/Meditations/AdminEditMeditation';

import AdminCategories from './Module/Admin/Categories/AdminCategories';
import AdminEditCategory from './Module/Admin/Categories/AdminEditCategory';
import AdminEditSubcategory from './Module/Admin/Categories/AdminEditSubcategory';

import AdminLevels from './Module/Admin/Levels/AdminLevels';
import AdminEditLevel from './Module/Admin/Levels/AdminEditLevel';
import MyAccount from './Module/Account/MyAccount';
import ResetPassword from './Module/LoginRegister/ResetPassword';
import AdminRatings from './Module/Admin/Ratings/AdminRatings';
import AdminNewsletterSubscribers from './Module/Admin/AdminNewsletterSubscribers';
import Contact from './Module/Contact/Contact';
import Terms from './Module/Docs/Terms';
import Privacy from './Module/Docs/Privacy';
import Cookies from './Module/Docs/Cookies';
import AdminContact from './Module/Admin/Contact/AdminContact';
import AdminMeditationsOrder from './Module/Admin/Meditations/AdminMeditationsOrder';

const history = createBrowserHistory();

export default class App extends React.Component {

  constructor(props) {
    
    super(props)
    this.state = {
      isAuthentificationPanel: false
    }

  }

  componentDidMount() {
    // console.log('props meditation ', history);
  }

  openAuthentificationPanel = () => {
    this.setState({ isAuthentificationPanel: this.state.isAuthentificationPanel ? false : true });
  }

  render() {
    return (
      <div className="App" >

        {
          this.state.isAuthentificationPanel &&
          <LoginRegister openAuthentificationPanel={this.openAuthentificationPanel} history={history} />
        }

        <HashRouter>
          <Switch>
            
            <Route exact path="/" render={ (props) => <Home {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            <Route exact path="/meditations/:idCategory" render={(props) => <Meditation {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history}/>
            
            <Route exact path="/contact" render={(props) => <Contact {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/terms" render={ (props) => <Terms {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            <Route exact path="/privacy" render={ (props) => <Privacy {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            <Route exact path="/cookies" render={ (props) => <Cookies {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            
            <Route exact path="/account" render={(props) => <MyAccount {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/resetpassword/:resetToken" render={ (props) => <ResetPassword {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            
            <Route exact path="/admin" render={(props) => <Admin {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/adminclients" render={ (props) => <AdminClients {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history} />
            <Route exact path="/adminuserdetais/:idUser" render={(props) => <AdminClientDetails {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history}/>
            <Route exact path="/adminquotation" render={(props) => <AdminQuotation {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            
            <Route exact path="/adminmeditations" render={(props) => <AdminMeditations {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/adminmeditationsorder" render={(props) => <AdminMeditationsOrder {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/adminmeditation/:idMeditation" render={(props) => <AdminEditMeditation {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            
            <Route exact path="/adminlevels" render={(props) => <AdminLevels {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/adminlevel/:idLevel/:idCategory" render={(props) => <AdminEditLevel {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />

            <Route exact path="/admincategories" render={(props) => <AdminCategories {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/admincategory/:idCategory" render={(props) => <AdminEditCategory {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/adminsubcategory/:idSubcategory" render={(props) => <AdminEditSubcategory {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            
            <Route exact path="/adminauthors" render={(props) => <AdminAuthors {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
            <Route exact path="/admineditauthor/:idAuthor" render={(props) => <AdminEditAuthor {...props} openAuthentificationPanel={this.openAuthentificationPanel}  />} history={history}/>

            <Route exact path="/adminratings" render={(props) => <AdminRatings {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />

            <Route exact path="/admincontact" render={(props) => <AdminContact {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />

            <Route exact path="/adminnewsletter" render={(props) => <AdminNewsletterSubscribers {...props} openAuthentificationPanel={this.openAuthentificationPanel} />} history={history} />
          </Switch>
        </HashRouter>

      </div>
    );
  }
}




// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
