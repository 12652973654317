import React from 'react';

import Menu from './Menu';
import DonateHeader from './Donate';
import LogoNamaste from '../../images/text_namaste_logo.png'

export default class SiteHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        // console.log('this.props siteheader ', this.props)
    }

    render() {
        return (
            <div className="header-container">
                <header>
                    <DonateHeader />
                    <div className="logo-container inline-block">
                        <img src={LogoNamaste} width='100px' alt="logo_namaste"/>
                    </div>
                    <Menu openAuthentificationPanel={this.props.openAuthentificationPanel} />
                </header>
            </div>
        )
    }
}