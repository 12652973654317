import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import AdminHeader from './AdminHeader';
import { getFromServer } from '../../Providers/WS';

export default class AdminNewsletterSubscribers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            subscribers: []
        }
    }



    componentDidMount() {
        this.getSubscribers()
    }

    getSubscribers = async () => {
        let subscribers = await getFromServer('newsletter', {
            mode: 'get_newsletter_subscribers',
        }, { credentials: 'include' })
        console.log('subscribers ', subscribers)
        if (subscribers.r === 'ok') {
            this.setState({ subscribers: subscribers.continut})
        } else {

        }
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <div>
                        <p className="opacity-title blue-lite">Newsletter Subscribers</p>
                    </div>

                    <div className="table-head">
                        <div className="width5 inline-block">ID</div>
                        <div className="width25 inline-block">E-mail</div>
                        <div className="width20 inline-block">Username</div>
                        <div className="width20 inline-block">FB User</div>
                        <div className="width20 inline-block">Data Inscriere</div>
                    </div>

                    {
                        this.state.subscribers.map((item, index) => {
                            return (
                                <div key={index} className="row-item">
                                    <div className="width5 inline-block">{item.idul}</div>
                                    <div className="width25 inline-block">{item.email}</div>
                                    <div className="width20 inline-block">{item.username}</div>
                                    <div className="width20 inline-block">{item.fb_username}</div>
                                    <div className="width20 inline-block">{item.data_adaugare}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }


}  // end class