import React, { Fragment } from 'react';
import { getFromServer } from '../../Providers/WS';
import MeditationsCategoryContainer from './MeditationsCategoryContainer';


export default class MeditationsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesList: [],
            // userMeditationProgress: [],
            showRight: Number(this.props.idCategorySelected) === 1 ? false : true,

            barLeftPosition: 0,
            barRightPosition: '95%',
            searchedString: '',
            ///
            contentMind: [],
            contentHeart: []

        }
    }

    leftSide;
    rightSide
    /// folosite pentru a clona listaele pentru search
    cloneListMind = []
    cloneListHeart = []

    componentDidMount() {
        this.initialize()
        console.log('this.props.idCategorySelected ', this.props.idCategorySelected)
        // console.log('showRight ', this.state.showRight)
    }

    initialize = async () => {
        // ordinea conteaza
        // await this.getUserProgress();
        await this.getCategories();

        this.leftSide = document.getElementsByClassName('leftCategory')[0];
        this.rightSide = document.getElementsByClassName('rightCategory')[0];
        this.toogleCategoryArrow = document.getElementsByClassName('toogleCategoryArrow')[0];
        // console.log('leftSide 2 ', this.leftSide)
    }

    getCategories = async () => {
        let categories = await getFromServer('category', {
            mode: 'list_categories',
        }, { credentials: 'include' })

        if (categories.r === 'ok') {

            this.setState({ categoriesList: categories.continut }, () => {
                console.log('categorii', this.state.categoriesList)
            });

            this.getCategoryContent(categories.continut)
        } else {
            alert('A aparut o problema la actualizare informatii')
        }
    }

    getCategoryContent = async (categories) => {

        for (let i = 0, n = categories.length; i < n; i++) {

            let categoryContent = await getFromServer('content', {
                mode: 'content_category_list',
                id_category: categories[i].idul
            }, { credentials: 'include' })

            if (categoryContent.r === 'ok') {
                if (i === 0) {
                    this.contentMind = categoryContent.continut
                    this.setState({ contentMind: categoryContent.continut }, () => { console.log('content minte: ', this.state.contentMind) })
                } else {
                    this.contentHeart = categoryContent.continut
                    this.setState({ contentHeart: categoryContent.continut }, () => { console.log('content heart: ', this.state.contentHeart) })
                }
            } else {
                console.error("error preluare meditatii pe categorie")
            }
        }
    }

    toogleCategory = () => {
        let bar = document.getElementsByClassName('toogleCategory')[0];


        this.leftSide.classList.add('mic')
        this.rightSide.classList.add('mic')
        this.leftSide.classList.remove('mare')
        this.rightSide.classList.remove('mare')

        // scoatere width0, ajuta doar la inceput
        this.leftSide.classList.remove('width0')
        this.rightSide.classList.remove('width0')

        if (this.state.showRight) {
            this.showCategoryContent()

            bar.classList.add('moveRight')
            bar.classList.remove('moveLeft')
            this.setState({ showRight: false })

        } else {

            this.showCategoryContent()

            bar.classList.remove('moveRight')
            bar.classList.add('moveLeft')

            this.setState({ showRight: true })

        }

    }

    showCategoryContent = () => {

        if (this.state.showRight) {
            // console.log('afisare categorie din dreapta')
            this.leftSide.classList.remove('mic')
            this.leftSide.classList.add('mare')

            this.rightSide.classList.add('mic')
            this.rightSide.classList.remove('mare')

            this.toogleCategoryArrow.classList.add('toogleCategoryArrowRotate0')
            this.toogleCategoryArrow.classList.remove('toogleCategoryArrowRotate180')
        } else {
            this.rightSide.classList.remove('mic')
            this.rightSide.classList.add('mare')

            this.leftSide.classList.remove('mare')
            this.leftSide.classList.add('mic')

            this.toogleCategoryArrow.classList.add('toogleCategoryArrowRotate180')
            this.toogleCategoryArrow.classList.remove('toogleCategoryArrowRotate0')

            // console.log('afisare categorie din stanga')
        }
    }

    onChangeSearch = (ev) => {
        if (ev.target.value.length >= 3) {
            // console.log('', ev.target.value)
            this.setState({ searchedString: ev.target.value, contentMind: this.contentMind, contentHeart: this.contentHeart }, () => {
                this.searchMind()
                this.searchHeart()
            })
        } else if (Number(ev.target.value.length) === 0) {
            // console.log('no searched string :: ', this.cloneListMind)
            this.setState({ contentMind: this.contentMind, contentHeart: this.contentHeart })
        } else {
            // console.log('a intrat in else ',)
        }
    }

    searchMind = () => {
        // console.log('aicii ', list)
        let cloneListMind = [...this.state.contentMind]
        console.log('lista clonata ', cloneListMind)

        let filteredList = cloneListMind.filter(meditation => {

            meditation.tags = !meditation.tags ? '' : meditation.tags
            // console.log('meditation ', meditation)

            // console.log('searched word ', this.state.searchedString)

            // console.log('1 ', meditation.descriere.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1)
            // console.log('2 ', meditation.nume.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1)
            // console.log('3 ', meditation.tags.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1 )

            return( (meditation.descriere.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1 
                ||
                meditation.nume.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1
                ||
                meditation.tags.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1)
            )
            
        })

        console.log('lista filtrata ', filteredList)
        this.setState({ contentMind: filteredList})

    }


    searchHeart = () => {

        let cloneListHeart = [...this.state.contentHeart]
        // console.log('lista clonata ', cloneListHeart
        let filteredList = cloneListHeart.filter(meditation => {

            meditation.tags = !meditation.tags ? '' : meditation.tags

            return (meditation.descriere.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1
                ||
                meditation.nume.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1
                ||
                meditation.tags.toLowerCase().indexOf(this.state.searchedString.toLowerCase()) > -1
            )
    
        })

        this.setState({ contentHeart: filteredList }, ()=>{console.log('content Heart trimis ', this.state.contentHeart)})

    }

    // getUserProgress = async () => {

    //     let progress = await getFromServer('users', {
    //         mode: 'user_progress_by_category',
    //         id_user: this.props.userData.idul,
    //         id_sesiune: this.props.userData.sessionIdul
    //     }, { credentials: 'include' })

    //     console.log('user progress ', progress)

    //     if (progress.r === 'ok') {

    //         this.setState({ userMeditationProgress: progress.continut })

    //     } else if (progress.r === 'err_auth') {

    //         console.error('Please Relogin');
    //         this.localStorage.deleteLocalStorage()

    //     } else {
    //         console.error('checkpoint error');
    //     }

    // }


    render() {
        return (

            <Fragment>

                <div className="meditations-search">
                    <div className="search-input">
                        <input type="text" onChange={this.onChangeSearch}></input>
                    </div>
                    <div className="inline-block">
                        <i className="material-icons search-icon">search</i>
                    </div>
                </div>

                <div className="meditations-container">

                    {/* {
                    this.state.categoriesList.length > 0 &&
                    this.state.categoriesList.map(item => {
                        return <MeditationsCategoryContainer
                            key={item.idul}
                            idCategory={item.idul}
                            nrCategories={this.state.categoriesList.length}
                            // setAudio={this.props.setAudio}
                            userData={this.props.userData}
                            userMeditationProgress={this.state.userMeditationProgress}
                        />
                    })
                } */}



                    {
                        this.state.categoriesList.length > 0 &&
                        <Fragment>

                            <div className={Number(this.props.idCategorySelected) === 1 ? "leftCategory inline-block" : "leftCategory inline-block width0"}>

                                <MeditationsCategoryContainer
                                    categoryMeditations={this.state.contentMind}
                                    currentPlayedIdMeditation={this.props.currentPlayedIdMeditation}
                                    idCategory={this.state.categoriesList[0].idul}
                                    setAudio={this.props.setAudio}
                                    openAuthentificationPanel={this.props.openAuthentificationPanel}
                                // searchedString={this.state.searchedString}
                                />
                            </div>

                            <div
                                className="toogleCategory inline-block"
                                onClick={this.toogleCategory}
                                style={{ left: this.state.leftBar, right: this.state.rightBar, backgroundColor: Number(this.props.idCategorySelected) === 1 ? '#e07973' : '#33b4b1' }}
                            >
                                <i className="material-icons toogleCategoryArrow">chevron_left</i>
                            </div>

                            <div className={Number(this.props.idCategorySelected) === 2 ? "rightCategory inline-block" : "rightCategory inline-block width0"}>
                                <MeditationsCategoryContainer
                                    categoryMeditations={this.state.contentHeart}
                                    currentPlayedIdMeditation={this.props.currentPlayedIdMeditation}
                                    idCategory={this.state.categoriesList[1].idul}
                                    setAudio={this.props.setAudio}
                                    openAuthentificationPanel={this.props.openAuthentificationPanel}
                                // searchedString={this.state.searchedString}
                                />
                            </div>
                        </Fragment>
                    }


                </div>
            </Fragment>

        );
    }


}  // end class