import React, { useState } from 'react';
import SmallButton from '../Pieces/SmallButton';
import { getFromServer } from '../../Providers/WS';
import LocalStorage from '../../Providers/LS'

export const Newsletter = () => {

    const [error, setError] = useState('')
    const [success, setSuccessMessage] = useState('')
    let newsletterInput = '';
    let idUser = 0;
    let localStorage = new LocalStorage();

    const saveMail = async () => {

        let userData = await localStorage.getLocalStorage(localStorage.userLS)
        console.log('userData ', userData)

        if (userData) {
            idUser = userData.idul
        }

        let newsletterResponse = await getFromServer('newsletter', {
            mode: 'insert_email',
            email: newsletterInput,
            id_user: idUser
        }, { credentials: 'include' })

        console.log('newsletterResponse ', newsletterResponse)

        if (newsletterResponse.r === "ok") {
            setError('')
            setSuccessMessage('Thank you. Subscription with success')
            newsletterResponse = '';
        } else {
            setError(newsletterResponse.mesaj)
        }


    }



    return (
        <div className="newsletter-container">

            {/* <p className="namaste-label"></p> */}
            <input
                className="small-namaste-round-input"
                placeholder="enter e-mail for newsletter"
                // value={this.state.name || ''}
                onChange={ev => {

                    newsletterInput = ev.target.value
                    console.log('introdus ', newsletterInput)

                }}
            >
            </input>
            <SmallButton text='SEND' action={saveMail} cssClass="green-button namaste-small-round-button" />
            <p className="error-message text-center">{error}</p>
            <p className="success-message text-center">{success}</p>
        </div>
    );
}




