import React from 'react';
import LongSaveButton from '../../Pieces/LongSaveButton';
import { Input, Select, MenuItem } from '@material-ui/core';
import { getFromServer } from '../../../Providers/WS';
import Validator from '../../../Providers/Validator';

/* PROPS */


export default class NewMeditationSection extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            errors: false,
            name: '',
            categoryID: 1
        }
    }

    validate = new Validator();

    componentDidMount() {

    }


    saveMeditation = async () => {

        let serverResponse = await getFromServer('content', { mode: 'insert_content', name: this.state.name, type: 'audio', id_category: this.state.categoryID }, { credentials: 'include' })
        console.log('serverResponse ', serverResponse)
        if (serverResponse.r === 'ok') {
            this.props.history.push(`/adminmeditation/${serverResponse.id_introdus}`)
        }

    }

    validateMeditation = () => {
        let validateResponse = this.validate.lenghtValidate(4, this.state.name)

        if (!validateResponse) {
            this.setState({ errors: true })
        } else {
            this.setState({ errors: false })
            this.saveMeditation()
        }
        console.log('validate response ', validateResponse)
    }

    setInput = (type, ev) => {
        switch (type) {
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            case 'category':
                this.setState({ categoryID: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }

    render() {
        return (

            <div>
                <input
                    className="namaste-round-input"
                    placeholder="Introduceti numele meditatiei"
                    onChange={(ev) => { this.setInput('name', ev) }}
                >
                </input>

                {
                    this.state.errors &&
                    <p className="error-message text-center">Introduceti un cuvant format din minim 5 caractere</p>
                }

                <p className="namaste-label">Selectati o categorie</p>
                <Select
                    value={this.state.categoryID}
                    onChange={(ev) => { this.setInput('category', ev) }}
                    className="namaste-select-box"
                    input={<Input id="val" />}>
                    {this.props.categories.map((item, idx) => {
                        return <MenuItem value={item.idul} key={idx} > {item.nume_categorie}</MenuItem>
                    })
                    }
                </Select>

                <LongSaveButton text="Salvare" action={this.validateMeditation} />

            </div>

        )
    }
}