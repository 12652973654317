import React from 'react';

import SiteHeader from '../SiteHeader/SiteHeader';
import MeditationsContainer from './MeditationsContainer';
import { getFromServer } from '../../Providers/WS';
import { mediaPath } from '../../Providers/WS';
import UserPlayer from '../Pieces/UserPlayer';
import LocalStorage from '../../Providers/LS'
import Footer from '../Footer/Footer';

// import { getFromServer } from '../../Providers/WS';

const audio = document.createElement('audio')
audio.setAttribute("id", "audioFile")

export default class Meditation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // playPause: '',
            progressWidth: 0,

            categoriesList: [],
            /////// player
            fileName: '',
            seek: '',
            remain_time: 0,
            audioDuration: 0,
            audio: '',
            audioName: '',
            ///////
            userData: {},
            // 
            prevCheckpoint: 0,
            //  userMeditationProgress: []
            //
            currentPlaylist: [],
            currentPlayedIdMeditation: -1,
        }
    }
    
    audio;
    localData = {
        checkpointObj: {},
        userData: {},
        currentIdCategory: this.props.match.params.idCategory || -1
    }

    componentDidMount() {
        // console.log('idCategory ', this.localData.currentIdCategory)
        console.log('props meditation ', this.props);
        // this.getUserLocalStorage();
    }
    
    componentWillUnmount() {
        this.changeMode('stop')
    }
    
    localStorage = new LocalStorage()
    
    getUserLocalStorage = async () => {
        this.localData.userData = await this.localStorage.getLocalStorage(this.localStorage.userLS)
        if (!this.localData.userData) {
            // window.location.href = "/"
            this.props.openAuthentificationPanel()

        } else {
            
            // console.log('localUser Data este ::: ', this.localData.userData)
            // this.getCategories();
            this.setState({ userData: this.localData.userData })

        }
    } 

    setPrevCheckpoint = (newCheckpointVal) => {
        this.setState({ prevCheckpoint: newCheckpointVal })
    }

    // am pus aici functia fiindca am nevoie de ea sa se declanseze si la stop()
    // getUserProgress = async () => {

    //     let progress = await getFromServer('users', {
    //         mode: 'user_progress_by_category',
    //         id_user: this.localData.userData.idul,
    //         id_sesiune: this.localData.userData.sessionIdul
    //     }, { credentials: 'include' })

    //     console.log('user progress ', progress)

    //     if (progress.r === 'ok') {

    //         this.setState({ userMeditationProgress: progress.continut })

    //     } else if (progress.r === 'err_auth') {

    //         console.error('Please Relogin');
    //         this.localStorage.deleteLocalStorage()

    //     } else {
    //         console.error('checkpoint error');
    //     }

    // }


    // FOR PLAYER

    setAudio = (audioFile, idMeditation, idCategory, audioDuration, audioName, checkpointObj=null) => {

        // verificam daca userul a schimbat categoria
        // daca da, setam id-ul categoriei curente si luam noul playlist
        if (this.localData.currentIdCategory !== idCategory) {
            console.log('idCategory nou primit ', idCategory)
            this.localData.currentIdCategory = idCategory

            this.getCategoryPlaylist()
        }
        // se
        this.setState({ fileName: audioFile, currentPlayedIdMeditation: idMeditation, audioDuration: audioDuration, mode: 'stop', audioName: audioName }, () => {
            console.log('audioFile ', audioFile)
            console.log('setare id meditatie ', this.state.currentPlayedIdMeditation)
            // console.log('audioDuration', this.state.audioDuration)
            this.setNewPlayerAudio()
        })

        // primire obiect pentru checkpoint
        this.localData.checkpointObj = checkpointObj
        // console.log('this.localData.checkpointObj', this.localData.checkpointObj)
    }

    getCategoryPlaylist = async () => {
        let categoryContent = await getFromServer('content', {
            mode: 'content_category_list',
            id_category: this.localData.currentIdCategory 
        }, { credentials: 'include' })

        console.log('playlist nou ', categoryContent.continut)
        if (categoryContent.r === 'ok') {
            this.setState({ currentPlaylist: categoryContent.continut })
        } else {
            console.error("error preluare meditatii pe categorie")
        }
    }

    // butoane de back si forward player
    backForwardPlaylist = (direction) => {
        // console.log('direction ', direction)
        // console.log('fisier curent ', this.state.fileName)

        let currentIndexInPlaylist = this.state.currentPlaylist.map(item => item.cale_audio ).indexOf(this.state.fileName)
        // console.log('current index ', currentIndexInPlaylist)

        let canChangeTrack = false;
        let newMeditationToPlay = {}
        if (direction === 'b' && currentIndexInPlaylist > 0) {
            console.log('play-am : ', this.state.currentPlaylist[Number(currentIndexInPlaylist) - 1])
            newMeditationToPlay = this.state.currentPlaylist[Number(currentIndexInPlaylist) - 1]
            canChangeTrack = true
        }
        
        if (direction === 'f' && (currentIndexInPlaylist < this.state.currentPlaylist.length - 1)) {
            console.log('play-am : ', this.state.currentPlaylist[Number(currentIndexInPlaylist)+1])
            newMeditationToPlay = this.state.currentPlaylist[Number(currentIndexInPlaylist) + 1]
            canChangeTrack = true
        }
        
        if (canChangeTrack) {
            this.setAudio(newMeditationToPlay.cale_audio, newMeditationToPlay.id_meditatie, this.localData.currentIdCategory, newMeditationToPlay.durata, newMeditationToPlay.nume)
        }

    }

    closePlayer = () => {
        this.setState({ fileName: '' });
        this.changeMode('stop');
    }

    /* START NEW PLAYER */

    setNewPlayerAudio = async () => {
        // console.log('filname ', this.state.fileName)

        if (this.state.fileName) {

            // in cazul in care exista deja un audio in desfasurare il oprim
            if (this.audio) {
                this.changeMode('stop');
            }

            // creare de element audio nou
            this.audio = new Audio();
            this.audio.src = mediaPath(this.state.fileName, "audio")

            // fara metadata nu transmite duration-ul audio-ului
            await this.audio.setAttribute('preload', 'metadata')

            this.audio.onloadedmetadata = () => {

                // console.log('durata: ', this.audio.duration)
                this.setState({ audio: this.audio })

            };

        }
    }

    changeMode = (newMode) => {

        if (newMode === 'play') {

            this.audio.play();
            this.setState({ mode: newMode }, () => console.log('am intrat in modul ', this.state.mode));

        } else if (newMode === 'stop') {

            // this.getUserProgress()

            if (typeof this.audio !== 'undefined') {
                this.audio.pause();
                
                this.setState({ mode: newMode, remain_time: this.state.audioDuration, audioDuration: this.state.audioDuration, prevCheckpoint: 0 }, () => {
                    // console.log('am intrat in modul ', this.state.mode)
                });
            }

        } else {

            this.audio.pause();
            this.setState({ mode: newMode }, () => console.log('am intrat in modul ', this.state.mode));

        }

    }

    /*   END NEW PLAYER */

    // getCategories = async () => {
    //     let categories = await getFromServer('category', {
    //         mode: 'list_categories',
    //     }, { credentials: 'include' })
        
    //     if (categories.r === 'ok') {
            
    //         console.error('www', categories.continut)
    //         this.setState({ categoriesList: categories.continut }, () => {
    //             console.log(this.state.categoriesList)
    //         });
    //     } else {
    //         alert('A aparut o problema la actualizare informatii')
    //     }

    // }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />

   

                {
                    // this.state.userData.idul &&
                    <MeditationsContainer
                        idCategorySelected={this.props.match.params.idCategory || this.localData.currentIdCategory} 
                        currentPlayedIdMeditation={this.state.currentPlayedIdMeditation}
                        setAudio={this.setAudio}
                        userData={this.state.userData}
                        openAuthentificationPanel={this.props.openAuthentificationPanel}
                    /> //categoriesList={this.state.categoriesList}  userMeditationProgress={this.state.userMeditationProgress} getUserProgress={this.getUserProgress}
                }

                {
                    this.state.fileName &&
                    <UserPlayer
                        audio={this.state.audio}
                        audioDuration={this.state.audioDuration}
                        remain_time={this.state.audioDuration}
                        mode={this.state.mode}
                        changeMode={this.changeMode}
                        audioName={this.state.audioName}
                        closePlayer={this.closePlayer}
                        backForwardPlaylist={this.backForwardPlaylist}
                        /// checkpoint 
                        prevCheckpoint={this.state.prevCheckpoint}
                        setPrevCheckpoint={this.setPrevCheckpoint}
                        checkpointObj={this.localData.checkpointObj}
                    />
                }
                <Footer history={this.props.history}/>
            </div>
        )
    }
}