import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { getFromServer } from '../../Providers/WS';
import LocalStorage from '../../Providers/LS';

export default class LoginFacebook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: false,
            name: '',
            picture: '',
            error: false
        }
    }

    errorMessage = '';

    localStorage = new LocalStorage();

    componentDidMount() {
    }

    fbClicked = () => {
    }

    responseFacebook = (response) => {
        console.log('response ', response)
        this.setState({ name: response.name, auth: true })

        if (this.props.mode === 'register') {
            console.log('go to register')
            this.registerWithFacebook(response)
        } else {
            console.log('go to login')
            this.loginWithFacebook(response);
        }
    }

    registerWithFacebook = async (fromFB) => {
        let registerFacebook
        try {
            registerFacebook = await getFromServer('insert_account', {
                mode: 'insert_fb_user',
                email: fromFB.email,
                name: fromFB.name
            }, { credentials: 'include' })
        }
        catch (e) {
            console.log('errror ', e)
        }

        console.log('register with facebook', registerFacebook)

        if (typeof registerFacebook === 'undefined') {

            // comut pe ecranul de logare
            this.props.changeAuthentificationMode()

        } else if (registerFacebook.r === 'err') {

            this.errorMessage = registerFacebook.mesaj
            this.setState({ error: true })

        } else {

        }
    }

    loginWithFacebook = async (fromFB) => {

        let loginFBResponse = await getFromServer('login', { mode: 'facebook_login', fb_email: fromFB.email }, { credentials: 'include' })

        console.log('login :', loginFBResponse);

        if (loginFBResponse.r === 'ok') {
            let userData = {
                isLogged: true,
                fbLogin: true,
                nume: loginFBResponse.continut.nume,
                idul: loginFBResponse.continut.idul,
                email: loginFBResponse.continut.email,
                username: loginFBResponse.continut.nume_utilizator,
                admin: loginFBResponse.continut.admin,
                blocked: loginFBResponse.continut.blocat,
                createdAt: loginFBResponse.continut.data_creare_cont,
                updatedAt: loginFBResponse.continut.data_modificare_cont,
                accountType: loginFBResponse.continut.label_tip_cont,
                sessionIdul: loginFBResponse.continut.id_sesiune
            }

            // salvare date in LS
            this.localStorage.setLocalStorage(this.localStorage.userLS, userData);

            window.location.href = "/";
            
        } else {
            this.errorMessage = loginFBResponse.mesaj
            this.setState({ error: true })
        }

    }

    render() {
        let facebookData;
        false ? facebookData = (
            <div>
                Hi, {this.state.name}
            </div>
        ) :
            facebookData = (
                <FacebookLogin
                    cssClass='facebook-login'
                    appId="2580575115401420"
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.fbClicked}
                    callback={this.responseFacebook}
                    textButton={this.props.buttonText}
                />
            )
        return (
            <div>
                {
                    this.state.error &&
                    <p className="error-message text-align">{this.errorMessage}</p>
                }
                {facebookData}
            </div>
        );
    }


}  // end class

