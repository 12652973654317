import React from 'react'; 
import { getFromServer } from '../../Providers/WS';
import LongSaveButton from '../Pieces/LongSaveButton';
import Validate from '../../Providers/Validator';

export default class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            emailStatus: '',
            email: ''
        }
    }

    validate = new Validate();
    errors = [];

    componentDidMount(){
        
    }


    
    sendEmail = async () => {

        this.errors = [];
        this.setState({ emailStatus: 'Va rugam asteptati...' })

        if (!this.validate.emailValidate(this.state.email)) {

            this.setState({ errors: true });
            this.errors[0] = 'Invalid e-mail address.';

        } else {

            let sendEmailResponse = await getFromServer('reset_password', {
                mode: 'check_email',
                email: this.state.email
            }, { credentials: 'include' })

            if (sendEmailResponse.r === 'ok') {
                this.setState({
                    emailStatus: sendEmailResponse.mesaj,
                    mail: ''
                })

            } else {
                this.setState({ emailStatus: sendEmailResponse.mesaj })
            }
        }
    }


    setInput = (type, ev) => {
        switch (type) {

            case 'email':
                this.setState({ email: ev });
                break;
            
            default:
                console.log('switch a intrat in default')

        }
    }

    render(){
        return (
            <div className="section backgroundWhite width80">
                <p className="title2 text-center">Recover Password</p>

                <p className="namaste-text width90 margin-auto" style={{ maxWidth: '500px' }}>Please enter your e-mail address in order to recover your password</p>

                <p className="namaste-label-site">E-mail address</p>
                <input
                    className="namaste-round-input"
                    value={this.props.email}
                    onChange={(ev) => {
                        this.setInput('email', ev.target.value)
                    }}
                ></input>

                { 
                    this.state.errors &&
                    <p className="error-message text-center">{this.errors[0]}</p>
                }                

                {
                    this.state.emailStatus && 
                    <p className="text-center">{this.state.emailStatus}</p>
                }    

                <LongSaveButton action={this.sendEmail} text="Send E-mail Adress" />
            </div>
        );
    }


}  // end class