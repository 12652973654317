import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { getFromServer } from '../../../Providers/WS';
import LocalStorage from '../../../Providers/LS';
import AddRoundButton from '../../Pieces/AddRoundButton';
import Validator from '../../../Providers/Validator';
import LongSaveButton from '../../Pieces/LongSaveButton';


export default class AdminAuthors extends React.Component {

    ls = new LocalStorage()
    validate = new Validator()

    localData = {
        userLS: {},
        newAuthorName: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            authors: [],
            isNewAuthorSectionEnabled: false
        }
    }

    componentDidMount() {
        console.log('props admin autori ', this.props);

        let userLS = this.ls.getLocalStorage(this.ls.userLS)
        if (userLS) {
            this.localData.userLS = userLS
            this.getAuthors();
        } else {
            // daca nu are userLS il trimit pe home
            // this.props.history.push('/');
            console.log('Eroare la preluare userLS');
        }

    }

    getAuthors = async () => {

        let authors = await getFromServer('author', {
            mode: 'get_authors_list',
            id_user: this.localData.userLS.idul,
            id_sesiune: this.localData.userLS.sessionIdul
        }, { credentials: 'include' })

        console.log('autorii mei ', authors)

        if (authors.r === 'ok') {

            this.setState({ authors: authors.continut })
            this.localData.autori = authors.continut
            console.log(this.localData.autori)

        } else {
            console.error(authors.mesaj)
        }

    }

    saveAuthor = async() => {
        let insert = await getFromServer('author', {
            mode: 'insert_author',
            author_name: this.localData.newAuthorName
        }, { credentials: 'include' })

        if (insert.r === 'ok') {
            this.props.history.push(`/admineditauthor/${insert.id_introdus}`)
        }       
    }

    showNewAuthorSection = () => {
        this.setState({ isNewAuthorSectionEnabled: true }, () => {
            console.log(`isNewAuthorSectionEnabled ${this.state.isNewAuthorSectionEnabled}`)
        });
    }

    validateAuthor = () => {
        let validateResponse = this.validate.lenghtValidate(3, this.localData.newAuthorName)

        if (!validateResponse) {
            this.setState({errors: true})
        } else {
            this.setState({ errors: false })
            this.saveAuthor()
        }
        console.log('validate response ', validateResponse)
    }

    setAuthorName = (input) => {
        this.localData.newAuthorName = input.target.value
    }

    goToAuthorDetails = (idul) => {
        this.props.history.push(`/admineditauthor/${idul}`)
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">


                    <div>
                        <p className="opacity-title blue-lite">Autori</p>
                        <AddRoundButton
                            action={this.showNewAuthorSection}
                        />

                        {
                            this.state.isNewAuthorSectionEnabled &&
                            <div>
                                <input className="namaste-round-input" placeholder="Introduceti numele noului autor" onChange={(ev) => { this.setAuthorName(ev) }}></input>

                                {
                                    this.state.errors &&
                                    <p className="error-message text-center">Introduceti un cuvant format din minim 4 caractere</p>
                                }

                                <LongSaveButton text="Salvare" action={this.validateAuthor}/>

                            </div>
                        }

                        <div className="table-head">
                            <div className="width20 inline-block">Nume Autor</div>
                            <div className="width50 inline-block">Descriere</div>
                            <div className="width30 inline-block">Website</div>
                        </div>

                        {
                            this.state.authors.map((item, index) => {
                                return (
                                    <div key={index} className="row-item" onClick={()=>this.goToAuthorDetails(item.idul)}>
                                        <div className="width20 inline-block">{item.nume}</div>
                                        <div className="width50 inline-block">{item.descriere}</div>
                                        <div className="width30 inline-block">{item.website}</div>
                                    </div>
                                )
                            })
                        }
                    </div>


                </div>
            </div>
        )
    }
}