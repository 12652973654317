import React from 'react';

import SiteHeader from '../SiteHeader/SiteHeader';
import Footer from '../Footer/Footer';
import { Grid } from '@material-ui/core';
import { getFromServer, mediaPath, default_image_meditation } from '../../Providers/WS';
import CategoryLink from '../../images/category_link.png'
import CategoryLinkHeart from '../../images/category_link_heart.png'
import CategoryLinkMind from '../../images/category_link_mind.png'
// import Login from '../LoginRegister/Login';

export default class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            categoriesList: [],
            categoryImg: 'neutral',
            link: ''
        }
    }

    componentDidMount() {
        console.log('props home ', this.props);
        this.getCategories()
    }

    // goToMeditation = (idCategory) => {
    //     this.props.history.push(`/meditations/${idCategory}`);
    // }

    goToMeditation = () => {
        this.props.history.push(`${this.state.link}`);
    }

    getCategories = async () => {

        let categories = await getFromServer('category', {
            mode: 'list_categories',
        }, { credentials: 'include' })

        if (categories.r === 'ok') {
            console.log('categries ', categories)
            this.setState({ categoriesList: categories.continut }, () => {
                console.log('categorii', this.state.categoriesList)
            });
        } else {
            alert('A aparut o problema la actualizare informatii')
        }

    }

    getCoords = (evt) => {

        var itemCoords = evt.target.getBoundingClientRect();
        // let category = ''
        // console.log('rect ', itemCoords)
        // console.log('rect ', evt.clientX)
        // console.log('rect ', evt.clientY)

        if ((evt.clientX - itemCoords.x) < itemCoords.width / 2) {

            this.setState((prevState, prevProps) => {
                if ('mind' != prevState.categoryImg) {

                    return { categoryImg: 'mind', link: '/meditations/1' }
                }
            }, () => { console.log('new state ', this.state.categoryImg) })

        } else {

            this.setState((prevState) => {
                // console.log('curr state', this.state.categoryImg)
                // console.log('prev state', prevState.categoryImg)
                if ('heart' != prevState.categoryImg) {

                    return { categoryImg: 'heart', link: '/meditations/2' }
                }
            }, () => { console.log('new state ', this.state.categoryImg) })
        }

    }


    render() {
        return (
            <div>

                <div>
                    <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />

                    <div className="section background-namaste width80">
                        {/* <Grid container>
                            {
                                this.state.categoriesList.length > 0 &&
                                this.state.categoriesList.map( (category, idx) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={`categ${idx}`}>
                                            <div
                                                onClick={() => this.goToMeditation(category.idul)}
                                                className="category-image"
                                                
                                                style={
                                                    
                                                        category.cale_poza_listare ?
                                                            { backgroundImage: `url("${mediaPath(category.cale_poza_listare, 'images')} ") ` } :
                                                            { backgroundImage: `url("${default_image_meditation}")` }
                                                    
                                                }
                                            ></div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid> */}

                        <div style={{
                            position: 'relative',
                            top: '50%',
                            transform: 'translateY(-69%)'
                        }}>
                            <div onClick={() => this.goToMeditation()}>

                                <img
                                    id='category_img'
                                    src={this.state.categoryImg === 'heart' ? CategoryLinkHeart :
                                        (this.state.categoryImg === 'mind' ? CategoryLinkMind : CategoryLink)}
                                    width="400"
                                    height="315"
                                    style={{ margin: 'auto', display: 'block', cursor: 'pointer' }}
                                    onMouseMove={(e) => this.getCoords(e)}
                                >
                                </img>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer history={this.props.history} />
            </div >

        )
    }

}