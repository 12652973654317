import React from 'react';

import { getFromServer } from '../../../Providers/WS';
import Utils from '../../../Providers/Utils';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import ProgramatorLuni from './ProgramatorLuni'
import { Dialog, DialogActions, DialogContent, Button, FormControl, Input, InputLabel } from '@material-ui/core'


export default class AdminQuotation extends React.Component {
    utils = new Utils();

    constructor(props) {
        super(props);
        this.state = {
            dataProgramareZi: this.utils.defaultData(),
            month: '',
            year: '',
            citateLuni: [],
            citatSelectat: [{
                autor: '',
                text: '',
                idul: 0
            }],
            openAdd: false
        }
    }

    componentDidMount() {
        this.getCitate()
        this.calendarLunar()
    }

    calendarLunar = () => {
        this.setState({
            button1: 'white',
            button3: '#518adf',
            displayZiInput: 'none',
            color2: 'white',
            color1: '#518adf',
            displayLuni: 'block'
        }, () => {
            this.getMonth()
            this.getYear()
        })
    }

    getMonth = () => {
        let date = this.state.dataProgramareZi.split('-')
        let month = Number(date[1])
        this.setState({ month: month })
    }

    getYear = () => {
        let date = this.state.dataProgramareZi.split('-')
        let year = Number(date[0])
        this.setState({ year: year })
    }

    plusLuna = () => {
        let date = new Date(this.state.dataProgramareZi)

        date.setMonth(date.getMonth() + 1)

        this.setState({
            dataProgramareZi: this.utils.transformDataForSql(date),
            data: this.utils.transformDataForSql(date)
        }, () => {

            this.getMonth()
            this.getYear()

        })
    }

    minusLuna = () => {
        let date = new Date(this.state.dataProgramareZi)

        date.setMonth(date.getMonth() - 1)

        this.setState({
            dataProgramareZi: this.utils.transformDataForSql(date),
            data: this.utils.transformDataForSql(date)
        }, () => {

            this.getMonth()
            this.getYear()

        })
    }


    getCitate = async () => {
        let citate = await getFromServer('maxime', { mode: 'get_list_admin' }, { credentials: 'include' })

        console.log('citate ', citate)

        if (citate.r === 'ok') {
            this.setState({ citateLuni: citate.continut })
        }
    }

    getCitat = () => {
        let citate = [...this.state.citateLuni]
        let citateFilter = citate.filter(c => c.data_listare === this.state.dataProgramareZi)

        if (citateFilter.length > 0) {

            this.setState({
                citatSelectat: citateFilter,
                openAdd: true
            })

        } else {
            this.setState({
                citatSelectat: [{
                    autor: '',
                    text: '',
                    idul: 0
                }],
                openAdd: true
            })
        }
    }

    goToZi = (d) => {


        let ziua = (d + 1) < 10 ? ('0' + (d + 1)) : String(d + 1)

        let data = this.state.dataProgramareZi.split('-')
        data[2] = ziua

        let dataNew = data.join('-')

        this.setState({ dataProgramareZi: dataNew }, () => {
            this.getCitat()

        })

    }

    changeText = camp => ev => {
        let oldState = this.state.citatSelectat
        oldState[0][camp] = ev.target.value

        this.setState({ citatSelectat: oldState })
    }

    closeAdd = () => {
        this.setState({ openAdd: false })
    }

    editCitat = async () => {

        let edit = await getFromServer('maxime', {
            mode: 'edit_maxima',
            idul: this.state.citatSelectat[0].idul,
            text: this.state.citatSelectat[0].text,
            author: this.state.citatSelectat[0].autor,
            listing_date: this.state.dataProgramareZi
        }, { credentials: 'include' })


        if (edit.r === 'ok') {
            this.setState({ openAdd: false })
        }
    }

    insertCitat = async () => {


        let insert = await getFromServer('maxime', {
            mode: 'insert_maxima', text: this.state.citatSelectat[0].text,
            author: this.state.citatSelectat[0].autor,
            listing_date: this.state.dataProgramareZi
        }, { credentials: 'include' })
        if (insert.r === 'ok') {
            let citate = [...this.state.citateLuni]

            let citatSelectat = {
                idul: insert.id_introdus,
                text: this.state.citatSelectat[0].text,
                autor: this.state.citatSelectat[0].autor,
                data_listare: this.state.dataProgramareZi
            }

            citate.push(citatSelectat)

            this.setState({
                citateLuni: citate,
                openAdd: false
            })
        }

    }

    stergeCitat = async () => {

        let sterge = await getFromServer('maxime', { mode: 'hide_maxima', idul: this.state.citatSelectat[0].idul }, { credentials: 'include' })

        if (sterge.r === 'ok') {

            let citate = [...this.state.citateLuni]
            let idx = citate.findIndex(c => c.idul === this.state.citatSelectat[0].idul)

            citate.splice(idx, 1)

            this.setState({
                citateLuni: citate,
                openAdd: false
            })
        }

    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    {
                        <div>
                            <p className="opacity-title blue-lite">Quotations</p>
                        </div>
                    }
                </div>
                <Dialog

                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.openAdd}
                    onClose={this.closeAdd}
                >

                    <DialogActions style={{ justifyContent: 'center' }}>

                        <Button className='meditatii-close-dialog-button' onClick={this.closeAdd} variant="fab">
                            <i className='material-icons'>clear</i>
                        </Button>

                    </DialogActions>


                    <h2 style={{ padding: '20px 10px 0 10px', marginTop: 20, textAlign: 'center' }} className="meditatii-section-meditezi-title">
                        {this.state.citatSelectat[0].idul !== 0 ? 'Schimba Citat' : 'Adauga Citat'}
                    </h2>

                    <DialogContent style={{ textAlign: 'center' }}>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-nume-categorie">Autor</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-nume-categorie"
                                type='text'
                                value={this.state.citatSelectat[0].autor}
                                onChange={this.changeText('autor')}


                            />
                        </FormControl>

                        <FormControl className="meditatii-text-field" style={{ width: "100%" }}  >
                            <InputLabel style={{ color: "#bfbfbf" }} htmlFor="meditatii-nume-categorie">Text</InputLabel>
                            <Input
                                disableUnderline={true}
                                id="meditatii-nume-categorie"
                                multiline
                                type='text'
                                value={this.state.citatSelectat[0].text}
                                onChange={this.changeText('text')}


                            />
                        </FormControl>



                        <Button disabled={this.state.disableInsert} style={{ marginTop: 20 }} variant='contained' color="primary" className='meditatii-autentificare-button' onClick={this.state.citatSelectat[0].idul !== 0 ? this.editCitat : this.insertCitat} >
                            {this.state.citatSelectat[0].idul !== 0 ? "Editeaza" : 'Adauga'}
                        </Button>
                        <Button style={{ marginTop: 20, display: this.state.citatSelectat[0].idul !== 0 ? 'inline-flex' : 'none', }} variant='contained' color="primary" className={this.state.citatSelectat[0].idul !== 0 ? 'meditatii-delete-button' : ''} onClick={this.stergeCitat} >
                            Sterge
                        </Button>

                    </DialogContent>
                </Dialog>
                < ProgramatorLuni
                    anul={this.state.year}
                    luna={this.state.month}
                    eventsProps={this.state.citateLuni}
                    display={this.state.displayLuni}
                    plusLuna={this.plusLuna}
                    minusLuna={this.minusLuna}
                    getCitat={this.getCitat}
                    goToZi={this.goToZi} />
            </div>
        );
    }
}

;