import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { getFromServer } from '../../../Providers/WS';
import { mediaPath } from '../../../Providers/WS';
import LocalStorage from '../../../Providers/LS';
import AddRoundButton from '../../Pieces/AddRoundButton';
import Validator from '../../../Providers/Validator';
import NewMeditationSection from './NewMeditationSection';


export default class AdminMeditations extends React.Component {

    ls = new LocalStorage()
    validate = new Validator()

    localData = {
        userLs: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            meditations: [],
            categories: [],
            showNewMeditationSection: false
        }
    }

    componentDidMount() {
        let userLS = this.ls.getLocalStorage(this.ls.userLS)
        if (userLS) {
            this.localData.userLS = userLS
            this.getCategories();
        } else {
            // daca nu are userLS il trimit pe home
            this.props.history.push('/');
            console.log('Eroare la preluare userLS');
        }
    }

    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })
        console.log('categories in content ', categories)
        if (categories.r === 'ok') {
            this.setState({ categories: categories.continut }, () => {
                this.getContent()
            })
        }
    }

    getContent = async () => {

        let content = await getFromServer('content', { mode: 'content_list_admin', id_user: this.localData.userLS.idul, id_sesiune: this.localData.userLS.sessionIdul }, { credentials: 'include' })
        if (content.r === 'ok') {

            let uniqueArr = [];

            uniqueArr = content.continut.filter((el, i, self) => (
                i === self.findIndex((t) => t.nume === el.nume)
            ))

            let counter = uniqueArr.length

            for (let i = 0; i < counter; i++) {

                let counter2 = content.continut.length
                for (let k = 0; k < counter2; k++) {
                    if ((uniqueArr[i].idul === content.continut[k].idul) && (uniqueArr[i].id_categorie !== content.continut[k].id_categorie)) {

                        uniqueArr[i].nume_categorie += ', ' + content.continut[k].nume_categorie + ", "
                    }
                }
                if (uniqueArr[i].nume_categorie !== null && (uniqueArr[i].nume_categorie.substring(uniqueArr[i].nume_categorie.length - 2) === ', ')) {

                    uniqueArr[i].nume_categorie = uniqueArr[i].nume_categorie.substring(0, uniqueArr[i].nume_categorie.length - 2)
                }
            }

            this.setState({
                meditations: uniqueArr,
                filterContent: uniqueArr
            }, () => {
                    console.log('content ', this.state.meditations)
            })
        }
    }

    showNewMeditationSection = () => {
        this.setState({ showNewMeditationSection: true }, () => {});
    }

    goToMeditationDetails = (idul) => {
        this.props.history.push(`/adminmeditation/${idul}`)
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">

                    <div>
                        <p className="opacity-title blue-lite">Meditatii</p>
                        <AddRoundButton
                            action={this.showNewMeditationSection}
                        />

                        {
                            this.state.showNewMeditationSection &&
                            <NewMeditationSection categories={this.state.categories} setInputs={this.setInput} history={this.props.history} />
                        }

                        <div className="table-head">
                            <div className="width10 inline-block" style={{ width: '40px' }}>Poza</div>
                            <div className="width40 inline-block">Nume</div>
                            <div className="width40 inline-block">Categorie</div>
                        </div>

                        {
                            this.state.meditations.map((item, index) => {
                                return (
                                    <div key={index} className="row-item" onClick={() => this.goToMeditationDetails(item.idul)}>
                                        <img
                                            alt = "meditation-list"
                                            className="width10 inline-block"
                                            style={{width: '40px'}}
                                            src={item.cale_poza_listare !== null ? mediaPath(item.cale_poza_listare, 'images') : mediaPath('default_img.jpg', 'images')}
                                        /> 
                                        <div className="width40 inline-block">{item.nume}</div>
                                        <div className="width40 inline-block">{item.nume_categorie}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }


}