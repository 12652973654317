import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import LongSaveButton from '../../Pieces/LongSaveButton'

import { mediaPath } from '../../../Providers/WS';
import { getFromServer } from '../../../Providers/WS';
import { visibilityOptions } from '../../../Providers/WS';

import AddRoundButton from '../../Pieces/AddRoundButton';

import { Input, Select, MenuItem } from '@material-ui/core'

export default class AdminEditCategory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            isDeleted: '',
            subtitle: '',
            image: '',
            appVisibility: '',
            deletedAt: '',
            updatedAt: '',
            visibilityOptions: visibilityOptions
        }
    }

    localData = {
    }

    componentDidMount() {
        this.getAuthor()
    }

    getAuthor = async () => {

        let category = await getFromServer('category', {
            mode: 'get_category',
            id_category: this.props.match.params.idCategory
        }, { credentials: 'include' })

        console.log('categorie ', category)

        if (category.r === 'ok') {
            this.setState({
                name: category.continut[0].nume_categorie,
                subtitle: category.continut[0].subtitlu || '',
                isDeleted: category.continut[0].sters || '',
                appVisibility: category.continut[0].vizibilitate,
                image: category.continut[0].cale_poza_listare,
                deletedAt: category.continut[0].date_sters,
                updatedAt: category.continut[0].data_modificare,

            }, () => {
                console.log('categorie ', this.state.subtitle)
            })
        } else {
            console.log('eroare la preluare autor')

        }
    }

    updateCategory = async () => {

        let updateAutor = await getFromServer('category', {
            mode: 'update_category',
            id_category: this.props.match.params.idCategory,
            tagline: this.state.subtitle,
            category_name: this.state.name,
            visibility: this.state.appVisibility

        }, { credentials: 'include' })

        if (updateAutor.r === 'ok') {
            this.props.history.push('/adminsubcategories');
        } else {
            alert('A aparut o problema la actualizare informatii')
        }

    }

    setInput = (type, ev) => {
        switch (type) {
            case 'subtitle':
                this.setState({ subtitle: ev.target.value });
                break;
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }

    selectFile = () => {
        let fileInput = document.getElementById('input-poza-listare');
        fileInput.click()
    }

    fileChange = async (idfile, idListare) => {
        let saveFile = document.getElementById(idfile).files[0];
        let pozaListare = document.getElementById(idListare)
     
        let reader = new FileReader();
        reader.readAsDataURL(saveFile);

        reader.onload = async () => {
            let fileContent = reader.result;

            pozaListare.src = fileContent

            await getFromServer('category', {
                mode: 'insert_list_image',
                id_category: this.props.match.params.idCategory
            },
                { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')
        }

    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <p className="opacity-title blue-lite">Editare Categorie</p>
                    {
                        this.state.name &&
                        <div>

                            {/* Camp pentru upload poza, ascuns */}
                            <input id="input-poza-listare" type="file" accept="image/*"
                                onChange={() => { this.fileChange("input-poza-listare", 'list-image') }}
                                style={{ display: 'none' }}
                            ></input>

                            {/* Upload poza element */}
                            <div className="image-thumb-admin-container ">
                                <img
                                    alt="namaste-space-category"
                                    id='list-image'
                                    className='image-thumb-admin'
                                    src={this.state.image !== null ? mediaPath(this.state.image, 'images') : mediaPath('default_img.jpg', 'images')}
                                />
                                <AddRoundButton cssStyle={{ position: 'absolute', top: '-10px', right: '-10px' }} action={this.selectFile} />
                            </div>

                            <p className="namaste-label">Categorie</p>
                            <input
                                className="namaste-round-input"
                                value={this.state.name || ''}
                                onChange={ev => {
                                    this.setInput('name', ev)
                                }}
                            >
                            </input>

                            <p className="namaste-label">Subtitlu</p>
                            <input
                                className="namaste-round-input"
                                placeholder="Subtitlu"
                                value={this.state.subtitle || ''}
                                onChange={ev => {
                                    this.setInput('subtitle', ev)
                                }}
                            >
                            </input>

                            <p className="namaste-label">Vizibilitate</p>
                            <Select
                                value={this.state.appVisibility}
                                onChange={(ev) => { this.setState({ appVisibility: ev.target.value }) }}
                                className="namaste-select-box"
                                input={<Input id="val" />}>
                                {this.state.visibilityOptions.map((item, idx) => {
                                    return <MenuItem value={item.idul} key={idx} > {item.nume}</MenuItem>
                                })
                                }
                            </Select>

                            <LongSaveButton text='Actualizare' action={this.updateCategory} />
                        </div>
                    }

                </div>
            </div>
        )
    }
}