import React from 'react';
import AdminMenu from './AdminMenu';

export default class AdminHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        console.log('props admin header ', this.props);
    }


    render() {
        return (

            <div className="admin-container">

                <h1 className="title1 blue-lite">GURU</h1>
                <AdminMenu history={this.props.history}/>

            </div>

        )
    }
}