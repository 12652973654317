import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import LongSaveButton from '../../Pieces/LongSaveButton'

import { getFromServer } from '../../../Providers/WS';
import { Select, MenuItem } from '@material-ui/core';

export default class AdminEditSubcategory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            categoriesList: [],
            currentCategory: {}
        }
    }

    localData = {
    }

    componentDidMount() {
        
        this.getSubcategory();
        this.getCategories();
        this.getCurrentCategoryOfSubcategory();
    }

    getSubcategory = async () => {

        let subcategory = await getFromServer('subcategory', {
            mode: 'get_subcategory',
            id_subcategory: this.props.match.params.idSubcategory
        }, { credentials: 'include' })

        console.log('subcategorie ', subcategory)

        if (subcategory.r === 'ok') {
            this.setState({
                name: subcategory.continut[0].nume_subcategorie,
            }, () => {
                console.log('categorie ', this.state.name) 
            })
        } else {
            console.log('eroare la preluare subcategory')
        }
    }

    getCurrentCategoryOfSubcategory = async () => {
        let currentCategory = await getFromServer('subcategory', { mode: 'get_category_of_subcategory', id_subcategory: this.props.match.params.idSubcategory }, { credentials: 'include' })
        console.log('currentCategory ', currentCategory)
        if (currentCategory.r === 'ok') {
            this.setState({ currentCategory: currentCategory.continut[0] }, () => {
                console.log('currentCategory ', this.state.currentCategory.id_categorie)
            })
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })
        // console.log('categories ', categories)
        if (categories.r === 'ok') {
            this.setState({ categoriesList: categories.continut }, () => {
                console.log('categoriesList ', this.state.categoriesList)
            })
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    updateSubcategory = async () => {

        let updateSubcategory = await getFromServer('subcategory', {
            mode: 'update_subcategory',
            id_subcategory: this.props.match.params.idSubcategory,
            subcategory_name: this.state.name,

        }, { credentials: 'include' })

        if (updateSubcategory.r === 'ok') {
            this.props.history.push('/admincategories');
        } else {
            alert('A aparut o problema la actualizare informatii')
        }

    }

    updateCurrentCategoryOfSubcategory = async (IdCategorySelected) => {
        if (IdCategorySelected) {
            let updateCategorySubcategory = await getFromServer('subcategory', {
                mode: 'update_category_of_subcategory',
                id_subcategory: this.props.match.params.idSubcategory,
                id_category: IdCategorySelected

            }, { credentials: 'include' })

            if (updateCategorySubcategory.r === 'ok') {
                this.props.history.push('/admincategories');
            } else {
                alert('A aparut o problema la actualizare informatii')
            }
        } else {
            console.error('Probleme la preluare id categorie selectat')
        }
    }

    setInput = (type, ev) => {
        switch (type) {
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <p className="opacity-title blue-lite">Editare Subcategorie</p>

                    <div>
                        <p className="namaste-label">Subcategorie</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.name || ''}
                            onChange={ev => {
                                this.setInput('name', ev)
                            }}
                        >
                        </input>

                        <LongSaveButton text='Actualizare' action={this.updateSubcategory} />
                    </div>

                    {
                        this.state.currentCategory.id_categorie && this.state.categoriesList.length > 0 &&
                        <div>
                            <p className="namaste-label">Schimba Categorie</p>
                            <Select
                                value={this.state.currentCategory.id_categorie}
                                onChange={(ev) => { this.updateCurrentCategoryOfSubcategory(ev.target.value) }}
                                className="namaste-select-box"
                            >
                                {
                                    this.state.categoriesList.map((item, idx) => {
                                        return <MenuItem value={item.idul} key={idx}>{item.nume_categorie}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                    }

                </div>
            </div>
        )
    }
}