import React from 'react';

export const Separator = (props) => {
    return (

        <div style={{ backgroundColor: props.bgcolor }} className="separator">

        </div>
    )
}
