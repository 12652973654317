import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { getFromServer } from '../../../Providers/WS';
import LocalStorage from '../../../Providers/LS';
import AddRoundButton from '../../Pieces/AddRoundButton';
import Validator from '../../../Providers/Validator';
import LongSaveButton from '../../Pieces/LongSaveButton';

import SwitchButton from '../../Pieces/SwitchButton';
import AdminSubcategories from './AdminSubcategories';


export default class AdminCategories extends React.Component {

    ls = new LocalStorage()
    validate = new Validator()

    localData = {
        newCategoryName: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            errors: false,
            isNewCategorySectionEnabled: false,
            categoriesList: [],
            isCategoryHidden: ''
        }
    }

    componentDidMount() {
        this.getCategories();
    }


    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })
        console.log('categories ', categories)
        if (categories.r === 'ok') {
            this.setState({ categoriesList: categories.continut }, () => {
            })
        }
    }

    showNewCategorySection = () => {
        this.setState({ isNewCategorySectionEnabled: true })
    }

    setCategoryName = (input) => {
        this.localData.newCategoryName = input.target.value
    }

    saveCategory = async () => {

        let insertCategory = await getFromServer('category', { category_name: this.localData.newCategoryName, mode: 'insert_category' }, { credentials: 'include' })

        if (insertCategory.r === 'ok') {
            this.props.history.push(`/admincategory/${insertCategory.id_introdus}`)
        } else {
            alert('A aparut o problema la creare categorie.')
        }

    }

    validateCategory = () => {
        let validateResponse = this.validate.lenghtValidate(2, this.localData.newCategoryName)

        if (!validateResponse) {
            this.setState({ errors: true })
        } else {
            this.setState({ errors: false })
            this.saveCategory()
        }
        console.log('validate response ', validateResponse)
    }

    changeCategoryStatus = async (deleted, idul, ev) => {
        this.setState({ isCategoryHidden: ev.target.checked }, async () => {
            if (deleted !== 1) {
                await getFromServer('category', { mode: 'delete_category', id_category: idul }, { credentials: 'include' })
            } else {
                await getFromServer('category', { mode: 'publish_category', id_category: idul }, { credentials: 'include' })
            }
            this.getCategories();
        })
    }

    goToCategoryDetails = (idul) => {
        this.props.history.push(`/admincategory/${idul}`)
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">

                    <div>
                        <p className="opacity-title blue-lite">Categorii</p>
                        <AddRoundButton
                            action={this.showNewCategorySection}
                        />

                        {
                            this.state.isNewCategorySectionEnabled &&
                            <div>
                                <input
                                    className="namaste-round-input"
                                    placeholder="Introduceti numele categoriei"
                                    onChange={(ev) => { this.setCategoryName(ev) }}
                                >
                                </input>

                                {
                                    this.state.errors &&
                                    <p className="error-message text-center">Introduceti un cuvant format din minim 3 caractere</p>
                                }

                                <LongSaveButton text="Salvare" action={this.validateCategory} />

                            </div>
                        }


                        <div className="table-head">
                            <div className="width80 inline-block">Nume</div>
                            <div className="width20 inline-block">Status</div>
                        </div>

                        {
                            this.state.categoriesList &&
                            this.state.categoriesList.map((item, index) => {
                                return (
                                    <div key={index} className="row-item">
                                        <div className="width80 inline-block" onClick={() => this.goToCategoryDetails(item.idul)}>
                                            <p style={{ lineHeight: '37px' }}>{index + 1}. {item.nume_categorie}</p>
                                        </div>
                                        <div className="width20 inline-block">
                                            <SwitchButton item={item} action={this.changeCategoryStatus} />
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* Subcategorii */}
                    {
                        this.state.categoriesList.length > 0 &&
                        <AdminSubcategories categoriesList={this.state.categoriesList} history={this.props.history}/>
                    }

                </div>
            </div>
        )
    }


}