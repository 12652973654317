import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import { getFromServer } from '../../Providers/WS';
import LocalStorage from '../../Providers/LS';
import Validate from '../../Providers/Validator';
import LongSaveButton from '../Pieces/LongSaveButton';
import Footer from '../Footer/Footer';

export default class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            mode: 'user-details',
            oldPassword: '',
            newPassword1: '',
            newPassword2: ''
        }
    }

    localStorage = new LocalStorage();
    validate = new Validate();
    userData = {};

    componentDidMount() {
        this.getUserLocalStorage()
    }

    getUserLocalStorage = () => {
        this.userData = this.localStorage.getLocalStorage(this.localStorage.userLS)

        this.setState({ username: this.userData.username, email: this.userData.email }, () => {
            console.log('state ', this.state.setState)
        })

        // console.log('localUser Data este ::: ', this.userData)
    }

    setInput = (type, ev) => {
        switch (type) {

            case 'email':
                this.setState({ email: ev });
                break;

            case 'oldPassword':
                this.setState({ oldPassword: ev });
                break;

            case 'newPassword1':
                this.setState({ newPassword1: ev });
                break;

            case 'newPassword2':
                this.setState({ newPassword2: ev });
                break;

            case 'mode':
                this.setState({ mode: ev });
                break;

            default:
                console.log('switch a intrat in default')
        }
    }

    validateUserInputs = (mode) => {

        this.errors = [];

        console.log('mnode', mode)
        switch (mode) {

            // update date cont
            case 'account-details':
                this.changeUserDetails()
                break;

            // update password
            case 'change-password':
                this.verifyPassword()
                break;

            default:
                console.error('Default switch')
        }

        if (this.errors.length === 0) {

            // doar daca am avut erori in trecut
            if (this.state.errors) {
                this.setState({ errors: false });
            }
        }

        
    }

    changeUserDetails = async () => {

        if (!this.validate.emailValidate(this.state.email)) {

            this.setState({ errors: true });
            this.errors[0] = 'Invalid e-mail address.';

        } else {

            let updateResponse = await getFromServer('insert_account', {
                mode: 'edit_user',
                id: this.userData.idul,
                email: this.state.email,
                name: '',
                id_sesiune: this.userData.sessionIdul,
            }, { credentials: 'include' })

            console.log('response update ', updateResponse)

            if (updateResponse.r === 'ok') {

                // salvare date in LS
                this.userData.email = this.state.email
                this.localStorage.setLocalStorage(this.localStorage.userLS, this.userData);
            } else {
                console.error('Eroare la salvare');
            }
        }
    }

    verifyPassword = () => {

        if (this.state.newPassword1 !== this.state.newPassword2) {

            this.setState({ errors: true });
            this.errors[0] = 'There is a difference between the new passwords. Please check again.';

        }
        else if (!this.validate.lenghtValidate(4, this.state.newPassword1) || !this.validate.lenghtValidate(4, this.state.oldPassword)){

            this.setState({ errors: true });
            this.errors[0] = 'The password must contain at least 5 characters.';

        }
        else {
            this.setState({ errors: false });
            this.errors = [];

            console.log('aici update ',)
            this.updatePassword()

        }

    }

    updatePassword = async () => {
        let pass = {
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword1
        }

        let changePassResponse = await getFromServer('insert_account', {
            id: this.userData.idul,
            mode: 'edit_password'
        }, { credentials: 'include', method: 'POST' }, false, 'POST', JSON.stringify(pass), 'postData')

        console.log('change pass response ', changePassResponse);


        if (changePassResponse.r === 'ok') {
            
            this.setInput('mode', 'user-details')

        } else {

            this.setState({ errors: true });
            this.errors[0] = changePassResponse.mesaj;

        }
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />

                {/* USER DETAILS NAMASTE ACCOUNT */}

                {
                    this.state.mode === 'user-details' && !this.userData.fbLogin &&
                    <div className="section backgroundWhite width80">

                        <div className="section-container width80 margin-auto">

                            <p className="title2 text-center">Account Details</p>

                            <p className="namaste-label-site">Username</p>
                            <p className="namaste-text width80 margin-auto" style={{maxWidth: '400px'}}>{this.state.username}</p>

                            <br />

                            <p className="namaste-label-site">E-mail address</p>
                            <input
                                className="namaste-round-input"
                                value={this.state.email}
                                onChange={(ev) => {
                                    this.setInput('email', ev.target.value)
                                }}
                            ></input>

                            {
                                this.state.errors &&
                                <p className="error-message text-center">{this.errors[0]}</p>
                            }

                            <LongSaveButton action={() => this.validateUserInputs('account-details')} text="Save Account Details" />

                            <p className="link text-center" onClick={() => this.setInput('mode', 'change-password')}>Change Password</p>
                        </div>
                    </div>
                }

                {/* USER DETAILS FACEBOOK ACCOUNT */}

                {
                    this.state.mode === 'user-details' && this.userData.fbLogin &&
                    <div className="section backgroundWhite width80">

                        <div className="section-container width80 margin-auto">

                            <p className="title2 text-center">Account Details</p>

                            <p className="namaste-label-site">Name</p>
                            <p className="namaste-text width80 margin-auto" style={{ maxWidth: '400px' }}>{this.userData.nume}</p>

                   
                            <p className="namaste-label-site">E-mail</p>
                            <p className="namaste-text width80 margin-auto" style={{ maxWidth: '400px' }}>{this.userData.email}</p>

                        </div>
                    </div>
                }
                
                {/* UPDATE PASSWORD */}

                {
                    this.state.mode === 'change-password' &&
                    <div className="section backgroundWhite width80">

                        <div className="section-container width80 margin-auto">
                            <p className="title2 text-center">Change Password</p>
                        </div>

                        <p className="namaste-label-site"> Type old password</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.oldPassword}
                            type="password"
                            onChange={(ev) => {
                                this.setInput('oldPassword', ev.target.value)
                            }}
                        ></input>

                        <p className="namaste-label-site">Type new password</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.newPassword1}
                            type="password"
                            onChange={(ev) => {
                                this.setInput('newPassword1', ev.target.value)
                            }}
                        ></input>

                        <p className="namaste-label-site">Type again new password</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.newPassword2}
                            type="password"
                            onChange={(ev) => {
                                this.setInput('newPassword2', ev.target.value)
                            }}
                        ></input>

                        {
                            this.state.errors &&
                            <p className="error-message text-center">{this.errors[0]}</p>
                        }

                        <LongSaveButton action={() => this.validateUserInputs('change-password')} text="Change Password" />
                        <p className="link text-center" onClick={() => this.setInput('mode', 'user-details')}>Change User Details</p>
                    </div>

                }

                <Footer history={this.props.history}/>
            </div>
        );
    }


}  // end class