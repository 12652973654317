import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import AdminHeader from './AdminHeader';
import { getFromServer } from '../../Providers/WS';

export default class AdminClients extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orderby: 'numeasc',
            nrEl: 100,
            nrPagina: 1,
            clienti: [],
            cauta: ''
        }

    }

    componentDidMount() {
        console.log('props admin clients ', this.props);
        // this.verifyIsAdmin()
        this.getClients(this.state.orderby, this.state.nrEl, this.state.nrPagina);
    }

   

    getClients = async (orderBy, nrEl, nrPagina) => {

        let clienti = await getFromServer('users', {
            mode: 'list_users',
            current_page: nrPagina,
            nr_elem_page: nrEl,
            order_by: orderBy
        }, { credentials: 'include' })

        console.log('clientii mei ', clienti)

        if (clienti.r === 'ok') {

            let cloneClienti = [...this.state.clienti]
            let counter = clienti.continut.length

            for (let i = 0; i < counter; i++) {
                cloneClienti.push(clienti.continut[i])
            }

            this.setState({ clienti: cloneClienti })
        } else {

        }

    }

    goToUser = (idul) => {
        this.props.history.push(`/adminuserdetais/${idul}`)
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">

                    {
                        <div>
                            <p className="opacity-title blue-lite">Utilizatori</p>

                            <div className="table-head">
                                <div className="width33 inline-block">Username</div>
                                <div className="width33 inline-block">E-mail</div>
                                <div className="width33 inline-block">Creare Cont</div>
                            </div>

                            {
                                this.state.clienti.map((item, index) => {
                                    return (
                                        <div key={index} className="row-item" onClick={()=>{this.goToUser(item.idul)}}>
                                            <div className="width33 inline-block">{item.nume_utilizator}</div>
                                            <div className="width33 inline-block">{item.email}</div>
                                            <div className="width33 inline-block">{item.data_creare_cont}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </div>
            </div>
        )
    }
}