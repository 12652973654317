import { getFromServer } from './WS';

export default class Utile {

    lunileAnuluiRomana = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"];

    dataCurentaForSql = () => {
        let myDate = new Date()

        let year = myDate.getFullYear()

        let lunaNormala = myDate.getMonth() + 1
        let month = (lunaNormala < 10 ? '0' : '') + lunaNormala

        let day = (myDate.getDate() < 10 ? '0' : '') + myDate.getDate()

        return `${year}-${month}-${day}`
    }

    specificDateToDateField = (dt) => {
        // yyyy-MM-dd
        let cur = new Date(dt)
        let an = cur.getFullYear()
        let luna = cur.getMonth() + 1
        let ziua = cur.getDate()

        let ziuaAsString = String(ziua).length === 1 ? `0${ziua}` : String(ziua)
        let lunaAsString = String(luna).length === 1 ? `0${luna}` : String(luna)

        return an + "-" + lunaAsString + "-" + ziuaAsString
    }


    fromFriendlyDateToSql = (d, tipDate = false) => {
        if (d) {
            let splitData = d.split(' ')

            console.error('splitData1 ::: ', splitData)

            let year = splitData[0]
            let month = ''
            switch (splitData[1]) {
                case 'Ianuarie':
                    month = '01'
                    break;
                case 'Februarie':
                    month = '02'
                    break;
                case 'Martie':
                    month = '03'
                    break;
                case 'Aprilie':
                    month = '04'
                    break;
                case 'Mai':
                    month = '05'
                    break;
                case 'Iunie':
                    month = '06'
                    break;
                case 'Iulie':
                    month = '07'
                    break;
                case 'August':
                    month = '08'
                    break;
                case 'Septembrie':
                    month = '09'
                    break;
                case 'Octombrie':
                    month = '10'
                    break;
                case 'Noiembrie':
                    month = '11'
                    break;
                case 'Decembrie':
                    month = '12'
                    break;
                default:
                    month = '00'
                    break;
            }
            let day = (splitData[2] < 10 ? '0' : '') + splitData[0]


            if (tipDate) {
                return `${year}-${month}-${day}`
            } else {
                return `${year}-${month}-${day} 00:00:00 `
            }
        }
    }

    validate = (array) => {
        for (let i = 0, m = array.length; i < m; i++) {
            if (!array[i]) {
                return false
            } else {
                return true
            }
        }
    }

    dataFriendly2(d) {
        // 1878-02-09T00:00:00 = 1878 Februarie August
        if (d) {

            let firstCut = d.split('T')
            let splitData = firstCut[0].split('-')

            let an = Number(splitData[0])

            let luna = splitData[1].substring(0, 1) === '0' ? splitData[1].substring(1, 2) : splitData[1]
            luna = Number(luna)

            let ziua = splitData[2].substring(0, 1) === '0' ? splitData[2].substring(1, 2) : splitData[2]
            ziua = Number(ziua)

            const luni = ['', 'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

            return `${ziua} ${luni[luna]} ${an}`
        }
        else {
            return null
        }
    }


    dataFriendlyFromUnix(d) {
        // 155896889984 -> 

        if (d) {
            let date = new Date(d * 1000)

            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = (date.getDate() < 10 ? '0' : '') + date.getDate();

            let newDate = `${year}-${month}-${day}`;

            this.dataFriendly(newDate)
            // console.log(`${year}-${month}-${day}`)
        }
        else {
            return null
        }
    }


    dataFriendly(d) {
        // 1878-02-09 = 1878 Februarie August
        if (d) {

            let splitData = d.split('-')

            let an = Number(splitData[0])

            let luna = splitData[1].substring(0, 1) === '0' ? splitData[1].substring(1, 2) : splitData[1]
            luna = Number(luna)

            let ziua = splitData[2].substring(0, 1) === '0' ? splitData[2].substring(1, 2) : splitData[2]

            ziua = Number(ziua)

            const luni = ['', 'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

            // console.log('data ', `${ ziua } ${ luni[luna] } ${ an }`)


            return `${ziua} ${luni[luna]} ${an}`
        }
        else {
            return null
        }
    }

    addDaysToDateAndConvertToField = (dt, nrZile) => {

        var result = new Date(dt);
        result.setDate(result.getDate() + nrZile);

        return this.specificDateToDateField(result)
    }


    addDaysToDate = (dt, nrZile) => {

        var result = new Date(dt);
        result.setDate(result.getDate() + nrZile);

        return result //this.specificDateToDateField(result)
    }

    randomData = (nr) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < nr; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        console.log('codul este ', text)

        return text;
    }

    verifyAdmin = async (idul, sessionIdul) => {

        let fromServer = await getFromServer('users', { mode: 'check_if_admin', id_user: idul, id_sesiune: sessionIdul }, { credentials: 'include' })

        // console.log('isAdmin ', fromServer)
        if (fromServer.r === 'ok' && fromServer.isAdmin) {
            return true
        } else {
            return false
        }
    }

    // FROM VICTOR

    transformDataForSql = (data) => {

        let myDate = new Date(data)

        let year = myDate.getFullYear()
        let lunaNormala = myDate.getMonth() + 1
        let month = ((lunaNormala < 10 ? '0' : '') + lunaNormala);
        let day = (myDate.getDate() < 10 ? '0' : '') + myDate.getDate();
        return `${year}-${month}-${day}`

    }

    defaultData = () => {
        let myDate = new Date()

        let year = myDate.getFullYear()
        let lunaNormala = myDate.getMonth() + 1
        let month = ((lunaNormala < 10 ? '0' : '') + lunaNormala);
        let day = (myDate.getDate() < 10 ? '0' : '') + myDate.getDate();
        return `${year}-${month}-${day}`
    }

    updateData = (data) => {

        if (data !== null && data.length > 19) {
            let myDate = new Date(+data.substring(6, 19))

            let year = myDate.getFullYear()
            let lunaNormala = myDate.getMonth() + 1
            let month = ((lunaNormala < 10 ? '0' : '') + lunaNormala);
            let day = (myDate.getDate() < 10 ? '0' : '') + myDate.getDate();

            return `${year}-${month}-${day}`

        } else if (data !== null) {

            const an = data.slice(0, 4)
            let luna = data.slice(5, 7)
            const zi = data.slice(8, 10)
            return `${an}-${luna}-${zi}`
        }
    }

    arataData = (data) => {

        if (data != null && data.length > 19) {

            let dataCampanie = new Date(+data.substring(6, 19))

            const luni = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

            const an = dataCampanie.getFullYear()
            const zi = (dataCampanie.getDate() < 10 ? '0' : '') + dataCampanie.getDate();
            const luna = luni[dataCampanie.getMonth()];

            return `${zi} ${luna} ${an}`

        } else if (data != null) {
            const luni = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
            const an = data.slice(0, 4)
            let dataLunaIndex = (data.slice(5, 7) < 10 ? data.slice(6, 7) : data.slice(5, 7)) - 1
            const luna = luni[dataLunaIndex]
            const zi = data.slice(8, 10)

            return `${zi} ${luna} ${an}`
        }

    } 

    // arr = array-ul de obiecte de sortat
    // propertyTosortBy = proprietatea din obiect dupa care se face sortarea
    sortAZ = (arr, propertyTosortBy) => {
        arr.sort((a, b) => {

            // console.log(typeof a[propertyTosortBy])

            // daca proprietatea are valori string
            if (typeof a[propertyTosortBy] === 'string') {

                if (a[propertyTosortBy].toLowerCase() < b[propertyTosortBy].toLowerCase()) {
                    return -1
                } else if (a[propertyTosortBy].toLowerCase() > b[propertyTosortBy].toLowerCase()) {
                    return 1
                } else {
                    return 0
                }
            } else {

                if (a[propertyTosortBy] < b[propertyTosortBy]) {
                    return -1
                } else if (a[propertyTosortBy] > b[propertyTosortBy]) {
                    return 1
                } else {
                    return 0
                }

            }


        })
        return arr;
    }

}
