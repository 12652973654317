// import Encrypt from './Encrypt';

export default class LocalStorage {

    userLS = 'userLS';

    setLocalStorage = (key, value) => {
        if (key) {
            // let encrypt = new Encrypt()
            // value = encrypt.encryptText(value)
            // console.log('encrypt ', value)

            window.localStorage.setItem(key, JSON.stringify(value))
            return true;
        } else {
            return false;
        }
    }

 
    getLocalStorage = (key) => {
        // let encrypt = new Encrypt()
        // console.log('ddddd ', encrypt.decryptText(window.localStorage.getItem(key)))
        let lsResponse = JSON.parse(window.localStorage.getItem(key))
        // lsResponse = encrypt.decryptText(lsResponse)
        return lsResponse;
    }

    deleteLocalStorage = () => {
        window.localStorage.clear();
    }

}