import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { getFromServer } from '../../../Providers/WS';
import SelectCategories from '../../Pieces/SelectCategories';
import LongSaveButton from '../../Pieces/LongSaveButton';
import { Divider } from '@material-ui/core';

export default class AdminEditLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levelInfo: [],
            levelName: '',
            levelMeditations: '',
            meditations: [],
            idCategorySelected: this.props.match.params.idCategory || 0
        }
    }

    componentDidMount() {
        console.log('idCategorySelected ', this.state.idCategorySelected)
        this.initialize();
    }

    initialize = async () => {
        await this.getLevelInfo();
        await this.getMeditationsLevel()
        await this.getMeditationsForLevelCategory(this.state.idCategorySelected);
    }

    getLevelInfo = async () => {
        let levelInfo = await getFromServer('levels', { mode: 'get_level', id_level: this.props.match.params.idLevel }, { credentials: 'include' })
        console.log('level info ', levelInfo)

        if (levelInfo.r === 'ok') {
            this.setState({ levelInfo: levelInfo.continut[0], levelName: levelInfo.continut[0].nume_level }, () => {
            })
        } else {
            console.error('Problema la preluare level')
        }
    }

    getMeditationsLevel = async () => {
        let levelMeditations = await getFromServer('levels', { mode: 'get_meditations_level', id_level: this.props.match.params.idLevel }, { credentials: 'include' })

        if (levelMeditations.r === 'ok') {
            this.setState({ levelMeditations: levelMeditations.continut }, () => {
                console.log('meditatiile nivelului ', this.state.levelMeditations);
            })
        } else {
            console.error('Problema la preluare level')
        }
    }

    getMeditationsForLevelCategory = async (idCategory) => {
        let meditations = await getFromServer('levels', { mode: 'get_meditations_by_category', id_category: idCategory }, { credentials: 'include' })
        console.error('medtation ', meditations)
        if (meditations.r === 'ok') {

            let filtered = []
            for (let i = 0, j = meditations.continut.length; i < j; i++){

                let match = false;
                for (let x = 0, y = this.state.levelMeditations.length; x < y; x++){
                    // console.log(`${this.state.levelMeditations[i].id_content} === ${meditations.continut[x].idul} && ${this.state.levelMeditations[i].id_subcategorie} === ${meditations.continut[x].id_subcategorie}`)
                    if (this.state.levelMeditations[x].id_content === meditations.continut[i].idul && this.state.levelMeditations[x].id_subcategorie === meditations.continut[i].id_subcategorie) {
                        match = true;
                        break;
                    }
                }

                if (!match) {
                    filtered.push(meditations.continut[i])
                }
            }

            this.setState({ meditations: filtered }, () => {
                console.log('meditatii ', this.state.meditations)
            })
        } else {
            console.error('Problema la preluare meditatii')
        }
    }

    defaultValueForCategory = (idul) => {
        this.setState({ idCategorySelected: idul }, () => {
            // console.log('idCategorySelected ', this.state.idCategorySelected)
        });
    }

    onChangeCategory = (val) => {
        this.setState({ idCategorySelected: val }, () => {
            this.getMeditationsForLevelCategory(this.state.idCategorySelected)
        });
    }

    setInput = (type, ev) => {
        switch (type) {
            case 'name':
                this.setState({ levelName: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }

    updateLevel = async () => {

        let updateLevel = await getFromServer('levels', {
            mode: 'update_level',
            id_level: this.props.match.params.idLevel,
            level_name: this.state.levelName,
            id_category: this.state.idCategorySelected,
        }, { credentials: 'include' })

        if (updateLevel.r === 'ok') {
            this.props.history.push('/adminlevels');
        } else {
            alert('A aparut o problema la actualizare informatii')
        }

    }

    onSelectMeditation = async (method, idMeditation, idSubcategorie) => {
        
        if (method === 'add') {

            let updateLevel = await getFromServer('levels', {
                mode: 'add_meditation_to_level',
                id_content: idMeditation,
                id_level: this.props.match.params.idLevel,
                id_subcategory: idSubcategorie
            }, { credentials: 'include' })

            if (updateLevel.r === 'ok') {
                await this.getMeditationsLevel()
                await this.getMeditationsForLevelCategory(this.state.idCategorySelected)
            } else {
                alert('A aparut o problema la actualizare informatii')
            }

        } else {

            let updateLevel = await getFromServer('levels', {
                mode: 'delete_meditation_to_level',
                id_content: idMeditation,
                id_level: this.props.match.params.idLevel,
                id_subcategory: idSubcategorie
            }, { credentials: 'include' })

            if (updateLevel.r === 'ok') {
                await this.getMeditationsLevel()
                await this.getMeditationsForLevelCategory(this.state.idCategorySelected)
            } else {
                alert('A aparut o problema la actualizare informatii')
            }

        }
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <p className="opacity-title blue-lite">Editare Level</p>


                    <div>
                        <p className="namaste-label">Nume Level</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.levelName || ''}
                            onChange={ev => {
                                this.setInput('name', ev)
                            }}
                        >
                        </input>

                        <LongSaveButton text='Actualizare' action={this.updateLevel} />

                        {
                            this.state.idCategorySelected &&
                            <SelectCategories
                            label='Schimba categoria nivelului'
                            onChangeCategory={this.onChangeCategory}
                            setDefaultValue={this.defaultValueForCategory}
                            currentValue={this.state.idCategorySelected}
                            />
                        }
                        
                    </div>


                    <Divider light />
                            
                    <p className="subtitle blue-lite">Meditatii nivel</p>

                    {
                        this.state.levelMeditations.length > 0 &&
                        this.state.levelMeditations.map((item, idx) => {
                            return <p key={idx} onClick={() => this.onSelectMeditation('remove', item.id_content, item.id_subcategorie)} className="chip" style={{ backgroundColor: item.color }}>{item.nume}</p>
                        })
                    }

                    <p className="subtitle blue-lite">Meditatii de adaugat la Nivel</p>

                    {
                        this.state.meditations.length > 0 &&
                        this.state.meditations.map( (item, idx) => {
                            return <p key={idx} onClick={()=>this.onSelectMeditation('add', item.idul, item.id_subcategorie)} className="chip" style={{ backgroundColor: item.color }}>{item.nume}</p>
                        })
                    }
                </div>
            </div>
        );
    }


}  // end class