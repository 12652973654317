import React from 'react';
import LongSaveButton from '../../Pieces/LongSaveButton';
import Validator from '../../../Providers/Validator'

export default class AdminTagsEditor extends React.Component {

    validate = new Validator()

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            newTag: ''
        }
    }

    componentDidMount() {
        console.log('props editor tag ', this.props)

    }

    validateInput = () => {

        let responseValidate = this.validate.lenghtValidate(2, this.state.newTag)

        if (responseValidate) {

            this.props.updateNewValue(this.state.newTag)

            if (this.state.error) {
                this.setState({ error: false })
            } else {
                this.setState({ newTag: '' })
            }

        } else {
            this.setState({ error: true })
        }
    }

    render() {
        return (
            <div>
                <input
                    className="namaste-round-input"
                    placeholder="Introduceti tag nou"
                    value={this.state.newTag}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            this.validateInput()
                            
                        }   
                    }}
                    onChange={ev => {
                        this.setState({newTag: ev.target.value})
                    }}
                >

                </input>

                {
                    this.state.error &&
                    <p className="error-message text-center">Numar de caractere insuficient (minim 2)</p>
                }
                <LongSaveButton text='Adauga Tag' action={this.validateInput} />

                <div className="tags-container">

                    {
                        this.props.list.length > 0 &&
                        this.props.list.map( (item, index) => {
                            return (
                                <div key={index} className="inline-block tag-item" onClick={()=>this.props.deleteItem(index)}>{item}</div>
                            )
                        })
                    }

                </div>
            </div>
        );
    }


}  // end class