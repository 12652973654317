import React from 'react';
import { Grid, MenuItem, Select } from '@material-ui/core';

import { getFromServer } from '../../../Providers/WS';


export default class AdminMediationCategorySubcategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCategory: this.props.categories[0],
            idCurrentCategory: this.props.categories[0].id_categorie,
            contentSubcategories: this.props.contentSubcategories,
            categoriesList: [],
            subcategories: [],
            ///
            proposedSubcategoriesToShow: [],
            currentSubcategoriesToShow: [],
        }
    }

    componentDidMount() {
        console.log('props categ subcateg', this.props)
        this.initialize();
    }

    initialize = async () => {
        await this.getCategories();
        await this.getSubcategories(this.state.idCurrentCategory);
    }

    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })

        if (categories.r === 'ok') {
            this.setState({ categoriesList: categories.continut }, () => {
                console.log('categoriesList ', this.state.categoriesList)
            })
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    getSubcategories = async (idCategory) => {
        let subcategories = await getFromServer('category', { mode: 'get_subcategories_of_category', id_category: idCategory }, { credentials: 'include' })

        if (subcategories.r === 'ok') {
            this.setState({ subcategories: subcategories.continut }, () => {
                console.log('lista subcategorii ', this.state.subcategories)
                this.obtainData()
            })
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    obtainData = () => {
        let currentSubcategoriesToShow = []
        let othersSubcategoriesToShow = []

        for (let i = 0, j = this.state.subcategories.length; i < j; i++) {

            let found = false
            for (let y = 0, z = this.props.contentSubcategories.length; y < z; y++) {

                if (this.props.contentSubcategories[y] === this.state.subcategories[i].idul) {
                    currentSubcategoriesToShow.push(this.state.subcategories[i])
                    found = true;
                    break;
                }
            }

            if (!found) {
                othersSubcategoriesToShow.push(this.state.subcategories[i])
            }
        }

        this.setState({ proposedSubcategoriesToShow: othersSubcategoriesToShow, currentSubcategoriesToShow: currentSubcategoriesToShow }, () => {
            // console.log('proposedSubcategoriesToShow', this.state.proposedSubcategoriesToShow)
            // console.log('currentSubcategoriesToShow ', this.state.currentSubcategoriesToShow)
        })

    }

    updateCategory = async (id) => {
        // La update Categorie in meditatie se va sterge din tabela content_categorii toate categoriile si subcategoriile ce tin de acea meditatie
        // Apoi se va adauga in acea tabela id_content si id_category fara id_subcategorie.
        this.setState({ idCurrentCategory: id }, () => {
            this.getSubcategories(this.state.idCurrentCategory)
        })

        let changecategory = await getFromServer('content', { mode: 'change_category', id_category: id, id_content: this.props.idMeditation }, { credentials: 'include' })

        if (changecategory.r === 'ok') {
            this.props.getMeditation()
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    changeSubcategory = async (changeType, idSubcategory) => {
        console.log(' xxx::  ', changeType, idSubcategory, ' id_content ', this.props.idMeditation, ' category ', this.state.idCurrentCategory)

        if (changeType === 'remove') {

            let changecategory = await getFromServer('content', { mode: 'delete_subcategory', id_subcategory: idSubcategory, id_category: this.state.idCurrentCategory, id_content: this.props.idMeditation }, { credentials: 'include' })
            if (changecategory.r === 'ok') {
                // console.log('changecategory ', changecategory)
                await this.props.getMeditation() // TO DO - pentru a primi content this.props.contentSubcategories, insa mai bine facem functie aici de preuare a subcategoriilor meditatiei
                this.obtainData();
            } else {
                console.error('Eroare la update subcategory')
            }

        } else {

            if (this.state.currentSubcategoriesToShow.length === 0) {

                // se face update in baza
                let changecategory = await getFromServer('content', { mode: 'update_subcategory', id_subcategory: idSubcategory, id_category: this.state.idCurrentCategory, id_content: this.props.idMeditation }, { credentials: 'include' })

                if (changecategory.r === 'ok') {
                    // console.log('changecategory ', changecategory)
                    await this.props.getMeditation() // TO DO - pentru a primi content this.props.contentSubcategories, insa mai bine facem functie aici de preuare a subcategoriilor meditatiei
                    this.obtainData();
                } else {
                    console.error('Eroare la update subcategory')
                }

            } else {
                // se insereaza o noua subcategorie

                let changecategory = await getFromServer('content', { mode: 'insert_subcategory', id_subcategory: idSubcategory, id_category: this.state.idCurrentCategory, id_content: this.props.idMeditation }, { credentials: 'include' })

                if (changecategory.r === 'ok') {
                    // console.log('changecategory ', changecategory)
                    await this.props.getMeditation() // TO DO - pentru a primi content this.props.contentSubcategories, insa mai bine facem functie aici de preuare a subcategoriilor meditatiei
                    this.obtainData();
                } else {
                    console.error('Eroare la update subcategory')
                }
            }

        }

    }

    render() {
        return (
            <div>
                <p className="subtitle blue-lite text-center">Categorie - Subcategorii</p>
                <Grid container style={{ margin: 'auto' }}>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p>Categorii</p>
                        {
                            this.state.categoriesList.length > 0 &&
                            <div>
                                <p className="namaste-label">Schimba Categorie</p>
                                <Select
                                    value={this.state.idCurrentCategory}
                                    onChange={(ev) => { this.updateCategory(ev.target.value) }}
                                    className="namaste-select-box"
                                >
                                    {
                                        this.state.categoriesList.map((item, idx) => {
                                            return <MenuItem value={item.idul} key={idx}>{item.nume_categorie}</MenuItem>
                                        })
                                    }
                                </Select>
                            </div>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p>Subcategorii de Atribuit</p>

                        <div style={{ marginTop: '10px' }}>
                            {
                                this.state.proposedSubcategoriesToShow.length > 0 &&
                                this.state.proposedSubcategoriesToShow.map((item, idx) => <span key={idx} onClick={() => this.changeSubcategory('add', item.idul)} className="chip backgroundRed">{item.nume_subcategorie}</span>)
                            }
                        </div>

                        <p>Subcategorii Actuale</p>
                        <div style={{ marginTop: '10px' }}>
                            {
                                this.state.currentSubcategoriesToShow.length > 0 &&
                                this.state.currentSubcategoriesToShow.map((item, idx) => <span key={idx} onClick={() => this.changeSubcategory('remove', item.idul)} className="chip backgroundGreen">{item.nume_subcategorie}</span>)
                            }
                        </div>
                    </Grid>

                </Grid>
            </div>
        );
    }

}  // end class