import React from 'react';
// import { getLocalUserData } from '../../Providers/ls';
// import {fromServer} from '../../Providers/ws'
import SiteHeader from '../SiteHeader/SiteHeader';
import AdminHeader from './AdminHeader';


export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        console.log('this.props admin ', this.props)
        this.scrollTop()
    }

    scrollTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return ( 
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history}/>
             
                    {/* <div className="admin-menu">

                        <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminclients') }} >
                            <p>Clienti</p>
                        </div>
                        <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/citatezilnice') }} >
                            <p>Citate Zilnice</p>
                        </div>

                        <div className="admin-tab inline-block" onClick={() => {
                            console.log('this.props:: ', this.props)
                            this.props.history.push('/admincontent')
                        }} >
                            <p>Meditatii</p>
                        </div>

                        <div className="admin-tab inline-block" onClick={() => {
                            console.log('this.props:: ', this.props)
                            this.props.history.push('/adminauthors')
                        }} >
                            <p>Autori</p>
                        </div>

                        <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/admincategorii') }} >
                            <p>Categorii</p>
                        </div>

                        <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/adminprograme') }} >
                            <p>Programe</p>
                        </div>

                        <div className="admin-tab inline-block" onClick={() => { this.props.history.push('/ratings') }} >
                            <p>Note / Ratings</p>
                        </div>
                    </div> */}
                
            </div>
        );
    }
}

;