import React from 'react';
import { Switch } from '@material-ui/core'

/* PROPS */
// item - obiect cu informatiile necesare
// cssStyle - CSS Suplimentar

export default class SwitchButton extends React.Component {

    componentDidMount() {
    }

    render() {
        return (

            <Switch
                className={this.props.item.sters !== 1 ? "checkSwitch" : "uncheckSwitch"}
                checked={this.props.item.sters === 1 ? true : false}
                onChange={(ev) => this.props.action(this.props.item.sters, this.props.item.idul, ev, this.props.item)}
            />

        )
    }
}