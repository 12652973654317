import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import AdminHeader from './AdminHeader';
import { getFromServer } from '../../Providers/WS';
import Utils from '../../Providers/Utils';

import {Switch, FormControlLabel } from '@material-ui/core';

export default class AdminClientDetails extends React.Component {

    utils = new Utils()

    constructor(props) {
        super(props);
        this.state = {

            client: {
                tip_content: '',
            },

            checkClient: false
        }
    }

    componentDidMount() {
        this.getUserDetails()
    }

    getUserDetails = async () => {

        let client = await getFromServer('users', {
            mode: 'get_user',
            id_user: this.props.match.params.idUser
        }, { credentials: 'include' })

        console.log('user details ', client)

        if (client.r === 'ok') {
            this.setState({
                client: client.continut[0],
                checkClient: client.continut[0].blocat !== '1' ? true : false
            })
        }
    }


    blockUser = async () => {

        if (this.state.checkClient === true) {

            let blocheaza = await getFromServer('users', {
                mode: "block_user",
                id_user: this.state.client.idul
            }, { credentials: 'include' })

            if (blocheaza.r === 'ok') {
                this.setState({ checkClient: false })
            }
        } else {

            let deblocheaza = await getFromServer('users', {
                mode: "unblock_user",
                id_user: this.state.client.idul
            }, { credentials: 'include' })

            if (deblocheaza.r === 'ok') {
                this.setState({ checkClient: true })
            }
        }

    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">

                    <div>
                        <p className="opacity-title blue-lite">Detalii Utilizator</p>
                    </div>

                    <FormControlLabel style={{ margin: 'auto' }}
                        control={
                            <Switch 
                                // className='meditatii-my-switch'
                                checked={this.state.checkClient}
                                onChange={this.blockUser}
                                value="checkClient"
                                color='default'
                                checkedIcon={<i className='material-icons' style={{ color: '#06cc06' }}>fiber_manual_record</i>}
                            />
                        }
                        label="Activ"
                    />

                    <p>Nume Utilizator: {this.state.client.nume_utilizator}</p>

                    {
                        this.state.client.nume &&
                        <h1 className="title2" >{this.state.client.nume}</h1>
                    }
                    <p>E-mail: {this.state.client.email}</p>
                    <p>Data Creare Cont: <span>{this.utils.arataData(this.state.client.data_creare_cont)}</span></p>
                    <p>Data Expirare Cont: <span>{this.utils.arataData(this.state.client.data_expirare)}</span></p>
                    <p>Este Admin: <span>{String(this.state.client.admin) === '1' ? "Da" : "Nu"}</span></p>
                    <p>Tip Abonament: <span>{this.state.client.tip_content !== null ? this.state.client.tip_content.toUpperCase() : ''}</span></p>

                   

                </div>
            </div>
        )
    }
}