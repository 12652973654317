import React from 'react';
import Checkpoints from '../Checkpoints/Checkpoints';

export default class UserPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progressiveBarWidth: 0,
            // mode: 'stop',
            remain_time: this.props.remain_time,
            //

        }
    }

    current_time;
    seconds;
    // remain_time = this.props.audioDuration;

    past_time;
    timer_handler;

    playIcon = 'play_arrow'
    stopIcon = 'stop'
    pauseIcon = 'pause'
    prevIcon = 'first_page'
    nextIcon = 'last_page'

    componentDidMount() {
        // console.log('nou')ß
    }

    checkpoint = new Checkpoints();

    playContent = () => {
        this.props.changeMode('play')
        this.repeat();
    }

    stopContent = () => {
        clearInterval(this.timer_handler);
        if (this.props.audio) {
            this.props.audio.currentTime = 0;
            this.props.changeMode('stop')
        }

        this.setState({ progressive_bar_width: 0, remain_time: this.props.remain_time }, () => {
            // console.log('progressive_bar este: ', this.state.progressive_bar_width)
        });

        // this.props.remain_time = this.props.audioDuration;

    }

    pauseContent = () => {
        clearInterval(this.timer_handler);
        this.props.changeMode('pause')
    }

    repeat = () => {

        this.current_time = this.props.audio.duration - this.props.audio.currentTime;
        console.log('this.audio.currentTime', this.props.audio.currentTime);

        this.timer_handler = setInterval(() => {
            this.timer()
        }, 1000);
        // console.error('this.timer_handler ', this.timer_handler)

    }

    timer = () => {

        if (this.props.audio.currentTime != 0) {

            // console.log('this.audio.duration ', this.props.audio.duration);
            // console.log('this.audio.currentTime', this.props.audio.currentTime);
            this.current_time = this.props.audio.duration - this.props.audio.currentTime;
            let seconds = this.current_time;

            // console.log(' current time ', this.current_time)
            var days = Math.floor(seconds / 24 / 60 / 60);
            var hoursLeft = Math.floor((seconds) - (days * 86400));
            var hours = Math.floor(hoursLeft / 3600);
            var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
            var minutes = Math.floor(minutesLeft / 60);
            var remainingSeconds = Math.floor(seconds % 60);

            // this.props.remain_time = this.hour(hours) + this.minute(hours, minutes) + this.second(remainingSeconds);
            this.setState({ remain_time: this.hour(hours) + this.minute(hours, minutes) + this.second(remainingSeconds) })

            // daca meditatie are setat loop, se va relua
            // if (this.content_details.content_loop == 1 && this.audio.duration == this.audio.currentTime) {
            //     this.audio.play();
            // } else {
            if (this.current_time <= 0) {
                this.stopContent();
                console.error('player stoped')
            } else {
                seconds--;
            }
            // }

            let progressive_bar_width = (this.props.audio.currentTime / this.props.audio.duration) * 100

            this.setState({ progressive_bar_width: progressive_bar_width + '%' }, () => {
                console.log('progressive_bar este: ', this.state.progressive_bar_width)

                // CHECKPOINT LEVEL JOBS
                this.checkpoint.verifyCheckpoint(progressive_bar_width, this.props.checkpointObj, this.props.prevCheckpoint, this.props.setPrevCheckpoint)
            });

        }

    }

    hour = (hour) => {
        if (hour == '00') {
            return '';
        } else {
            return (hour < 10 ? "0" + hour + ':' : hour + ':');
        }
    }

    minute = (hour, minute) => {
        // daca minutul este egal cu 0 dar ora nu
        if (minute == '00' && hour !== '00') {
            return '00:';
            // daca minutul este cu 0 si la fel si ora
        } else if (minute == '00') {
            return '';
        } else {

            return (minute < 10 ? "0" + minute + ':' : minute + ':');
        }
    }

    second = (second) => {
        if (second == '00') {
            return '00';
        } else {
            return (second < 10 ? "0" + second : second);
        }
    }

    seek = (event) => {
        console.error('event: ', event);
        console.error('click la ', event.clientX);

        let bar = document.querySelector('.progressive-bar');
        console.error('bar width', bar.offsetWidth)

        let pb = Math.floor((event.clientX / bar.offsetWidth) * 100);
        this.progressive_bar_width = pb + '%';
        console.log('audio duration ', this.props.audio.duration)

        this.props.audio.currentTime = (pb / 100) * this.props.audio.duration;
        console.log('this.audio.currentTime ', this.props.audio.currentTime)

        clearInterval(this.timer_handler);
        this.repeat();
        console.log('this.progressive_bar_width ', this.state.progressive_bar_width)
        console.log('this.audio.currentTime ', this.current_time)
    }


    render() {
        return (
            <div className="player">

                {/* buton inchidere player */}
                <div onClick={this.props.closePlayer} className="close-player-container">
                    <i className='material-icons close-player' >highlight_off</i>
                </div>
                <div className="progressive-bar" onClick={(ev) => this.seek(ev)}>
                    <div className="progressive-bar-active" style={{ width: this.state.progressive_bar_width }}></div>
                </div >

                <div className='player-controls'>

                    <div className="inline-block h-align-center">
                        <div className="inline-block">
                            <p className="remaining_time">{this.props.audioName}</p>
                        </div>
                        <span>   </span>

                        <div className="inline-block">
                            <p className="remaining_time">{this.props.mode === 'stop' ? this.props.remain_time : this.state.remain_time}</p>
                        </div>
                    </div>

                    <div>

                        {
                            this.props.mode === 'play' &&
                            <div className='player-controls-buttons'>
                                <div className='player-back inline-block' onClick={() => this.props.backForwardPlaylist('b')}>
                                    <i className='material-icons back-forward-button'>{this.prevIcon}</i>
                                </div>

                                <div className='player-pause inline-block' onClick={this.pauseContent} >
                                    <i className='material-icons pause-button'>{this.pauseIcon}</i>
                                </div>

                                <div className='player-stop inline-block' onClick={this.stopContent}>
                                    <i className='material-icons stop-button'>{this.stopIcon}</i>
                                </div>

                                <div className='player-forward inline-block' onClick={() => this.props.backForwardPlaylist('f')}>
                                    <i className='material-icons back-forward-button'>{this.nextIcon}</i>
                                </div>
                            </div>
                        }

                        {
                            this.props.mode === 'pause' &&
                            <div className='player-controls-buttons'>
                                <div className='player-back inline-block' onClick={() => this.props.backForwardPlaylist('b')}>
                                    <i className='material-icons back-forward-button'>{this.prevIcon}</i>
                                </div>
                                <div className="player-play inline-block" onClick={this.playContent}>
                                    <i className='material-icons play-button'>{this.playIcon}</i>
                                </div>
                                <div className='player-stop inline-block' onClick={this.stopContent}>
                                    <i className='material-icons stop-button'>{this.stopIcon}</i>
                                </div>
                                <div className='player-forward inline-block' onClick={() => this.props.backForwardPlaylist('f')}>
                                    <i className='material-icons back-forward-button'>{this.nextIcon}</i>
                                </div>
                            </div>
                        }

                        {
                            this.props.mode === 'stop' &&
                            <div className='player-controls-buttons'>
                                <div className='player-back inline-block' onClick={() => this.props.backForwardPlaylist('b')}>
                                    <i className='material-icons back-forward-button'>{this.prevIcon}</i>
                                </div>
                                <div className="player-play inline-block" onClick={this.playContent}>
                                    <i className='material-icons play-button'>{this.playIcon}</i>
                                </div>

                                <div className='player-stop-disabled inline-block'>
                                    <i className='material-icons stop-button'>{this.stopIcon}</i>
                                </div>
                                <div className='player-forward inline-block' onClick={() => this.props.backForwardPlaylist('f')}>
                                    <i className='material-icons back-forward-button'>{this.nextIcon}</i>
                                </div>
                            </div>
                        }

                       
                    </div>

                </div >
            </div >
        );
    }


}  // end class