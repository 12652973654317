
export default class Validator {

    lenghtValidate = (nrCharacters, toValidate) => {

        if (Number.isInteger(nrCharacters)) {

            let isLenghtOk = (toValidate.length) > nrCharacters ? true : false
            return isLenghtOk;

        } else {

            console.log('Parameter is not a number')
            return false;

        }
    }

    existValidate = (toValidate) => {

        return toValidate ? true : false;

    }

    emailValidate = (email) => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true
        }
        return false
    }

}
