import React from 'react';
import { getFromServer } from '../../Providers/WS';
import Validate from '../../Providers/Validator';
import SiteHeader from '../SiteHeader/SiteHeader';
import LongSaveButton from '../Pieces/LongSaveButton';

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword1: '',
            newPassword2: '',
            validateErrors: false,
            tokenErrors: false,
            errorsMessage: '',
            confirmMessage: ''
        }
    }

    componentDidMount() {
        this.verifyToken()
    }

    validate = new Validate();

    verifyToken = async () => {

        if (!this.validate.lenghtValidate(10, this.props.match.params.resetToken)) {

            this.setState({ tokenErrors: true }, () => {
            });
            this.errors[0] = 'There was a problem with the reset password. Please contact us.';

        } else {

            let tokenResponse = await getFromServer('reset_password', {

                mode: 'check_token',
                token: this.props.match.params.resetToken

            }, { credentials: 'include' })

            console.log('response server ', tokenResponse)

            if (tokenResponse.r === 'err') {
                console.log('ee', tokenResponse.mesaj)
                this.setState({ tokenErrors: true, errorsMessage: tokenResponse.mesaj })

            } else {
                console.log('token ok')
            }
        }

    }

    verifyPassword = () => {

        if (this.state.newPassword1 !== this.state.newPassword2) {

            this.setState({ validateErrors: true, errorsMessage: 'There is a difference between the new passwords. Please check again.' });

        }
        else if (!this.validate.lenghtValidate(4, this.state.newPassword1)) {

            this.setState({ validateErrors: true, errorsMessage: 'The password must contain at least 5 characters.' });

        }
        else {
            this.setState({ validateErrors: false, errorsMessage: '' });

            console.log('aici update ')
            this.changePassword()

        }
    }


    setInput = (type, ev) => {
        switch (type) {

            case 'newPassword1':
                this.setState({ newPassword1: ev });
                break;

            case 'newPassword2':
                this.setState({ newPassword2: ev });
                break;

            default:
                console.log('switch a intrat in default')
        }
    }

    changePassword = async () => {

        let resetPassResponse = await getFromServer('reset_password', {
            mode: 'reset_password',
            token: this.props.match.params.resetToken
        },
            {
                credentials: 'include',
                method: 'POST'
            }, false, "POST", this.state.newPassword1, 'postData')

        console.log('password reset ', resetPassResponse)

        if (resetPassResponse.r === 'ok') {

            this.setState({ confirmMessage: resetPassResponse.mesaj, newPassword2: '', newPassword1: '' });

            setTimeout(() => {
                this.props.history.push('/')
            }, 5000)

        } else {
            console.error('Error: reset password')
        }
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />

                {
                    !this.state.errors &&
                    <div className="section backgroundWhite width80">
                        <p className="title2 text-center">Password Reset </p>
                        <p className="namaste-label-site">Type new password</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.newPassword1}
                            type="password"
                            onChange={(ev) => {
                                this.setInput('newPassword1', ev.target.value)
                            }}
                        ></input>

                        <p className="namaste-label-site">Type again new password</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.newPassword2}
                            type="password"
                            onChange={(ev) => {
                                this.setInput('newPassword2', ev.target.value)
                            }}
                        ></input>

                        {
                            this.state.validateErrors &&
                            <div className="section backgroundWhite width80">
                                <p className="error-message text-center">{this.state.errorsMessage}</p>
                            </div>
                        }

                        {
                            this.state.confirmMessage.length > 0 &&
                            <p className="success-message text-center">{this.state.confirmMessage}</p>
                        }

                        <LongSaveButton action={() => this.verifyPassword()} text="Change Password" />
                    </div>

                }

                {
                    this.state.tokenErrors &&
                    <div className="section backgroundWhite width80">
                        <p className="error-message text-center">{this.state.errorsMessage}</p>
                    </div>
                }
            </div>
        );
    }


}  // end class