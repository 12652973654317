import React from 'react';
import { getFromServer } from '../../Providers/WS';
import Validate from '../../Providers/Validator';
import LocalStorage from '../../Providers/LS';
import ForgotPassword from './ForgotPassword';
import FacebookLogin from './FacebookLogin';

export default class LoginRegister extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLogInMode: true,
            isErrors: false,
            isForgotPassword: false
        }
    }

    userInputs = {
        username: '',
        password: '',
        email: ''
    }

    errors = [];
    successMessage = "";

    localStorage = new LocalStorage();
    validate = new Validate()

    componentDidMount() {
        console.log('props login ', this.props);

        // this.facebookMobileLogin()
    }

    changeAuthentificationMode = () => {
        this.setState({ isLogInMode: this.state.isLogInMode ? false : true, isForgotPassword: false })
    }

    login = async () => {

        let logInInfo = {
            username: this.userInputs.username,
            password: this.userInputs.password
        }

        console.log('loginstart :', logInInfo);
        let loginResponse = await getFromServer('login', { mode: 'login' },
            { credentials: 'include', method: 'POST' },
            false,
            'POST',
            JSON.stringify(logInInfo),
            'postData'
        )
        console.log('login response :', loginResponse);

        if (loginResponse.r === 'ok') {

            let userData = {
                isLogged: true,
                fbLogin: false,
                nume: loginResponse.continut.nume,
                idul: loginResponse.continut.idul,
                email: loginResponse.continut.email,
                username: loginResponse.continut.nume_utilizator,
                admin: loginResponse.continut.admin,
                blocked: loginResponse.continut.blocat,
                createdAt: loginResponse.continut.data_creare_cont,
                updatedAt: loginResponse.continut.data_modificare_cont,
                accountType: loginResponse.continut.label_tip_cont,
                sessionIdul: loginResponse.continut.id_sesiune
            }

            // salvare date in LS
            this.localStorage.setLocalStorage(this.localStorage.userLS, userData);

            // this.props.history.push("/")
            window.location.reload()

            this.props.openAuthentificationPanel()
        } else {
            this.errors[4] = loginResponse.mesaj;
            this.setState({ errors: true });
            // this.setState({ vParola: login.mesaj })
        }

    }

    register = async () => {
        // console.log(`trimis la server:  username: ${this.userInputs.username} email: ${this.userInputs.email} password: ${this.userInputs.password}`)

        let register_response = await getFromServer('insert_account', {
            username: this.userInputs.username,
            email: this.userInputs.email,
            password: this.userInputs.password,
            mode: 'insert_user'
        }, { credentials: 'include' })

        if (register_response.r === 'ok') {
            this.successMessage = register_response.mesaj;

            this.setState({ errors: false }, () => {
                // Daca inregistrarea s-a facut cu success se face redirectionare catre panoul de login
                setTimeout(() => {
                    this.setState({ isLogInMode: true })
                }, 5000);
            });
        } else {
            this.errors[4] = register_response.mesaj;
            this.setState({ errors: true });
        }

        console.log('register_response', register_response)
    }

    validateUserInputs = () => {

        this.errors = [];

        console.log('this.userInputs.username ', this.userInputs.username)
        if (!this.validate.lenghtValidate(4, this.userInputs.username)) {
            this.setState({ errors: true });
            this.errors[0] = 'The username must contain at least 4 characters.';
        }

        if (!this.validate.lenghtValidate(4, this.userInputs.password)) {
            this.setState({ errors: true });
            this.errors[1] = 'The password must contain at least 5 characters.';
        }

        // verificare doar daca este in SIGN UP mode
        if (!this.state.isLogInMode && !this.validate.emailValidate(this.userInputs.email)) {
            this.setState({ errors: true });
            this.errors[2] = 'Invalid e-mail address.';
        }

        if (this.errors.length === 0) {

            // doar daca am avut erori
            if (this.state.errors) {
                this.setState({ errors: false });
            }

            if (!this.state.isLogInMode) {
                this.register()
            } else {
                this.login()
            }

        } else {
            console.error('Exista erori la completare formular sign up')
        }

    }



    render() {
        return (
            <div className="pop-up-cover">

                <div className="authentification-container padding10">
                    <div className="close-x-container">

                        <div className="close-x" onClick={this.props.openAuthentificationPanel}>
                            <div></div>
                            <div></div>
                        </div>
                    </div>


                    {/* LOGIN MODE */}

                    {
                        this.state.isLogInMode &&
                        <div className="login-container">

                            <p className="title2 text-center">Login Into Your Account</p>

                            <input
                                className="namaste-round-input"
                                placeholder="E-mail or Username"
                                // value="valizaharia11"
                                onChange={(ev) => {
                                    this.userInputs.username = ev.target.value
                                }}
                            ></input>

                            <input
                                className="namaste-round-input"
                                type="password"
                                placeholder="Password"
                                // value=''
                                onChange={(ev) => {
                                    this.userInputs.password = ev.target.value
                                }}
                            ></input>

                            {
                                this.state.errors &&
                                <p className="error-message text-center">{this.errors[4]}</p>
                            }

                            <button className="namaste-round-button green-button" onClick={this.login}>Log in</button>

                            <p className="link text-center" onClick={() => { this.setState({ isForgotPassword: true, isLogInMode: false }) }}>Forgot Password?</p>
                            <p className="sign-up-text text-center">Don't have an account? <span onClick={this.changeAuthentificationMode}>Sign up</span></p>                            

                            <FacebookLogin buttonText="Login with Facebook" mode="login"/>

                        </div>
                    }

                    {/* SIGN UP MODE  */}

                    {
                        !this.state.isLogInMode && !this.state.isForgotPassword &&
                        <div className="login-container">

                            <p className="title2 text-center">Create Your Account</p>

                            {
                                this.state.errors &&
                                <p className="error-message">{this.errors[0]}</p>
                            }

                            <input
                                className="namaste-round-input"
                                placeholder="Username"
                                onChange={(ev) => {
                                    this.userInputs.username = ev.target.value
                                }}
                            ></input>

                            {
                                this.state.errors &&
                                <p className="error-message">{this.errors[2]}</p>
                            }

                            <input
                                className="namaste-round-input"
                                type="email"
                                placeholder="E-mail"
                                onChange={(ev) => {
                                    this.userInputs.email = ev.target.value.toLowerCase()
                                }}
                            ></input>

                            {
                                this.state.errors &&
                                <p className="error-message">{this.errors[1]}</p>
                            }

                            <input
                                className="namaste-round-input"
                                type="password"
                                placeholder="Password"
                                onChange={(ev) => {
                                    this.userInputs.password = ev.target.value
                                }}
                            ></input>

                            {
                                this.state.errors &&
                                <p className="error-message">{this.errors[4]}</p>
                            }

                            {
                                !this.state.errors &&
                                <p className="success-message">{this.successMessage}</p>
                            }

                            <button className="namaste-round-button green-button" onClick={this.validateUserInputs}>Sign Up</button>

                            <p className="sign-up-text text-center">You already have an account? <span onClick={this.changeAuthentificationMode}>Sign in</span></p>

                            <FacebookLogin buttonText="Register with Facebook" mode="register" changeAuthentificationMode={this.changeAuthentificationMode}/>
                        </div>
                    }

                
                    {
                        this.state.isForgotPassword &&
                        <ForgotPassword />
                    }

                </div>
            </div>
        )
    }

}