// const adresaServer = "http://localhost:8888/namaste/Namaste_API_DEV/";
const production = true;
const adresaServer = production ? "http://www.namastespace.com/API/files/" : "http://localhost:8888/namaste/Namaste_API_DEV/";

// const checkpointsToReach = 3; // !!! se gaseste hardcoded si in checkpoint_levels.php
export const checkpoints = [20, 40, 60, 80];

// const adresaServer = "https://www.casameditatiilor.ro/API/files/"

// poza - tip = images, audio - tip = audio
export const mediaPath = (media, tip) => {

    if (production) {
        return `http://www.namastespace.com/API/media/${tip}/${media}`
    } else { 
        return `http://localhost:8888/namaste/media/${tip}/${media}`
    }
} 

// default image
export const default_image_meditation = `http://localhost:8888/namaste/media/images/default_img.jpg`;

export const visibilityOptions = [
    {
        idul: '0',
        nume: 'Ambele platforme'
    },

    {
        idul: '1',
        nume: 'Doar in browser'
    },
    {
        idul: '2',
        nume: 'Doar pe Aplicatia Mobila'
    },

]

//( pagina, paramsToSend =null,httpParam = null, asText= false, metoda = 'GET', postData = null, postDataName )
//(lista, {mode:''}, {credentials:'include'})

export const getFromServer = async (pagina, paramsToSend = null, httpParam = null, asText = false, metoda = 'GET', postData = null, postDataName = '') => {
    let r = null; 

    let adresa = `${adresaServer}${pagina}.php`

    if (paramsToSend) {
        adresa += '?'
        for (let [key, value] of Object.entries(paramsToSend)) {
            adresa += `${key}=${value}&`
        }
        adresa = adresa.substring(0, adresa.length - 1)
    }
    
    adresa = encodeURI(adresa)
    console.log('adresa::: ', adresa)

    if (metoda === 'GET') {

        let myReq = new Request(adresa, httpParam)
        r = await fetch(myReq)

    } else if (metoda === 'POST') {
        let formData = new FormData();
        formData.append(postDataName, postData)

        let postParams = { ...httpParam }
        postParams.body = formData

        let myReq = new Request(adresa, postParams)
        r = await fetch(myReq)

    }

    if (asText === true) {
        if (r.ok) {

            let js = await r.text();
            return js;
            //return new Error(`Eroare primire date: ${js.m}`)

        }
        else {
            throw new Error(r.statusText);
        }
    } else {
        if (r.ok) {

            let js = await r.json();
            return js
            
        }
        else {
            throw new Error(r.statusText);
        }
    }
}






