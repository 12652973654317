import React from 'react';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import { Separator } from '../Pieces/Separator';
import { Grid } from '@material-ui/core';
import { Newsletter } from './NewsletterSubscription';


export default class Footer extends React.Component {

    componentDidMount() {

    }

    goToContact = () => {
        this.props.history.push('/contact')
    }
    
    goToTerms = () => {
        this.props.history.push('/terms')
    }

    goToPrivacy = () => {
        this.props.history.push('/privacy')
    }
    
    goToCookies = () => {
        this.props.history.push('/cookies')
    }

    render() {
        return (
            <div className="footer">

                <div className="social-media">
                    <a href="https://www.facebook.com/NamasteSpace/" target="blank"><img src={facebook} width="17px" alt="instagram" /></a>
                    <a href="https://www.instagram.com/namaste.space/" target="blank"><img src={instagram} width="17px" alt="facebook"/></a>
                </div>

                <Separator bgcolor='#d8d8d8' />

                <Grid container>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        <div className="links-group">
                            <p className="text-link">about us</p>
                            <p className="text-link" onClick={this.goToContact}>contact</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        <div className="links-group">
                            <p className="text-link" onClick={this.goToPrivacy}>privacy policy</p>
                            <p className="text-link" onClick={this.goToTerms}>terms and conditions</p>
                            <p className="text-link" onClick={this.goToCookies}>cookies</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        <div className="links-group">
                            <Newsletter />
                        </div>

                    </Grid>
                </Grid>

                <Separator bgcolor='#d8d8d8' />

                <p className="copyright">©2020 Namaste Space</p>

            </div>
        );
    }


}  // end class