import React from 'react';
import LocalStorage from '../../Providers/LS';

export default class Menu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isMenuIsOpen: false,
            isAuthentificationPanel: false,
        }
    }

    userData = {};
    localStorage = new LocalStorage()

    componentDidMount() {
        console.log('props menu ', this.props) 
        this.getUserLocalStorage()
    }

    getUserLocalStorage = async () => {
        this.userData = await this.localStorage.getLocalStorage(this.localStorage.userLS)
        console.log('localUser Data este ::: ', this.userData)
    }

    openCloseMenu = () => {
        this.setState({ isMenuIsOpen: this.state.isMenuIsOpen === true ? false : true }, () => {
            console.log('isMenuIsOpen ', this.state.isMenuIsOpen)

            if (this.state.isMenuIsOpen) {
                document.querySelector('.hamburger-menu-top-bar').classList.add('animation-hamburger-menu-top-bar')
                document.querySelector('.hamburger-menu-bottom-bar').classList.add('animation-hamburger-menu-bottom-bar')
                document.querySelector('.hamburger-menu-middle-bar').classList.add('animation-hamburger-menu-middle-bar')
            } else {
                document.querySelector('.hamburger-menu-top-bar').classList.remove('animation-hamburger-menu-top-bar')
                document.querySelector('.hamburger-menu-bottom-bar').classList.remove('animation-hamburger-menu-bottom-bar')
                document.querySelector('.hamburger-menu-middle-bar').classList.remove('animation-hamburger-menu-middle-bar')
            } 

        })
    }

    logout = () => {
        this.localStorage.deleteLocalStorage();
        window.location.href = '/'
    }


    goNav = (page) => {
        document.location.href = `#${page}`;
    }

    openAuthentificationPanel = () => {
        this.setState({ isAuthentificationPanel: this.state.isAuthentificationPanel ? false : true });
    }

    render() {
        return (

            <div className="menu-container inline-block" onClick={() => this.openCloseMenu()}>
                <div className="hamburger-menu-bar hamburger-menu-top-bar"></div>
                <div className="hamburger-menu-bar hamburger-menu-middle-bar"></div>
                <div className="hamburger-menu-bar hamburger-menu-bottom-bar"></div>

                {
                    this.state.isMenuIsOpen &&
                    <div className="menu-list">

                        {/* HOME */}
                        <div className="menu-item" onClick={()=>this.goNav("/")}>Home</div>

                        {/* Meditation  */}
                        <div className="menu-item" onClick={()=>this.goNav("/meditations/1")}>Meditation</div>
                        
                        {/* Admin  */}
                        {
                            this.userData && this.userData.admin &&
                            <div className="menu-item" onClick={() => this.goNav("/admin")}>Admin</div>
                        }

                        {/* Login  */}
                        {
                            !this.userData &&
                            <div className="menu-item" onClick={this.props.openAuthentificationPanel}>Login</div>
                        }


                        {/* Contul meu  */}
                        {
                            this.userData && this.userData.isLogged &&
                            <div className="menu-item" onClick={() => this.goNav("/account")}>My Account</div>
                        }

                        {/* Log out  */}
                        {
                            this.userData && this.userData.isLogged &&
                            <div className="menu-item" onClick={this.logout}>Logout</div>
                        }


                    </div>
                }

            </div>

        )
    }

}