import React from 'react';
import SiteHeader from "../SiteHeader/SiteHeader"
import Footer from '../Footer/Footer';


// const goToCookies = () => {

//     window.location.href = '/cookies'
// }

const Cookies = (props) => {

    return (
        <div>
            <SiteHeader openAuthentificationPanel={props.openAuthentificationPanel} />

            <div className="section backgroundWhite width80">
                <p className="title text-center">Cookies Policy</p>

                <p className="subtitle">Information Collected Using Cookies and other Web Technologies</p>
                <p className="plain-text">Namaste Space may use cookies, pixel tags, local storage, and other technologies to automatically collect information through the Services. We use Technologies that are essentially small data files placed on your computer, tablet, mobile phone, or other devices (referred to collectively as a “device”) that allow us to record certain pieces of information whenever you visit or interact with our sites, services, applications, messaging, and tools, and to recognize you across devices. We use automated data collection tools such as Cookies and Web Beacons to collect certain information.
</p>
<br />
                <p className="plain-text bold">Cookies</p>
                <p className="plain-text">“Cookies” are small text files that are placed on your device by a web server when you access our Services. Although most browsers automatically accept cookies, you can change your browser options to stop automatically accepting cookies or to prompt you before accepting cookies. Please note, however, that if you don’t accept cookies, you may not be able to access all portions or features of the Services. Some third-party services providers that we engage (including third-party advertisers) may also place their own cookies on your device. Note that this Privacy Policy covers only our use of cookies and does not include use of cookies by such third parties.

</p>
<br />

                <p className="plain-text bold">Pixels</p>
                <p className="plain-text">“Pixels”/“Web Beacons” (also known as Web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with Cookies, to track and measure the performance of our Services, to monitor how many visitors view our Services, and to monitor the effectiveness of our advertising.
                </p>
<br />
                <p className="plain-text bold">Widgets</p>
                <p className="plain-text">“Social Media Widgets” such as the Facebook “Like” button and LinkedIn (that might include widgets such as the share this button or other interactive miniprograms) may be on our Site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or hosted directly on our Website. Your interactions with these features are governed by the privacy policy of the company providing it.
                </p>
                <br />
                <p className="plain-text bold">Analytics</p>
                <p className="plain-text">We may also use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behavior and visitor demographics on some of our Services, and to develop website content. This analytics data is not tied to any Personal Information. For more information about Google Analytics, please visit www.google.com/policies/ privacy/partners/. You can opt out of Google’s collection and Processing of data generated by your use of the Services by going to tools.google.com/dlpage/gaoptout.
</p>
                <p className="subtitle">Cookies and Interest-Based Advertising</p>
                <p className="plain-text">As noted above, you may stop or restrict the placement of cookies on your computer or remove them from your browser by adjusting your web browser preferences. 
</p>

                <p className="plain-text">If you have questions please contact us at: contact@namastespace.com.
</p>
            </div>

            <Footer history={props.history} />
        </div>
    )
}

export default Cookies