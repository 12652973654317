import React from 'react';
import { getFromServer } from '../../Providers/WS';
import MeditationBody from './MeditationBody';
import Utile from '../../Providers/Utils';

// import locked from '../../images/locked.png';
// import unlocked from '../../images/unlocked.png';


export default class MeditationsCategoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // levels: [],
            meditation: [{ idul: 0 }],
            current: -1,
            // contentList: []
        }
    }

    utils = new Utile();

    width = {}
    percent = (100 / this.props.nrCategories) - 2;

    componentDidMount() {

        // this.getCategoryContent(this.props.idCategory)

        // this.getLevelsOfCategory(this.props.idCategory);
    }

    // getCategoryContent = async (idCategory) => {

    //     let categoryContent = await getFromServer('content', {
    //         mode: 'content_category_list',
    //         id_category: idCategory
    //     }, { credentials: 'include' })


    //     console.log('categoryContent ', categoryContent)

    //     if (categoryContent.r === 'ok') {
    //         this.setState({ contentList: categoryContent.continut })
    //     } else {
    //         console.error("error preluare meditatii pe categorie")
    //     }

    //     // if (categoryContent.)

    // }



    // getLevelsOfCategory = async (idCategory) => {

    //     // console.log('user progress ', this.props.userMeditationProgress)

    //     let levels = await getFromServer('levels', {
    //         mode: 'list_levels',
    //         id_category: idCategory
    //     }, { credentials: 'include' })

    //     if (levels.r === 'ok') {
    //         levels = levels.continut;
    //         // BEGIN rearanjare JSON

    //         let idsLevels = new Set();

    //         for (let l in levels) {
    //             idsLevels.add(levels[l].id_level)
    //         }

    //         let newLevels = [];

    //         idsLevels.forEach(idLevel => {
    //             let meditations = levels.filter(level => level.id_level === idLevel);
    //             // console.log('levels din baza ', meditations)

    //             newLevels.push({
    //                 id_level: idLevel,
    //                 nume_level: meditations[0].nume_level,
    //                 // checked: meditations[0].level_checked || 0,
    //                 ordinea: meditations[0].ordinea || 999,
    //                 meditations: meditations
    //             })
    //         })

    //         // END rearanjare JSON

    //         // BEGIN adaugare user progress

    //         let progress = [...this.props.userMeditationProgress];

    //         for (let i = 0, j = newLevels.length; i < j; i++) {

    //             for (let a = 0, b = newLevels[i].meditations.length; a < b; a++) {

    //                 for (let x = 0, y = progress.length; x < y; x++) {

    //                     if (newLevels[i].id_level === progress[x].id_level) {
    //                         // console.log( `${newLevels[i].id_level}  === ${progress[x].id_level}`,)
    //                         newLevels[i]['level_checked'] = progress[x].level_checked;
    //                     }

    //                     if (newLevels[i].meditations[a].id_content === progress[x].id_content) {
    //                         // console.log(`${newLevels[i].meditations[a].id_content}  === ${progress[x].id_content}`,)
    //                         newLevels[i].meditations[a]['content_checked'] = progress[x].content_checked;
    //                     } else {

    //                     }
    //                 }
    //             }
    //         }

    //         // END adaugare user progress

    //         // sortare JSON dupa order
    //         newLevels = this.utils.sortAZ(newLevels, 'order')

    //          // acces vizual la levels (lacate)
    //         let checkedLevels = newLevels.filter(item => item.level_checked === 1)

    //         // daca nu are niciun level facut
    //         // ii punem primul level cu acces true iar celelalte false
    //         if (checkedLevels.length === 0) {

    //             newLevels[0].access = true;
    //             checkedLevels.push(newLevels[0])

    //             newLevels = newLevels.map(item => {
    //                 item.access = item.access ? item.access : false
    //                 return item
    //             })

    //         // daca are cel putin un level facut
    //         // luam cel mai mare level facut cu checked (1)   
    //         // setam cu access true ce are proprietatea "ordinea" mai mica, egala sau mai mare cu 1 decat nivelul cu ordinea cea mai mare facut (2)  
    //         } else {

    //             // (1)
    //             let lastChecked = checkedLevels[checkedLevels.length - 1]
    //             console.log('last checked ', lastChecked)

    //             // (2)
    //             for (let i = 0; i < newLevels.length; i++){

    //                 if (newLevels[i].ordinea - 1 <= lastChecked.ordinea) {
    //                     newLevels[i].access = true;
    //                 } else {
    //                     newLevels[i].access = false;
    //                 }

    //             } 

    //         }

    //         // setare in state
    //         this.setState({ levels: newLevels }, () => {
    //             console.log(`levels id_categ ${this.props.idCategory}`, this.state.levels)
    //         })

    //     } else {
    //         alert('A aparut o problema la actualizare informatii')
    //     }
    // }


    render() {

        return (
            <div>
                <div
                    style={{
                        // width: this.percent + '%',
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginBottom: '3px',
                        width: '100%'
                    }}
                    className="category-container"
                >

                    {
                        // this.state.contentList.length > 0 &&
                        // this.state.contentList.map((meditation, idx) => {
                        this.props.categoryMeditations.length > 0 &&
                        this.props.categoryMeditations.map((meditation, idx) => {
                            return <div key={idx} className="meditation-container">

                                <MeditationBody
                                    currentPlayedIdMeditation={this.props.currentPlayedIdMeditation}
                                    idCategory={this.props.idCategory}
                                    idMeditation={meditation.id_meditatie}
                                    setAudio={this.props.setAudio}
                                    // TO DO ii pot trimite prin props userData ca sa nu le mai iau in partea cealalta MeditationBody
                                    //
                                    idx={idx}
                                    meditation={meditation}
                                    openAuthentificationPanel={this.props.openAuthentificationPanel}
                                />

                            </div>

                        })

                    }

                </div>
            </div>



            // <div style={{ width: this.percent + '%', display: 'inline-block', marginLeft: '1%', verticalAlign: 'top', marginRight: '1%', marginBottom: '3px' }} className="category-container">
            //     {
            //         this.state.levels.length > 0 &&

            //         //FIRST MAP
            //         this.state.levels.map(item => {

            //             return <div key={item.id_level} >
            //                 <div className="level-container">
            //                     <div className="level-status-image" style={item.access ? { backgroundImage: `url(' ${unlocked}')` } : { backgroundImage: `url('${locked}')` }}
            //                     >
            //                         <p className="level-name">{item.nume_level} </p>
            //                     </div>

            //                 </div>
            //                 {
            //                     // SECOND MAP
            //                     item.meditations.map((meditation, idx) => {
            //                         return <div key={idx} className="meditation-container">

            //                             {
            //                                 meditation.id_content &&
            //                                 <MeditationBody
            //                                     id_level={item.id_level}
            //                                     idMeditation={meditation.id_content}
            //                                     setAudio={this.props.setAudio}
            //                                     // TO DO ii pot trimite prin props userData ca sa nu le mai iau in partea cealalta MeditationBody
            //                                     //
            //                                     idx={idx}
            //                                     meditation={meditation}
            //                                 />
            //                             }
            //                         </div>

            //                     })
            //                 }
            //             </div>

            //         })
            //     }
            // </div>
        );
    }


}  // end class