import React from 'react';

/* PROPS */
// action - functie pentru click
// cssStyle - CSS Suplimentar
// primaryFlag
// secondaryFlag
// primaryFlag

export default class AddRoundButton extends React.Component {

    componentDidMount() {
    }

    render() {
        return (

            <i onClick={this.props.action} className='material-icons' style={{ color: 'rgb(64, 183, 104)', cursor: 'pointer', ...this.props.cssStyle }} >add_circle</i>            
        )
    }
}