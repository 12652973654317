import React from 'react';
import { getFromServer } from '../../Providers/WS';
import { MenuItem, Select } from '@material-ui/core';

export default class SelectCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesList: [],
       
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })

        if (categories.r === 'ok') {

            this.setState({ categoriesList: categories.continut }, () => {
                // console.log('categoriesList ', this.state.categoriesList)
            })

            // trimitere default value (id) pentru parent
            if (categories.continut.length > 0 && !this.props.currentValue) {
                this.props.setDefaultValue(categories.continut[0].idul)
            } else if (categories.continut.length > 0 && this.props.currentValue) {
                this.props.setDefaultValue(this.props.currentValue);
            } else {
                this.props.setDefaultValue(this.props.currentValue);
            }
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    render() {
        return (
            <div>

                {
                    this.state.categoriesList.length > 0 &&
                    <div>
                        <p className="namaste-label">{this.props.label}</p>
                        <Select
                            value={this.props.currentValue}
                            onChange={(ev) => { this.props.onChangeCategory(ev.target.value) }}
                            className="namaste-select-box"
                        >
                            {
                                this.state.categoriesList.map((item, idx) => {
                                    return <MenuItem value={item.idul} key={idx}>{item.nume_categorie}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                }
            </div>
        );
    }


}  // end class