import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import LongSaveButton from '../../Pieces/LongSaveButton'
import { getFromServer } from '../../../Providers/WS';
import LocalStorage from '../../../Providers/LS';

import { mediaPath } from '../../../Providers/WS';
import AddRoundButton from '../../Pieces/AddRoundButton';

import { Select, MenuItem, Grid, Switch, Divider } from '@material-ui/core'
import Player from '../../Pieces/Player';
import AdminTagsEditor from './AdminTagsEditor';
import AdminMediationCategorySubcategory from './AdminMeditationCategorySubcategory';

// import SwitchButton from '../../Pieces/SwitchButton';

const audio = document.createElement('audio')
audio.setAttribute("id", "audioFile")

export default class AdminEditMeditation extends React.Component {

    ls = new LocalStorage()
    localData = {
        userLS: {}
    }

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            imagePath: '',
            author: 0,
            isContentFreeUsers: true,
            isContentFreeEveryone: false,
            isContentLoop: false,
            isPublished: false,

            tags: [],
            authors: [],
            categories: [],
            subcategories: [],
            editTagsMode: ""
        }
    }

    componentDidMount() {

        let userLS = this.ls.getLocalStorage(this.ls.userLS)
        if (userLS) {
            this.localData.userLS = userLS
            this.getMeditation();
        } else {
            // daca nu are userLS il trimit pe home
            // this.props.history.push('/');
            console.log('Eroare la preluare userLS');
        }
    }

    getMeditation = async () => {

        let content = await getFromServer('content', { mode: 'content_item_admin', id_content: this.props.match.params.idMeditation, id_user: this.localData.userLS.idul, id_sesiune: this.localData.userLS.sessionIdul }, { credentials: 'include' })

        if (content.r === 'ok') {

            console.log(`content `, content);
            this.getAuthors()

            let tags = []
            if (content.continut[0].tags !== null) {
                tags = content.continut[0].tags.split(',')
            }

            this.setState({
                content: content.continut[0],

                name: content.continut[0].nume,
                description: content.continut[0].descriere,
                imagePath: content.continut[0].cale_poza_listare,
                audioPath: content.continut[0].cale_audio,
                categories: content.categories_info,
                subcategories: content.subcategories_info,

                isContentFreeUsers: String(content.continut[0].is_free_users) === '1' ? true : false,
                isContentFreeEveryone: String(content.continut[0].is_free_everybody) === '1' ? true : false,
                isContentLoop: String(content.continut[0].content_loop) === '1' ? true : false,
                isPublished: String(content.continut[0].ascuns) === '1' ? false : true,
                tags: tags,
                author: content.continut[0].id_autor || 0

            }, () => {
                // console.log('sucategories ', this.state.subcategories)

                if (this.state.content.cale_audio !== null) {

                    audio.src = mediaPath(this.state.content.cale_audio, "audio")
                    audio.onloadedmetadata = () => {

                        let hour = Math.floor(audio.duration / 3600)
                        let minutes = Math.floor(audio.duration / 60);
                        let seconds = Math.ceil(audio.duration - minutes * 60) - 1

                        if (minutes >= 60) {
                            minutes = minutes - 60
                        }

                        hour = hour < 10 ? '0' + hour : hour

                        this.setState({
                            duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
                        })
                    }
                } else {

                }
            })
        }
    }


    getAuthors = async () => {

        let authors = await getFromServer('author', {
            mode: 'get_authors_list',
            id_user: this.localData.userLS.idul,
            id_sesiune: this.localData.userLS.sessionIdul
        }, { credentials: 'include' })

        // console.log('autorii mei ', authors)

        if (authors.r === 'ok') {
            this.setState({ authors: authors.continut })
        } else {
            console.error(authors.mesaj)
        }

    }

    selectFile = (mediaType) => {

        // console.log('--- ', document.getElementById('input-poza-listare-meditation'))
        // let imageInput = document.getElementById('input-poza-listare-meditation');
        // console.log('imageInput ', imageInput)

        // imageInput.click();

        switch (mediaType) {
            case 'image':
                let imageInput = document.getElementById('input-poza-listare-meditation');
                imageInput.click();
                break;
            case 'audio':
                let audioInput = document.getElementById('input-audio');
                audioInput.click();
                break;
            default:
                console.error('Switch a intrat in default');
                break;
        }
    }

    fileChange = async (idfile, idListare, type) => {

        let saveFile = document.getElementById(idfile).files[0];

        let pozaListare = document.getElementById(idListare)

        let reader = new FileReader();
        reader.readAsDataURL(saveFile);

        reader.onload = async () => {

            let fileContent = reader.result;
            pozaListare.src = fileContent

            if (type === 'image') {

                await getFromServer('content', {
                    mode: 'insert_list_image',
                    id_content: this.props.match.params.idMeditation
                },
                    { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')

            } else if (type === 'audio') {
                let insertAudio = await getFromServer('content', {
                    mode: 'insert_content_sound',
                    id_content: this.props.match.params.idMeditation
                },
                    { credentials: 'include', method: 'POST' }, false, 'POST', saveFile, 'file')

                if (insertAudio.r === 'ok') {
                    audio.setAttribute('src', fileContent)

                    try {

                        audio.onloadedmetadata = async () => {

                            let hour = Math.floor(audio.duration / 3600)
                            let minutes = Math.floor(audio.duration / 60);
                            let seconds = Math.ceil(audio.duration - minutes * 60) - 1

                            if (minutes >= 60) {
                                minutes = minutes - 60
                            }

                            let time = `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`

                            let response = await getFromServer('content', {
                                mode: 'update_time',
                                time: time,
                                id_content: this.props.match.params.idMeditation
                            }, { credentials: 'include' })
                            
                            console.log('response ', response)

                            // hour = hour < 10 ? '0' + hour : hour

                            // this.setState({
                            //     progress: false,
                            //     duration: `${hour < '01' ? '' : hour + ':'}${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,

                            // })
                        }
                    } catch (e) {
                        alert(e)
                    }

                } else {

                }

            } else {
                console.log('A intrat in else')
            }

            this.getMeditation()
        }
    }

    setInput = (type, ev) => {
        switch (type) {
            case 'description':
                this.setState({ description: ev.target.value });
                break;
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            case 'author':
                this.setState({ author: ev.target.value });
                break;
            case 'isContentFreeUsers':
                this.setState({ isContentFreeUsers: ev.target.checked }, () => this.updateMeditation());
                break;
            case 'isContentLoop':
                this.setState({ isContentLoop: ev.target.checked }, () => this.updateMeditation());
                break;
            case 'isContentFreeEveryone':
                this.setState({ isContentFreeEveryone: ev.target.checked }, () => this.updateMeditation());
                break;

            default:
                console.log('switch a intrat in default')
        }
    }

    updateMeditation = async () => {

        let isContentFreeUsers = this.state.isContentFreeUsers === true ? 1 : 0;
        let isContentFreeEveryone = this.state.isContentFreeEveryone === true ? 1 : 0;

        let description = this.state.description === null ? '' : this.state.description

        let responseUpdateMeditation = await getFromServer('content', {
            mode: 'content_details',
            id_content: this.props.match.params.idMeditation,
            name: this.state.name,
            description: description,
            content_loop: this.state.isContentLoop === true ? '1' : '0',
            id_author: this.state.author ? this.state.author : 0,
            is_free_users: isContentFreeUsers,
            is_free_everybody: isContentFreeEveryone,
            // ascuns: this.state.isPublished === 1 ? this.state.isPublished : 0
        }, { credentials: 'include' })

        if (responseUpdateMeditation.r === 'ok') {
            console.log('raspuns update meditatie ', responseUpdateMeditation)
        } else {
            alert('a aparut problema la update aplicatie');
        }

    }

    publishUnpublishMeditation = (ev) => {
        this.setState({ isPublished: ev.target.checked }, async () => {

            if (this.state.isPublished === true) {
                await getFromServer('content', { mode: 'publish', id_content: this.props.match.params.idMeditation }, { credentials: 'include' })
            } else {
                await getFromServer('content', { mode: 'unpublish_content', id_content: this.props.match.params.idMeditation }, { credentials: 'include' })
            }

        })
    }

    /* TAGS section */

    setNewTag = (newTagFromChild) => {
        this.setState({ newTag: newTagFromChild, editTagsMode: "add" }, () => {
            this.updateTags()
        })
    }

    updateTags = async () => {
        // console.log('a intrat in update taguri', this.state.newTag)
        let tags = [...this.state.tags]

        // daca exista newTag se face adaugare, daca nu este stergere
        if (this.state.editTagsMode === "add") {
            tags.push(this.state.newTag)
        }
        // console.log('tags inainte de update ', tags)
        let stringTags = tags.toString()

        let updateTags = await getFromServer('content', { mode: 'content_tags', id_content: this.props.match.params.idMeditation, tags: stringTags }, { credentials: 'include' })

        if (updateTags.r === 'ok') {
            this.setState({
                tags: tags,
                // stringTags: stringTags,
                tag: ''
            })

            this.getMeditation();

        } else {
            alert('Problema la adaugare taguri')
        }

    }

    deleteTag = (index) => {

        let newTags = [...this.state.tags]

        // console.log('taguri inainte de stergere  ', newTags)
        newTags.splice(index, 1)
        // console.log('taguri dupa stergere  ', newTags)
        this.setState({ tags: newTags, editTagsMode: "delete" }, () => {
            this.updateTags()
        })
    }

    /* End TAGS section */


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <p className="opacity-title blue-lite">Editare Meditatie</p>

                    <div>

                        {/* ************************* INFORMATII GENERALE ************************* */}

                        <p className="namaste-label">Nume Meditatie</p>
                        <input
                            className="namaste-round-input"
                            value={this.state.name || ''}
                            onChange={ev => {
                                this.setInput('name', ev)
                            }}
                        >
                        </input>

                        <p className="namaste-label">Descriere</p>
                        <textarea
                            className="namaste-round-textarea"
                            value={this.state.description || ''}
                            onChange={ev => {
                                this.setInput('description', ev)
                            }}
                        >
                        </textarea>

                        <p className="namaste-label">Autori</p>
                        <Select
                            value={this.state.author || 0}
                            onChange={(ev) => { this.setInput('author', ev) }}
                            className="namaste-select-box"
                        >
                            {this.state.authors.map((item, idx) => {
                                return <MenuItem value={item.idul} key={idx} >{item.nume}</MenuItem>
                            })
                            }
                        </Select>

                        <LongSaveButton text='Actualizare' action={this.updateMeditation} />
                        <Divider light />

                        {/* ************************* CATEGORII - SUBCATEGORII ************************* */}

                        {
                            this.state.name.length > 0 &&
                            <AdminMediationCategorySubcategory
                                idMeditation={this.props.match.params.idMeditation}
                                categories={this.state.categories}
                                contentSubcategories={this.state.subcategories}
                                getMeditation={this.getMeditation}
                            />
                        }
                        <Divider light />

                        {/* ************************* TAG-URI ************************* */}

                        <p className="subtitle blue-lite text-center">Tag-uri</p>

                        <AdminTagsEditor list={this.state.tags} updateNewValue={this.setNewTag} deleteItem={this.deleteTag} />
                        <Divider light />

                        {/* ************************* MEDIA ************************* */}



                        <p className="subtitle blue-lite text-center">Media</p>
                        <Grid container style={{ margin: 'auto' }}>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/* Camp pentru upload poza, ascuns */}
                                <input id="input-poza-listare-meditation" type="file" accept="image/*"
                                    onChange={() => { this.fileChange("input-poza-listare-meditation", 'list-image-meditation', 'image') }}
                                    style={{ display: 'none' }}
                                ></input>

                                {/* Upload poza element */}
                                <p className="namaste-label text-center" >Poza listare</p>
                                <div className="image-thumb-admin-container ">
                                    <img
                                        alt="namaste-space-default"
                                        id='list-image-meditation'
                                        className='image-thumb-admin'
                                        src={this.state.imagePath !== null ? mediaPath(this.state.imagePath, 'images') : mediaPath('default_img.jpg', 'images')}
                                    />
                                    <AddRoundButton cssStyle={{ position: 'absolute', top: '-10px', right: '-10px' }} action={() => this.selectFile('image')} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                {/* Camp pentru upload audio, ascuns */}
                                <input id="input-audio" type="file" accept="audio/*"
                                    onChange={() => { this.fileChange("input-audio", 'list-sound', 'audio') }}
                                    style={{ display: 'none' }}
                                ></input>


                                {/* Upload poza element */}
                                <p className="namaste-label text-center">Continut Audio</p>
                                <div className="image-thumb-admin-container ">
                                    <img
                                        alt="namaste-space-default-sound"
                                        id='list-sound'
                                        className='image-thumb-admin'
                                        src={this.state.audioPath !== null ? mediaPath('yes_sound_img.jpg', 'images') : mediaPath('no_sound_img.jpg', 'images')}
                                    />
                                    <AddRoundButton cssStyle={{ position: 'absolute', top: '-10px', right: '-10px' }} action={() => this.selectFile('audio')} />
                                </div>

                                <Player
                                    playPause={this.playPause}
                                    audio={audio}
                                />

                            </Grid>

                        </Grid>

                        <Divider light />

                        {/* ************************* SETARI ************************* */}

                        <p className="subtitle blue-lite text-center">Setari</p>
                        <Grid container>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div>
                                    <Switch
                                        className={this.state.isContentFreeUsers ? "checkSwitch" : "uncheckSwitch"}
                                        checked={this.state.isContentFreeUsers}
                                        onChange={(ev) => this.setInput('isContentFreeUsers', ev)}
                                    />
                                    <span>Meditatie gratis pentru utilizatori</span>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div>
                                    <Switch
                                        className={this.state.isContentFreeEveryone ? "checkSwitch" : "uncheckSwitch"}
                                        checked={this.state.isContentFreeEveryone}
                                        onChange={(ev) => this.setInput('isContentFreeEveryone', ev)}
                                    />
                                    <span>Meditatie gratis pentru utilizatori neautentificati</span>
                                </div>
                            </Grid>


                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div>
                                    <Switch
                                        className={this.state.isContentLoop ? "checkSwitch" : "uncheckSwitch"}
                                        checked={this.state.isContentLoop}
                                        onChange={(ev) => this.setInput('isContentLoop', ev)}
                                    />
                                    <span>Meditatia face loop la final?</span>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                                <div>
                                    <Switch
                                        className={this.state.isPublished ? "checkSwitch" : "uncheckSwitch"}
                                        checked={this.state.isPublished}
                                        onChange={(ev) => this.publishUnpublishMeditation(ev)}
                                    />
                                    <span>Live in Site</span>
                                </div>
                            </Grid>

                        </Grid>

                    </div>

                </div>
            </div>
        )
    }
}

