import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import Footer from '../Footer/Footer';
import { getFromServer } from '../../Providers/WS';
import LongSaveButton from '../Pieces/LongSaveButton';
import Validate from '../../Providers/Validator';
import LocalStorage from '../../Providers/LS';


export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            name: '',
            email: '',
            message: '',
            successMessage: ''
        }
    }

    errors = []
    userData = {}

    validate = new Validate()
    localStorage = new LocalStorage()

    componentDidMount() {
        this.userData = this.localStorage.getLocalStorage(this.localStorage.userLS)
        console.log('userData', this.userData)
    }

    validateUserInputs = () => {

        this.errors = [];

        console.log('this.userInputs.name ', this.state.name)
        if (!this.validate.lenghtValidate(4, this.state.name)) {
            this.setState({ errors: true });
            this.errors[0] = 'The name must contain at least 5 characters.';
        }

        // verificare doar daca este in SIGN UP mode
        if (!this.validate.emailValidate(this.state.email)) {
            this.setState({ errors: true });
            this.errors[1] = 'Invalid e-mail address.';
        }

        if (!this.validate.lenghtValidate(4, this.state.message)) {
            this.setState({ errors: true });
            this.errors[2] = 'The name must contain at least 5 characters.';
        }

        if (this.errors.length === 0) {

            // doar daca am avut erori
            if (this.state.errors) {
                this.setState({ errors: false });
            }

            this.sendMessage();

        } else {
            console.error('contact errors')
        }

    }

    sendMessage = async () => {

        this.setState({ vTrimiteContact: 'Va rugam asteptati.....' })
        let responseContact = await getFromServer('contact', { mode: 'insert_message', name: this.state.name, email: this.state.email, message: this.state.message, id_user: this.userData ? this.userData.idul : 0 }, { credentials: 'include' })

        console.log('responseContact  ', responseContact)

        if (responseContact.r === 'ok') {
            this.setState({ name: '', email: '', message: '', successMessage: 'The message was sent succesfully' })
        }  

    }

    setInput = (type, ev) => {
        switch (type) {
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            case 'email':
                this.setState({ email: ev.target.value });
                break;
            case 'message':
                this.setState({ message: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />

                <div className="section backgroundWhite width80">

                    <p className="title2 text-center">Contact</p>

                    <input
                        className="namaste-round-input"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={(ev) => {
                            this.setInput('name', ev)
                            // this.userInputs.name = ev.target.value
                        }}
                    ></input>
                    {
                        this.state.errors &&
                        <p className="error-message text-center">{this.errors[0]}</p>
                    }

                    <input
                        className="namaste-round-input"
                        placeholder="E-mail"
                        value={this.state.email}
                        onChange={(ev) => {
                            this.setInput('email', ev)
                        }}
                    ></input>
                    {
                        this.state.errors &&
                        <p className="error-message text-center">{this.errors[1]}</p>
                    }

                    <textarea
                        className="namaste-round-textarea"
                        placeholder="Message"
                        value={this.state.message}
                        onChange={(ev) => {
                            this.setInput('message', ev)
                        }}
                    >
                    </textarea>

                    {
                        this.state.errors &&
                        <p className="error-message text-center">{this.errors[2]}</p>
                    }

                    <LongSaveButton text='Send Message' action={this.validateUserInputs} />

                    {
                        !this.state.errors && this.state.successMessage &&
                        <p className="success-message text-center">{this.state.successMessage}</p>
                    }


                </div>

                <Footer history={this.props.history} />
            </div>
        );
    }


}  // end class