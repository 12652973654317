import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { getFromServer } from '../../../Providers/WS';
import { Tabs, Tab } from '@material-ui/core';
import SwitchButton from '../../Pieces/SwitchButton';

export default class AdminRatings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabsValue: 0,
            approved: [],
            unapproved: []
        }
    }

    componentDidMount() {
        this.getRatings();
    }

    getRatings = async () => {

        let ratings = await getFromServer('rating', { mode: 'get_ratings' }, { credentials: 'include' })
        console.log('rating are: ', ratings)

        if (ratings.r === 'ok') {

            let ratingsApproved = [...ratings.continut]

            let approved = ratingsApproved.filter(r => r.publicat === '1')
            let unapproved = ratingsApproved.filter(r => r.publicat !== '1')

            this.setState({
                approved: approved,
                unapproved: unapproved
            })
        } else {
            console.error('Error with admin ratings')
        }
    }

    delete = async (deleteStatus, idul, ev, item) => {

        let cloneAprobate = [...this.state.approved]
        let cloneNeaprobate = [...this.state.unapproved]

        console.log('item.publicat ', item.publicat)
        if (String(item.publicat) !== '0') {

            let newR = item
            newR.publicat = '0'
            let idx = cloneAprobate.findIndex(ra => ra.id_rating === item.id_rating)

            cloneAprobate.splice(idx, 1)
            cloneNeaprobate.unshift(newR)

            let unapprove = await getFromServer('rating', {
                mode: 'unapprove_rating',
                id_rating: item.id_rating,
                id_content: item.id_content
            }, { credentials: 'include' })

            if (unapprove.r === 'ok') {

                this.setState({
                    approved: cloneAprobate,
                    unapproved: cloneNeaprobate
                })
            }

        } else {

            let idx = cloneNeaprobate.findIndex(ra => ra.id_rating === item.id_rating)
            cloneNeaprobate.splice(idx, 1)

            let deleteResponse = await getFromServer('rating', { mode: 'delete_rating', id_rating: item.id_rating }, { credentials: 'include' })
            console.log('deleteResponse ', deleteResponse)
            if (deleteResponse.r === 'ok') {
                this.setState({
                    unapproved: cloneNeaprobate
                })
            }
        }
    }

    aproba = async (deleteStatus, idul, ev, item) => {

        let cloneAprobate = [...this.state.approved]
        let cloneNeaprobate = [...this.state.unapproved]

        let newR = item

        newR.publicat = '1'

        let idx = cloneNeaprobate.findIndex(ra => ra.id_rating === item.id_rating)

        cloneNeaprobate.splice(idx, 1)

        cloneAprobate.unshift(newR)

        let approveResponse = await getFromServer('rating', {
            mode: "approve_rating",
            id_rating: item.id_rating,
            id_content: item.id_content
        }, { credentials: 'include' })
        if (approveResponse.r === 'ok') {

            this.setState({
                approved: cloneAprobate,
                unapproved: cloneNeaprobate
            })

        } else {
            console.error('Admin Approve Problem',)
        }
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <div>
                        <p className="opacity-title blue-lite">Ratings</p>
                    </div>
                    <Tabs
                        value={this.state.tabsValue}
                        onChange={(ev, tabsValue) => { this.setState({ tabsValue }) }}
                        className="meditatii-tabs"
                        textColor="primary"
                        centered
                    >
                        <Tab label={<span style={{ color: this.state.tabsValue !== 0 ? "#2991d6" : "#02c753" }}>Aprobate</span>} />
                        <Tab label={<span style={{ color: this.state.tabsValue !== 1 ? "#2991d6" : "#02c753" }}>Neaprobate</span>} />

                    </Tabs>

                    <div>


                        {this.state.tabsValue === 0 &&
                            <div>

                                <div className="table-head">
                                    <div className="width80 inline-block">Rating</div>
                                    <div className="width20 inline-block">DezAproba</div>
                                    {/* <div className="width10 inline-block">Sterge</div> */}
                                </div>

                                {

                                    this.state.approved.map((rating, idx) => {
                                        return (
                                            <div key={idx} className="row-item ">

                                                <div className="width80 inline-block">
                                                    <p>{rating.nume_content}</p>
                                                    <p>User: {rating.nume_utilizator}</p>
                                                    <p>Rating {rating.nota}</p>
                                                    <p>Data: {rating.data_adaugare}</p>
                                                </div>

                                                <div className="width20 inline-block">
                                                    <SwitchButton item={rating} action={this.delete} />
                                                </div>
                                                {/* <LiRatings key={idx} index={idx} rating={r} aproba={this.aproba} sterge={this.sterge} /> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {this.state.tabsValue === 1 &&

                            <div>
                                <div className="table-head">
                                    <div className="width60 inline-block">Rating</div>
                                    <div className="width20 inline-block">Aproba</div>
                                    <div className="width20 inline-block">Sterge</div>
                                </div>

                                {

                                    this.state.unapproved.map((rating, idx) => {
                                        return (
                                            <div key={idx} className="row-item">
                                                <div className="width60 inline-block">
                                                    <p>{rating.nume_content}</p>
                                                    <p>User: {rating.nume_utilizator}</p>
                                                    <p>Rating: {rating.nota}</p>
                                                    <p>Data: {rating.data_adaugare}</p>
                                                </div>
                                                <div className="width20 inline-block">
                                                    <SwitchButton item={rating} action={this.aproba} />
                                                </div>
                                                <div className="width20 inline-block">
                                                    <SwitchButton item={rating} action={this.delete} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                    </div>


                </div>

            </div>
        );
    }


}  // end class