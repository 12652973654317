import React from 'react';
import AdminHeader from '../AdminHeader';
import SiteHeader from '../../SiteHeader/SiteHeader';
import { getFromServer } from '../../../Providers/WS';

export default class AdminContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        }
    }



    componentDidMount() {
        this.getMessages()
    }
    
    getMessages = async () => {
        let messages = await getFromServer('contact', { mode: 'get_messages' }, { credentials: 'include' })
        console.log('mesajele admin sunt: ', messages)

        if (messages.r === 'ok') {

            this.setState({
                messages: messages.continut
            })
        } else {
            console.error('Error with admin ratings')
        }
    }

    goToUser = (idul) => {
        this.props.history.push(`/adminuserdetais/${idul}`)
    }

    render() {
        return (
            <div>

                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />
                <div className="admin-child-container">
                    <div>
                        <p className="opacity-title blue-lite">Contact</p>

                        <div className="table-head">
                            <div className="width10 inline-block">ID Utilizator</div>
                            <div className="width15 inline-block">E-mail</div>
                            <div className="width60 inline-block">Mesaj</div>
                            <div className="width15 inline-block">Data</div>
                        </div>

                        {
                            this.state.messages.map((item, index) => {
                                return (
                                    <div key={index} className="row-item" onClick={() => { item.id_utilizator > 0 &&  this.goToUser(item.id_utilizator) }}>
                                        <div className="width10 inline-block">{item.id_utilizator}</div>
                                        <div className="width15 inline-block">{item.email}</div>
                                        <div className="width60 inline-block">{item.message}</div>
                                        <div className="width15 inline-block">{item.data_adaugare}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }


}  // end class
