import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import LongSaveButton from '../../Pieces/LongSaveButton'

import { getFromServer } from '../../../Providers/WS';

export default class AdminEditAuthor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            author: {},
            description: '',
            name: '',
            website: ''
        }
    }

    componentDidMount() {
        this.getAuthor()
    }

    getAuthor = async() => {
 
        let author = await getFromServer('author', {
            mode: 'get_author',
            id_author: this.props.match.params.idAuthor
        }, { credentials: 'include' })
 
        // console.log('autor ', author)
 
        if (author.r === 'ok') {
            this.setState({ name: author.continut[0].nume, description: author.continut[0].descriere || '', website: author.continut[0].website || ''})
        } else {
            console.log('eroare la preluare autor')
        }
    }

    updateAutor = async () => {

        console.log('eeee',)

        let updateAutor = await getFromServer('author', {
            mode: 'update_author',
            id_author: this.props.match.params.idAuthor,
            author_name: this.state.name,
            description: this.state.description,
            website: this.state.website
        }, { credentials: 'include' })

        if (updateAutor.r === 'ok') {
            this.props.history.push('/adminauthors');
        } else {
            alert('A aparut o problema la actualizare informatii')
        }

    }

    setInput = (type, ev) => {
        switch (type) {
            case 'description':
                this.setState({ description: ev.target.value });
                break;
            case 'name':
                this.setState({ name: ev.target.value });
                break;
            case 'website':
                this.setState({ website: ev.target.value });
                break;
            default: 
                console.log('switch a intrat in default',)
        }
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <p className="opacity-title blue-lite">Editare Autor</p>
                    {
                        this.state.author &&
                        <div>

                            <input
                                className="namaste-round-input"
                                value={this.state.name || ''}
                                onChange={ ev => {
                                    this.setInput('name', ev)
                                }}
                            >
                            </input>

                            <textarea
                                className="namaste-round-textarea"
                                value={this.state.description || ''}
                                onChange={ ev => {
                                    this.setInput('description', ev)
                                }}
                            >
                            </textarea>

                            <input
                                className="namaste-round-input"
                                value={this.state.website || ''}
                                onChange={ev => {
                                    this.setInput('website', ev)
                                }}
                            ></input>
                            <LongSaveButton text='Actualizare' action={this.updateAutor}/>
                        </div>
                    }
  
                </div>
            </div>
        )
    }
}