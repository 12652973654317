import React from 'react';
import SiteHeader from "../SiteHeader/SiteHeader"
import Footer from '../Footer/Footer';



const Privacy = (props) => {

    const goToCookies = () => {
        // window.location.href = '/cookies'
        console.log('props ', props)
        props.history.push('/cookies')
    }


    return (
        <div>
            <SiteHeader openAuthentificationPanel={props.openAuthentificationPanel} />

            <div className="section backgroundWhite width80">
                <p className="title text-center">Privacy Policy</p>

                <p className="plain-text">This Privacy Policy explains how Namastespace.com (“Namaste Space”, “we” or “us”) collects, uses, and discloses information about you when you access or use our websites, mobile application, and other online products and services (collectively, the “Services”), and when you contact our customer service team, engage with us on social media, or otherwise interact with us.
We may change this Privacy Policy from time to time and we encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>

                <p className="subtitle">Collection of Information</p>
                <p className="plain-text bold">Information You Provide to Us</p>
                <p className="plain-text ">We collect information when you register for an account, participate in interactive features (like submit a meditation rating), fill out a form or a survey, participate in a contest or promotion, make a donation, communicate with us via social media sites, request customer support, or otherwise communicate with us. The information you may provide includes your name, email, password, goals, previous meditation experience, feedback and survey responses, and other information about you included within your messages to us. </p>
                <br />
                <p className="plain-text bold">Other Information About Your Use of the Services</p>
                <p className="plain-text">When you use our Services, we collect the following information about you:</p>

                <ul>
                    <li className="plain-text">Usage Information: Whenever you use our Services, we collect usage information, such as the sessions you use, videos you view or music you listen to, what screens or features you access, and other similar types of usage information.</li>
                    <li className="plain-text">
                        Transactional Information: When you make a donation, we collect information about the transaction, such as product description, amount donated, time and date of the transaction.
                    </li>
                    <li className="plain-text">
                        Log Information: We collect standard log files when you use our Services, which include the type of web browser you use, app version, access times and dates, pages viewed, your IP address, and the page you visited before navigating to our websites.
                    </li>
                    <li className="plain-text">
                        Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, device identifiers set by your device operating system, and mobile network information (like your connection type, carrier and region).
                    </li>
                    <li className="plain-text">
                        Information we Generate: We generate some information about you based on other information we have collected. For example, like most platforms, we use your IP address to derive the approximate location of your device. We also use your first name to make an educated guess about your gender and use information about your activity to help determine the likelihood of you continuing to use our Services in the future (which we hope will be the case!).
                    </li>
                    <li className="plain-text">
                        Information Collected by Cookies and Similar Tracking Technologies: We use different technologies to collect information, including cookies and web beacons. Please see our Cookie Policy for more details: <span onClick={goToCookies} className="link" >https://www.namastespace.com/cookies</span> .
                    </li>
                </ul>

                <p className="subtitle">Use of Information</p>
                <p className="plain-text">We use the information we collect to:</p>

                <ul>
                    <li className="plain-text">
                        Provide, maintain and improve our Services, and develop new products and service;
                    </li>

                    <li className="plain-text">
                        Communicate with you about products, services, and events offered by Namaste Space and others, request feedback, and send news, gifts or other information we think will be of interest to you;
                    </li>
                    <li className="plain-text">
                        Monitor and analyze trends, usage, and activities in connection with our Services;
                    </li>
                    <li className="plain-text">
                        Personalize your online experience and the advertisements you see on other platforms based on your preferences, interests, and browsing behavior; and
                    </li>
                </ul>

                <p className="subtitle">Sharing of Information</p>
                <p className="plain-text">We share information about you as follows and as otherwise described in this Privacy Policy: </p>

                <ul>
                    <li className="plain-text">
                        With companies and contractors that perform services for us, including email service providers, payment processors, fraud prevention vendors and other service providers;
                    </li>

                    <li className="plain-text">
                        If we believe your actions are inconsistent with our user agreements or policies, if we believe you have violated the law, or to protect the rights, property, and safety of Namaste Space or others;
                    </li>
                </ul>

                <p className="subtitle">Advertising and Analytics Services Provided by Others</p>
                <p className="plain-text">We allow others to provide analytics services and serve advertisements on our behalf across the web and in mobile applications. These entities use cookies, web beacons, device identifiers and other technologies to collect information about your use of the Services and other websites and online services, including your IP address, device identifiers, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information. This information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and online services, and better understand your online activity. </p>


                <p className="subtitle">Your Choices</p>
                <p className="plain-text bold">Account Information
                 </p>
                <p className="plain-text">You may update certain account information you provide to us (such as your password, name and email address) by logging into your account or contacting us through our Help Center at contact@namastespace.com. If you wish to disable your account, please contact us at contact@namastespace.com.
                 </p>

                <p className="subtitle">Contact Us</p>
                <p className="plain-text bold">If you have any questions about this Privacy Policy, please contact us at: contact@namastespace.com.
                 </p>


            </div>

            <Footer history={props.history} />
        </div>
    )
}

export default Privacy