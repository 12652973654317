import React from 'react'; 
import { checkpoints } from '../../Providers/WS';
import { getFromServer } from '../../Providers/WS';

export default class Checkpoints extends React.Component{

    componentDidMount() {
        console.error('this.props in heckpoint ', this.props)
    }

    // prevCheckpoint = 0;

    verifyCheckpoint(percent, checkpointObj, prevCheckpoint, setPrevCheckpoint) {
        let percentTime = Math.floor(Number(percent));

        console.log(`percent: ${percentTime} lastcheckpoint: ${prevCheckpoint}`)
        
        if (checkpoints.includes(percentTime) && prevCheckpoint !== percentTime) {
            console.log(`${prevCheckpoint} !== ${percentTime}`)
            
            this.setCheckpoint(checkpointObj, percentTime)

            // schimbare valoare ultimul checkpoint atins 
            // functia este in meditation.js 
            setPrevCheckpoint(percentTime)
            
        } else {

            console.log(` ${percentTime} nu este checkpoint` ,)
        }
    }

    setCheckpoint = async (checkpointObj, percentTime) => {
        console.log('checkpointObj ', checkpointObj)
        
        let checkpointResponse = await getFromServer('checkpoint_levels', {
            
            mode: 'verify_checkpoint',
            id_content: checkpointObj.id_content,
            id_user: checkpointObj.id_user,
            id_sesiune: checkpointObj.id_sesiune,
            id_level: checkpointObj.id_level,
            checkpoint_value: percentTime

        }, { credentials: 'include' })

        
        if (checkpointResponse.r === 'ok') {
            
            console.log('raspuns checkpoint ', checkpointResponse)

        } else if (checkpointResponse.r === 'err_login') {

            console.error('Please Relogin');
            this.localStorage.deleteLocalStorage()

        } else {
            console.error('checkpoint error');
        }
    }


    render(){
        return (
            <div>
                
            </div>
        );
    }


}  // end class