import React from 'react';
import SiteHeader from "../SiteHeader/SiteHeader"
import Footer from '../Footer/Footer';


const Terms = (props) => {
    return (
        <div>
            <SiteHeader openAuthentificationPanel={props.openAuthentificationPanel} />

            <div className="section backgroundWhite width80">
                <p className="title text-center">Terms of Service</p>

                <p className="plain-text">Welcome to Namaste Space! <br /><br />Casa Namaste Srl provides our website located at www.namastespace.com (the “Site”) and our relaxation and self-improvement services are accessible via our Site and our mobile device application (“App”). For future reference, The Site, our services and App are collectively called the “Services.” Please read these Terms carefully because they govern your use of our Services.</p>

                <p className="subtitle">Agreement to Terms</p>

                <p className="plain-text">By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.</p>

                <p className="subtitle">Changes to Terms or Services</p>
                <p className="plain-text">We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the modified Terms on the Site or through other communications. It’s important that you review the Terms whenever we modify them, if you don’t agree to be bound by the modified Terms, then you may not use the Services anymore. </p>


                <p className="subtitle">Who May Use the Services</p>
                <p className="plain-text bold">Eligibility </p>
                <p className="plain-text">You may use the Services only if you are 13 years or older and are not barred from using the Services under applicable law. </p>
                <br />

                <p className="plain-text bold">Registration and Your Information </p>
                <p className="plain-text">If you want to use certain features of the Services you’ll have to create an account (“Account”). You can do this via the App or the Site or through your account with certain third-party social networking services such as Facebook. It’s important that you provide us with accurate, complete and up-to-date information for your Account and you agree to update such information, as needed, to keep it accurate, complete and up-to-date. You’re responsible for all activities that occur under your Account, whether or not you know about them.</p>

                <p className="subtitle">Using the Services</p>
                <p className="plain-text">Via the Services, users can select from a variety of options to create customized relaxation and self-improvement sessions.
You understand and agree that the Services, Products and any other information you learn from Namaste Space are not intended, designed, or implied to diagnose, prevent, or treat any condition or disease, to ascertain the state of your health, or to be a substitute for professional medical care.  Do not use the Services while driving, operating heavy machinery, or performing other tasks that require attention and concentration. You understand and agree that you are solely responsible for your use of the Services.</p>

                <p className="subtitle">Feedback</p>
                <p className="plain-text">We welcome feedback, comments and suggestions for improvements to the Services or Products (“Feedback”). You can submit Feedback by emailing us at contact@namastespace.com. </p>

                <p className="subtitle">Content and Content Rights</p>
                <p className="plain-text">For purposes of these Terms, (i) “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services; and (ii) “User Content” means any Content that users (including you) provide to be made available through the Services. Content includes without limitation User Content. </p>

                <p className="subtitle">Content Ownership</p>
                <p className="plain-text">Namaste Space and its licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You acknowledge that the Services and Content are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content. </p>

                <p className="subtitle">Rights in Content Granted by Namaste Space</p>
                <p className="plain-text">Subject to your compliance with these Terms, Namaste Space grants you a limited, non-exclusive, non-transferable, non-sublicenseable license to download, view, copy and display the Content solely in connection with your permitted use of the Services and solely for your personal and non-commercial purposes. </p>

                <p className="subtitle">Rights and Terms for Apps</p>
                <p className="plain-text bold">Rights in App Granted by Namaste Space</p>
                <p className="plain-text">Namaste Space grants you a limited non-exclusive, non-transferable, non-sublicenseable license to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your own personal non-commercial purposes.  </p>

                <p className="subtitle">Links to Third Party Websites or Resources</p>
                <p className="plain-text">The Services and App may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party websites or resources.  </p>

                <p className="subtitle">Termination</p>
                <p className="plain-text">We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. You may cancel your Account at any time by sending an email to us at <span className="bold">contact@namastespace.com</span>.</p>

                <p className="subtitle">Warranty Disclaimers</p>
                <p className="plain-text">The Services, Products and Content are provided “as is,” without warranty of any kind. Without limiting the foregoing, we explicitly disclaim any warranties of merchantability, fitness for a particular purpose, quiet enjoyment or non-infringement and any warranties arising out of course of dealing or usage of trade.

We make no warranty that the Services or Products will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content.</p>

                <p className="subtitle">Indemnity</p>
                <p className="plain-text">You will indemnify and hold harmless Casa Namaste Srl and its staff from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (i) your access to or use of the Services or Content or (ii) your violation of these Terms.</p>

                <p className="subtitle">Indemnity</p>
                <p className="plain-text bold">Neither Namastespace.com nor any other party involved in creating, producing, or delivering the Services, Products or Content will be liable for any incidental, special, exemplary or consequential damages, including, but not limited to, lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute Services or Products arising out of or in connection with these terms or from the use of or inability to use the Services, Products or Content, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not Namaste Space has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you.
                </p>
                
                <p className="subtitle">General Terms</p>
                <p className="plain-text">These Terms constitute the entire and exclusive understanding and agreement between Namaste Space and you regarding the Services, Products and Content.</p>

                <p className="subtitle">Contact Information</p>
                <p className="plain-text">If you have any questions about these Terms or the Services or Products, please contact Namaste Space at contact@namastespace.com.</p>

            </div>

            <Footer history={props.history} />
        </div>
    )
}

export default Terms