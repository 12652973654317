import React from 'react';

export default class LongSaveButton extends React.Component {

    componentDidMount() {
    }


    render() {
        return (
            <button className="green-button namaste-round-button" onClick={this.props.action}>{this.props.text}</button>
        )
    }
}

