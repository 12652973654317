import React, { Fragment } from 'react';
import { mediaPath, default_image_meditation } from '../../Providers/WS';
import { getFromServer } from '../../Providers/WS';
import LocalStorage from '../../Providers/LS';
import RatingStars from '../Rating/RatingStars';
import speaker from '../../images/speaker.png';

// import checked from '../../images/checked.png';

export default class MeditationBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meditation: {}
        }
    }

    componentDidMount() {
        // console.log('id content ', this.props.idMeditation);
        this.getUserLocalStorage();
    }

    localData = {
        userData: {},
        checkPointObj: {}
    }
    localStorage = new LocalStorage()

    getUserLocalStorage = async () => {
        this.localData.userData = await this.localStorage.getLocalStorage(this.localStorage.userLS)

    }

    getMeditation = async (idMeditation) => {
        // console.log(` level: ${this.props.id_level} id meditation ${this.props.idMeditation} `)

        // prepare object for checkpoints JOB
        this.localData.checkPointObj = {
            id_user: this.localData.userData.idul,
            id_content: idMeditation,
            id_level: this.props.id_level,
            id_sesiune: this.localData.userData.sessionIdul
        }

        if (idMeditation) {

            let meditation = await getFromServer('content', {
                mode: 'content_item',
                id_content: idMeditation,
                id_user: this.localData.userData.idul,
                id_sesiune: this.localData.userData.sessionIdul
            }, { credentials: 'include' })

            if (meditation.r === 'ok') {

                this.setState({ meditation: meditation.continut[0] }, () => {
                    console.log('Meditatia preluata este ', this.state.meditation);


                    if (this.state.meditation.cale_audio !== null) {

                    } else {

                    }
                })

            } else if (meditation.r === 'err_login') {
                console.error('Please Relogin');
                this.localStorage.deleteLocalStorage()

            } else {
                console.error('Ceva nu a functionat corect la preluare content');
            }
        } else {
            console.error('Ceva nu a functionat corect la primite id content');
        }
    }

    // TOGGLE SLIDE
    slide = (e) => {

        // inchidere elemente deschise
        let openedElement = document.querySelector('.show-element')
        let y = e.target.closest('.meditation-container')
        let z = y.querySelector('.meditation-body')

        // let x = z.getAttribute('data-elem') // momentan nu ne trebuie

        // console.log('a: ', a)
        // console.log('zz ', z) 
        // console.log('xx: ', x)

        if (openedElement) {
            // let b = openedElement.getAttribute('data-elem') // momentan nu ne trebuie

            // console.log('b: ', b)

            // verifica daca se da click pe elementul deschis
            // if (x !== b) {
            //     openedElement.classList.remove("show-element")
            //     openedElement.classList.add("hide-element")
            // }
        }

        if (z.classList.contains('hide-element')) {
            // console.log('ascunde1- ', z.classList.contains('.hide-element'))
            z.classList.add("show-element")
            z.classList.remove("hide-element")
        } else {
            // console.log('arata2- ', z.classList.contains('.show-element'))
            z.classList.add("hide-element")
            z.classList.remove("show-element")
        }
    }

    eventul = {};
    onMeditationBarClicked = async (ev, idCategory) => {
        if (!this.localData.userData) {
            console.log('localUser Data este ::: ', this.localData.userData)
            this.props.openAuthentificationPanel()
            // window.location.href = "/"
            // this.props.history.push('/')
        } else {
            // this.getMeditation(this.props.idMeditation);

            // dupa ce intra in functia de verificare in server daca userul are dreptul sa acceseze meditatia, ev se pierde
            this.eventul = { ...ev }

            // verificare daca are dreptul sa deschida meditatia
            // let isBlocked = await this.verifyIfAccessMeditation(idCategory)

            // console.log('ev ', this.eventul.target)
            // console.log('is blocked ', isBlocked)

            // if (isBlocked) {

            // console.log(ev.target.closest('.meditation-container').querySelector('.meditation-body').classList.contains('show-element'))

            // verificam daca meditatia pe care dam click este inchisa sau deschisa
            let isMeditationisHidden = this.eventul.target.closest('.meditation-container').querySelector('.meditation-body').classList.contains('show-element')

            // medoda se apeleaza doar la deschidere meditatie, nu si cand o inchidem
            if (!isMeditationisHidden) {
                this.getMeditation(this.props.idMeditation)
            }

            // this.eve.stopPropagation();

            this.slide(this.eventul);

            // } else {
            //     console.error('Meditatie blocata')
            // }
        }
    }

    verifyIfAccessMeditation = async (idCategory) => {

        let userLevels = await getFromServer('content', {

            mode: 'is_meditation_blocked',
            id_category: idCategory,
            id_user: this.localData.userData.idul,

        }, { credentials: 'include' });

        if (userLevels.r === 'ok') {

            userLevels = userLevels.continut;
            console.log('userLevels ', userLevels)

            let meditationLevelInfo = userLevels.filter(item => item.id_level === this.props.id_level)
            console.log('meditationLevelInfo ', meditationLevelInfo)

            // daca levelul meditatiei selectate este deja facut, ii dam acces
            if (meditationLevelInfo[0].level_checked === 1) {
                return true;
                // daca nu, trebuie sa verificam daca nu cumva se afla pe primul nivel din serie si trebuie sa ii dam acces indiferent ca e facut sau nu
            } else {

                let maxChecked = userLevels.filter(item => item.level_checked === 1);
                console.log('maxChecked ', maxChecked)

                let selectat = userLevels.filter(item => item.id_level === this.props.id_level);
                console.log('selectat ', selectat[0])

                if (maxChecked.length === 0 && selectat[0].ordinea === 1) {

                    return true

                } else if (maxChecked.length === 0 && selectat[0].ordinea > 1) {

                    return false

                } else {

                    if ((selectat[0].ordinea - 1) === maxChecked[maxChecked.length - 1].ordinea) {
                        return true
                    } else {
                        return false
                    }
                }

            }

        } else {

            console.error('server error');
        }
    }

    setVote = async (newRating) => {
        console.log('newRating ', newRating)

        let toSend = {
            mode: 'insert_rating',
            id_content: this.state.meditation.idul,
            id_user: this.localData.userData.idul,
            vote: Number(newRating)
        }

        let updateRating = await getFromServer('rating', toSend, { credentials: 'include' })

        console.log('response updated rating ', updateRating)

        if (updateRating.r === 'ok') {
            this.getMeditation(this.state.meditation.idul);
        } else {
            console.error('Rating error');
        }
    }

    render() {

        return (
            <div>

                {/* Meditation Bar */}

                <div
                    className="meditation-bar"
                    style={{ backgroundColor: this.props.meditation.color }}
                    onClick={(ev) => { this.onMeditationBarClicked(ev, this.props.meditation.id_categorie) }}
                >


                    <div className="meditation-bar-info">
                        {
                            this.props.currentPlayedIdMeditation &&
                            <img
                                src={this.props.currentPlayedIdMeditation === this.props.idMeditation ? speaker : ''}
                                width="15px"
                                height="15px"
                                alt="" />
                        }

                        <div className="inline-block meditation-title">
                            <p className="meditation-title">{this.props.meditation.nume}</p>
                        </div>

                        <div className="inline-block duration" >
                            {this.props.meditation.durata ? this.props.meditation.durata : ''}
                        </div>
                    </div>


                </div>

                {/* Meditation Body */}

                <div data-elem={this.props.idx + 1} className="meditation-body hide-element">

                    <div style={{ padding: '0px 2px 2px 2px' }}>

                        <div className="meditation-image-container inline-block">

                            <div
                                className="meditation-image"
                                onClick={() => this.props.setAudio(this.state.meditation.cale_audio, this.state.meditation.idul, this.props.idCategory, this.state.meditation.durata, this.state.meditation.nume, this.localData.checkPointObj)}
                                style={
                                    this.state.meditation.cale_poza_listare ?
                                        { backgroundImage: `url("${mediaPath(this.state.meditation.cale_poza_listare, 'images')} ") ` } :
                                        { backgroundImage: `url("${default_image_meditation}")` }
                                }
                            >
                                <i className='material-icons image-play-icon'>play_circle_outline</i>
                            </div>
                        </div>


                        <div className="meditation-info inline-block">
                            {
                                this.state.meditation.idul &&
                                <RatingStars userData={this.localData.userData} meditation={this.state.meditation} setVote={this.setVote} />
                            }
                            <p className="meditation-title" style={{ padding: '5px' }}>{this.state.meditation.nume}</p>

                            <p className="description">{this.state.meditation.descriere}</p>

                            <div className="mediation-tags">
                                {this.state.meditation.idul && this.state.meditation.tags &&
                                    this.state.meditation.tags.split(',').map((tag, idx) => {
                                        return (<div key={idx} className="tag" style={{ color: Number(this.props.idCategory) === 1 ? '#33b4b1' : '#e07973' }}>{tag} /</div>)
                                    })}
                            </div>
                        </div>




                    </div>

                </div>
            </div>
        );
    }


}  // end class