import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import AddRoundButton from '../../Pieces/AddRoundButton';
import LongSaveButton from '../../Pieces/LongSaveButton';
import SelectCategories from '../../Pieces/SelectCategories';
import Validator from '../../../Providers/Validator';
import { getFromServer } from '../../../Providers/WS';
import SwitchButton from '../../Pieces/SwitchButton';
import { Divider } from '@material-ui/core';

export default class AdminLevels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levelsList: [],
            filteredLevels: [],
            isNewLevelSectionEnabled: false,
            idCategorySelected: 1 || 0
        }
    }

    localData = {
        newLevelName: '',
    }

    validate = new Validator()

    componentDidMount() {
        this.getLevels();
    }

    showNewLevelSection = () => {
        this.setState({ isNewLevelSectionEnabled: true })
    }

    defaultValueForCategory = (idul) => {
        this.setState({ idCategorySelected: idul });
    }

    onChangeCategory = (val) => {
        this.setState({ idCategorySelected: val }, ()=>{
            this.filterLevels()
        });
    }

    setLevelName = (input) => {
        this.localData.newLevelName = input.target.value
    }


    getLevels = async () => {
        let levels = await getFromServer('levels', { mode: 'list_levels_admin' }, { credentials: 'include' })

        if (levels.r === 'ok') {
            console.log('this.state.idCategorySelected ', this.state.idCategorySelected)
            let filteredList = levels.continut.filter(item => item.id_categorie === this.state.idCategorySelected)

            this.setState({ levelsList: levels.continut, filteredLevels: filteredList }, () => {
                console.log('levelsList ', this.state.levelsList)
                console.log('filteredLevels ', this.state.filteredLevels)
            })

        } else {
            console.error('Eroare la preluare levels')
        }
    }

    filterLevels = () => {
        let filteredList = this.state.levelsList.filter(item => item.id_categorie === this.state.idCategorySelected)
        this.setState({ filteredLevels: filteredList }, () => {})
    }


    validateLevel = () => {
        let validateResponse = this.validate.lenghtValidate(2, this.localData.newLevelName)

        if (!validateResponse) {
            this.setState({ errors: true })
        } else {
            this.setState({ errors: false })
            this.saveLevel()
        }
        // console.log('validate response ', validateResponse)
    }

    saveLevel = async () => {
        console.log(' dsddsd ', this.state.idCategorySelected)
        let insertLevel = await getFromServer('levels', { level_name: this.localData.newLevelName, id_category: this.state.idCategorySelected, mode: 'insert_level' }, { credentials: 'include' })

        if (insertLevel.r === 'ok') {
            this.props.history.push(`/adminlevel/${insertLevel.id_introdus}/${this.state.idCategorySelected}`)
        } else {
            alert('A aparut o problema la creare categorie.')
        }
    }

    changeLevelStatus = async (deleted, idul, ev) => {
        this.setState({ isLevelHidden: ev.target.checked }, async () => {
            if (deleted !== 1) {
                await getFromServer('levels', { mode: 'delete_level', id_level: idul }, { credentials: 'include' })
            } else {
                await getFromServer('levels', { mode: 'publish_level', id_level: idul }, { credentials: 'include' })
            }
            this.getLevels();
        })
    }

    goToLevelDetails = (idul) => {
        this.props.history.push(`/adminlevel/${idul}/${this.state.idCategorySelected}`)
    }

    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />

                <div className="admin-child-container">
                    <div>
                        <p className="opacity-title blue-lite">Niveluri</p>
                        <AddRoundButton
                            action={this.showNewLevelSection}
                        />

                        {/* Sectiune adaugare Level Nou */}

                        {
                            this.state.isNewLevelSectionEnabled &&
                            <div>

                                <input
                                    className="namaste-round-input"
                                    placeholder="Introduceti numele nivelului"
                                    onChange={(ev) => { this.setLevelName(ev) }}
                                >
                                </input>

                                <SelectCategories
                                    label='Alegeti o categorie'
                                    onChangeCategory={this.onChangeCategory}
                                    setDefaultValue={this.defaultValueForCategory}
                                    currentValue={this.state.idCategorySelected}
                                />

                                {
                                    this.state.errors &&
                                    <p className="error-message text-center">Introduceti un cuvant format din minim 3 caractere</p>
                                }

                                <LongSaveButton text="Salvare" action={this.validateLevel} />

                            </div>
                        }

                        <Divider light />

                        {/* Lista LEVELS */}

                        <SelectCategories
                            label='Filtrare niveluri pe categorii'
                            onChangeCategory={this.onChangeCategory}
                            setDefaultValue={this.defaultValueForCategory}
                            currentValue={this.state.idCategorySelected}
                        />


                        <div className="table-head">
                            <div className="width80 inline-block">Nume</div>
                            <div className="width20 inline-block">Status</div>
                        </div>

                        {
                            this.state.filteredLevels && 
                            this.state.filteredLevels.map((item, index) => {
                                return (
                                    <div key={index} className="row-item">
                                        <div className="width80 inline-block" onClick={() => this.goToLevelDetails(item.idul)}>
                                            <p style={{ lineHeight: '37px' }}>{index + 1}. {item.nume_level}</p>
                                        </div>
                                        <div className="width20 inline-block">
                                            <SwitchButton item={item} action={this.changeLevelStatus} />
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }


}  // end class