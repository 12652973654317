import React from 'react';
import SiteHeader from '../../SiteHeader/SiteHeader';
import AdminHeader from '../AdminHeader';
import { MenuItem, Select } from '@material-ui/core';
import { getFromServer } from '../../../Providers/WS';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ valueToShow, idx, }) =>
    
    <li
        className="draggableItem"
        key={`medi${idx}`}
    >
        {valueToShow}
    </li>
)

const SortableList = SortableContainer(({ list, salveazaOrdine, openList, deleteProgram, openAdd }) => {
    return (



        <ul className="draggableList">

            {
        
                list.map((meditation, idx) => {
                    return (<SortableItem key={`medi${idx}`} index={idx} valueToShow={meditation.nume}  />)
                })
            }
        </ul>

    );
});

export default class AdminMeditationsOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idCurrentCategory: 1,
            categoriesList: [],
            meditationsList: []
        }

    }

    componentDidMount() {
        this.getCategories()
    }


    getCategories = async () => {
        let categories = await getFromServer('category', { mode: 'list_categories_admin' }, { credentials: 'include' })

        if (categories.r === 'ok') {
            this.setState({ categoriesList: categories.continut }, () => {
                console.log('categoriesList ', this.state.categoriesList)
                this.getContent(this.state.idCurrentCategory)
            })
        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }


    getContent = async (id) => {

        this.setState({ idCurrentCategory: id }, () => {
            // this.getContent(this.state.idCurrentCategory)
        })

        let categoryContent = await getFromServer('content', {
            mode: 'content_category_list',
            id_category: id
        }, { credentials: 'include' })

        // let changecategory = await getFromServer('content', { mode: 'change_category', id_category: id, id_content: this.props.idMeditation }, { credentials: 'include' })

        if (categoryContent.r === 'ok') {
            console.log('content categorie ', categoryContent)
            this.setState({ meditationsList: categoryContent.continut })

        } else {
            console.error('Eroare la preluare categories in edit subcategory')
        }
    }

    /* SORTABLE */

    // saveOrder = () => {
    //     console.log('mergem la salvare ordine')
    // }

    onSortEnd = ({ oldIndex, newIndex }) => {

        this.setState({
            meditationsList: arrayMove(this.state.meditationsList, oldIndex, newIndex),
        }, () => {
                console.log('noua lista:', this.state.meditationsList)
                this.setArrayForServer(this.state.meditationsList)
        });
    };

    setArrayForServer = (list) => {
        let newList = [...list];
        let orderIDs = []

        for (let i = 0, n = newList.length; i < n; i++){
            orderIDs.push(newList[i]['id_meditatie'])
        }

        this.updateOrder(orderIDs.toString())
        
    }
    
    updateOrder = async (orderIDs) => {
        // console.log('orderID ', orderIDs)

        let responseSaveOrder = await getFromServer('content', {
            mode: 'update_contents_order',
            order_ids: orderIDs,
        }, { credetials: 'include' })

        if (responseSaveOrder.r==="ok") {
            console.log(responseSaveOrder.mesaj)
        } else {
            throw new Error(responseSaveOrder)
        }
    }


    render() {
        return (
            <div>
                <SiteHeader openAuthentificationPanel={this.props.openAuthentificationPanel} />
                <AdminHeader history={this.props.history} />
                <div className="admin-child-container">
                    <div>
                        <p className="opacity-title blue-lite">Ordine in site meditatii</p>
                    </div>
                    {
                        this.state.categoriesList.length > 0 &&
                        <div>
                            <p className="namaste-label">Schimba Categorie</p>
                            <Select
                                value={this.state.idCurrentCategory}
                                onChange={(ev) => { this.getContent(ev.target.value) }}
                                className="namaste-select-box"
                            >
                                {
                                    this.state.categoriesList.map((item, idx) => {
                                        return <MenuItem value={item.idul} key={idx}>{item.nume_categorie}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                    }
                    {
                        this.state.meditationsList.length > 0 &&
                        <SortableList saveOrder={this.saveOrder} onSortEnd={this.onSortEnd} list={this.state.meditationsList} />
                    }



                </div>
            </div>
        );
    }


}  // end class