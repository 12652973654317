import React from 'react';
import AddRoundButton from '../../Pieces/AddRoundButton';
import LongSaveButton from '../../Pieces/LongSaveButton';
import SwitchButton from '../../Pieces/SwitchButton';
import { Select, MenuItem } from '@material-ui/core';
import Validator from '../../../Providers/Validator';
import { getFromServer } from '../../../Providers/WS';

export default class AdminSubcategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: this.props.categoriesList[0] ? this.props.categoriesList[0].idul : 0,
            subcategoriesList: [],
            isNewSubcategorySectionEnabled: false,
            isSubcategoryHidden: ''
        }
    }

    validate = new Validator()

    localData = {
        newSubcategoryName: ''
    } 

    componentDidMount() {
        this.getSubcategories();
    } 

    getSubcategories = async () => {
        let subcategories = await getFromServer('subcategory', { mode: 'list_subcategories_admin' }, { credentials: 'include' })
        console.log('subcategories ', subcategories)
        if (subcategories.r === 'ok') {
            this.setState({ subcategoriesList: subcategories.continut }, () => {
            })
        } else {
            console.log('Problama la preluare subcategorii')
        }
    }

    showNewSubcategorySection = () => {
        this.setState({ isNewSubcategorySectionEnabled: true })
    }

    setInput = (type, ev) => {
        switch (type) {
            case 'category':
                this.setState({ category: ev.target.value });
                break;
            case 'subcategory_name':
                this.setState({ name: ev.target.value });
                break;
            default:
                console.log('switch a intrat in default')
        }
    }

    setSubcategoryName = (input) => {
        this.localData.newSubcategoryName = input.target.value
    }

    validateSubcategory = () => {
        let validateResponse = this.validate.lenghtValidate(2, this.localData.newSubcategoryName)

        if (!validateResponse) {
            this.setState({ errors: true })
        } else {
            this.setState({ errors: false })
            this.saveSubcategory()
        }
        console.log('validate response ', validateResponse)
    }

    saveSubcategory = async () => {
        console.log('this.localData.newSubcategoryName', this.localData.newSubcategoryName)
        let insertSubcategory = await getFromServer('subcategory', { subcategory_name: this.localData.newSubcategoryName, id_category: this.state.category, mode: 'insert_subcategory' }, { credentials: 'include' })
 
        if (insertSubcategory.r === 'ok') {
            this.props.history.push(`/adminsubcategory/${insertSubcategory.id_introdus}`)
        } else {
            alert('A aparut o problema la creare subcategorie.')
        }
    }

    changeSubcategoryStatus = async (deleted, idul, ev) => {
        this.setState({ isSubcategoryHidden: ev.target.checked }, async () => {
            if (deleted !== 1) {
                await getFromServer('subcategory', { mode: 'delete_subcategory', id_subcategory: idul }, { credentials: 'include' })
            } else {
                await getFromServer('subcategory', { mode: 'publish_subcategory', id_subcategory: idul }, { credentials: 'include' })
            }
            this.getSubcategories();
        })
    }

    goToSubcategoryDetails = (idul) => {
        this.props.history.push(`/adminsubcategory/${idul}`)
    }

    render() {
        
        return (
            <div>

                <div>
                    <p className="opacity-title blue-lite">Subcategorii</p>
                    <AddRoundButton
                        action={this.showNewSubcategorySection}
                    />

                    {
                        this.state.isNewSubcategorySectionEnabled &&
                        <div>

                            <p className="namaste-label">Categorie</p>
                            <Select
                                value={this.state.category || 0}
                                onChange={(ev) => { this.setInput('category', ev) }}
                                className="namaste-select-box"
                            >
                                {this.props.categoriesList.length > 0 &&
                                   
                                    this.props.categoriesList.map((item, idx) => {
                                        return <MenuItem value={item.idul} key={idx} >{item.nume_categorie}</MenuItem>
                                    })
                                }

                            </Select>

                            {/* Input Categorie Noua */}
                            <input
                                className="namaste-round-input"
                                placeholder="Introduceti numele subcategoriei"
                                onChange={(ev) => { this.setSubcategoryName(ev) }}
                            >
                            </input>

                            {
                                this.state.errors &&
                                <p className="error-message text-center">Introduceti un cuvant format din minim 3 caractere</p>
                            }

                            <LongSaveButton text="Salvare" action={this.validateSubcategory} />

                        </div>
                    }


                    <div className="table-head">
                        <div className="width80 inline-block">Nume</div>
                        <div className="width20 inline-block">Status</div>
                    </div>

                    {
                        this.state.subcategoriesList &&
                        this.state.subcategoriesList.map((item, index) => {
                            return (
                                <div key={index} className="row-item">
                                    <div className="width80 inline-block" onClick={() => this.goToSubcategoryDetails(item.idul)}>
                                        <p style={{ lineHeight: '37px' }}>{index + 1}. {item.nume_subcategorie}</p>
                                    </div>
                                    <div className="width20 inline-block">
                                        <SwitchButton item={item} action={this.changeSubcategoryStatus} />
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }


}  // end class
